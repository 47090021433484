  <template>
  <div class="contact-header">
    <div class="contact-paragraph">
      <h1>Ada Pertanyaan?</h1>
      <hr />
      <h2>
        PT Adhi Konsultan Utama melayani Anda kapan pun dan dimana pun anda
        berada
      </h2>
    </div>
    <div class="contact-details">
      <div class="contact-container">
        <div class="details-left">
          <h1>Penawaran Proyek</h1>
          <h2>
            <a href="mailto:pt.adhikonsultanutama@gmail.com"
              >pt.adhikonsultanutama@gmail.com</a
            >
          </h2>
        </div>
        <hr />
        <div class="details-right">
          <h1>Konsultasi</h1>
          <h2><a href="tel:+6243692761">&#40;0294&#41;&#160;369&#160;2761</a></h2>
        </div>
      </div>
    </div>
  </div>
  <div class="map-container">
    <iframe :src="map"> </iframe>
    <div class="inner-shadow"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2721.30970470616!2d110.1910074392379!3d-6.9227893933719695!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e705d5b8f9e75bb%3A0xc7d2cfa3ed8a9938!2sPT%20ADHI%20KONSULTAN%20UTAMA!5e0!3m2!1sen!2sus!4v1690695443115!5m2!1sen!2sus",
    };
  },
};
</script>

<style scoped>
.contact-header {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.contact-paragraph {
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  padding-bottom: 6rem;
  width: 35%;
}

.contact-paragraph h1 {
  font-weight: 600;
  font-size: 2.5rem;
  color: #001858;
  margin: 0;
}

.contact-paragraph hr {
  border-style: solid;
  border-width: 0.15rem;
  border-color: #e4000f;
  width: 45%;
  margin: 0;
}

.contact-paragraph h2 {
  font-weight: 400;
  font-size: 2rem;
  color: #001858;
  letter-spacing: 0.05em;
  padding-top: 1.25rem;
  margin: 0;
}

.contact-details {
  display: flex;
  align-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.contact-container {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  padding-left: 3rem;
  padding-right: 2rem;
  gap: 3rem;
}

.contact-container a,
.contact-container a:visited,
.contact-container a:link {
  color: #000000;
  text-decoration: none;
}

.contact-details h1 {
  font-weight: 400;
  font-size: 1.5rem;
  color: #000000;
  margin: 0;
}

.contact-details hr {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 1px;
  padding-top: 3rem;
  margin: 0;
  height: 8rem;
}

.contact-details h2 {
  font-weight: 600;
  font-size: 1.5rem;
  color: #000000;
  padding-right: 3rem;
  margin: 0;
}

.map-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.map-container iframe {
  width: 100%;
  height: 33rem;
  pointer-events: auto;
  border-style: none;
}

.map-container .inner-shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  box-shadow: inset 0 0 50px #444444;
  top: 0;
  left: 0;
  pointer-events: none;
}

@media only screen and (max-width: 1473px) {
  .contact-header {
    gap: 1rem;
  }
  .contact-paragraph {
    justify-content: center;
  }
  .contact-details {
    padding: 6rem 0;
  }
  .contact-paragraph h1 {
    font-size: 1.75rem;
    padding-bottom: 0.75rem;
  }
  .contact-paragraph h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .contact-container h1 {
    font-size: 1.25rem;
  }
  .contact-container h2 {
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 1697px) {
  .contact-header {
    width: 90%;
  }
}

@media only screen and (max-width: 1584px) {
}
@media only screen and (max-width: 1446px) {
  .contact-paragraph h1 {
    font-size: 1.5rem;
  }

  .contact-paragraph h2 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 1050px) {
  .contact-header {
    flex-direction: column;
    width: auto;
    align-items: center;
  }
  .contact-details {
    padding: 0 0 2rem 0;
    justify-content: center;
  }
  .contact-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 0;
  }
  .contact-container hr {
    display: none;
  }
  .contact-container h1 {
    font-size: 1rem;
  }
  .contact-container h2 {
    font-size: 1rem;
    padding: 0;
  }
  .contact-paragraph {
    width: 80%;
    padding: 2rem 0;
  }
  .contact-paragraph h1 {
    font-size: 1.4rem;
  }
  .contact-paragraph h2 {
    font-size: 1.2rem;
  }
  .details-left {
    padding: 1rem;
    border-bottom: 1px solid #000000;
  }
  .details-right {
    padding: 1rem;
  }
}
@media only screen and (max-width: 400px) {
  .contact-header {
    flex-direction: column;
    width: auto;
    align-items: center;
  }
  .contact-details {
    padding: 0 0 2rem 0;
    justify-content: center;
  }
  .contact-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 0;
  }
  .contact-container hr {
    display: none;
  }
  .contact-container h1 {
    font-size: 1rem;
  }
  .contact-container h2 {
    font-size: 1rem;
    padding: 0;
  }
  .contact-paragraph {
    width: 80%;
    padding: 2rem 0;
  }
  .contact-paragraph h1 {
    font-size: 1.4rem;
  }
  .contact-paragraph h2 {
    font-size: 1.2rem;
  }
  .details-left {
    padding: 1rem;
    border-bottom: 1px solid #000000;
  }
  .details-right {
    padding: 1rem;
  }
}
</style>
