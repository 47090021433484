<template>
  <div class="container">
    <Hero
      :article="article.title"
      :articleBg="article.cover"
    />
    <article>
      <div class="article-details">
        <div>
          <img :src="article.author.photo" alt="" draggable="false" />
          <h2>{{ article.author.name }}</h2>
        </div>
        <div>
          <img
            src="@/assets/user/icons/home/article/clock.svg"
            alt=""
            draggable="false"
          />
          <h2>{{ article.time }}</h2>
        </div>
      </div>
      <div class="article-socials">
        <a
          :href="article.socials.facebook"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="@/assets/user/icons/article/facebook.svg" alt="" />
        </a>
        <a
          :href="article.socials.whatsapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="@/assets/user/icons/article/whatsapp.svg" alt="" />
        </a>
        <a
          :href="article.socials.twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="@/assets/user/icons/article/twitter.svg" alt="" />
        </a>
      </div>
      <div class="article-content">
        <p v-for="(item, index) in article.contents" :key="index">
          {{ item.content }}
        </p>
      </div>
    </article>
    <div class="more-articles">
      <h1>BERITA LAINNYA</h1>
      <div class="article-wrapper">
        <div
          class="article-card-item"
          v-for="(moreArticle, index) in articles"
          :key="index"
        >
          <img :src="moreArticle.cover" alt="" draggable="false" />
          <div class="article-card-details">
            <h1>
              {{ moreArticle.title }}
            </h1>
            <div class="article-card-user-timestamp">
              <div>
                <img :src="moreArticle.author.photo" alt="" draggable="false" />
                <h2>{{ moreArticle.author.name }}</h2>
              </div>
              <div>
                <img
                  src="@/assets/user/icons/home/article/clock.svg"
                  alt=""
                  draggable="false"
                />
                <h2>{{ moreArticle.time }}</h2>
              </div>
            </div>
            <h3>
              {{ moreArticle.content }}
            </h3>
            <div class="article-more" @click="toArticle(moreArticle.id)">
              <h4>Selengkapnya</h4>
              <img
                src="@/assets/user/icons/home/article/arrow.svg"
                alt=""
                draggable="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Hero from "@/components/user/Hero.vue";
import Footer from "../Footer.vue";

export default {
  components: {
    Hero,
    Footer,
  },
  data() {
    return {
      article: {
        cover: "/user/assets/images/article/cover/article-1.png",
        author: {
          photo: "/user/assets/images/article/author/author-1.png",
          name: "Basuki",
        },
        time: "7 Maret 2020",
        title: "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
        contents: [
          {
            content:
              "Gorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.",
          },
          {
            content:
              "Curabitur tempor quis eros tempus lacinia. Nam bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum ullamcorper quis venenatis nisi. Proin vitae facilisis nisi, ac posuere leo.",
          },
          {
            content:
              "Nam pulvinar blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus non odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu, faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.",
          },
        ],
        socials: {
          facebook: "https://facebook.com",
          twitter: "https://twitter.com",
          whatsapp: "https://whatsapp.com",
        },
        id: 1,
      },
      articles: [
        {
          cover: "/user/assets/images/article/cover/article-1.png",
          author: {
            photo: "/user/assets/images/article/author/author-1.png",
            name: "Basuki",
          },
          time: "7 Maret 2020",
          title:
            "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
          content:
            "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
          id: 1,
        },
        {
          cover: "/user/assets/images/article/cover/article-1.png",
          author: {
            photo: "/user/assets/images/article/author/author-1.png",
            name: "Basuki",
          },
          time: "7 Maret 2020",
          title:
            "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
          content:
            "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
          id: 1,
        },
        {
          cover: "/user/assets/images/article/cover/article-1.png",
          author: {
            photo: "/user/assets/images/article/author/author-1.png",
            name: "Basuki",
          },
          time: "7 Maret 2020",
          title:
            "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
          content:
            "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
          id: 1,
        },
        {
          cover: "/user/assets/images/article/cover/article-1.png",
          author: {
            photo: "/user/assets/images/article/author/author-1.png",
            name: "Basuki",
          },
          time: "7 Maret 2020",
          title:
            "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
          content:
            "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
          id: 1,
        },
      ],
    };
  },
};
</script>

<style scoped>
h1,
h2,
h3,
p {
  margin: 0;
}

img {
  pointer-events: none;
}

.container {
  font-family: "Poppins";
  background: #f8f8ff;
}

.container article {
  padding: 4rem 7rem;
}

.article-details {
  display: flex;
  gap: 3.5rem;
  padding: 0 0 1.75rem 0;
}

.article-details div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.article-details div img {
  height: 2rem;
  width: 2rem;
}

.article-details h2 {
  font-size: 1.75rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.article-socials {
  display: flex;
  gap: 0.25rem;
}

.article-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.article-content p {
  font-size: 1.75rem;
  font-weight: 400;
}

.article-content p:first-of-type {
  padding: 2rem 0 0 0;
}

.article-content p:not(:first-of-type) {
  text-indent: 9rem;
}

.more-articles {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

.more-articles h1 {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.article-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1.15rem;
  padding: 0 0 6rem 0;  
}

.article-card-item {
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.article-card-details {
  padding: 0.25rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.article-card-user-timestamp {
  display: flex;
  gap: 1rem;
}

.article-card-user-timestamp div {
  display: flex;
  gap: 0.5rem;
}

.article-card-item div div img {
  object-fit: none;
}

.article-card-item h1 {
  font-weight: 500;
  font-size: 1.25rem;
  max-width: 22rem;
  margin: 0;
  letter-spacing: 0;
}

.article-card-item h2 {
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0;
}

.article-card-item h3 {
  font-weight: 400;
  max-width: 23rem;
  font-size: 1rem;
  letter-spacing: 0;
}

.article-card-item h4 {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  letter-spacing: 0;
}

.article-more {
  display: flex;
  gap: 0.25rem;
  cursor: pointer;
  width: 8.5rem;
}
</style>
