<template>
  <div
    v-if="isMain && !simpleMode && !article"
    class="main-hero prevent-select"
  >
    <div class="navbar">
      <router-link to="/home" draggable="false">BERANDA</router-link>
      <router-link to="/about-us" draggable="false">TENTANG</router-link>
      <router-link to="/product" draggable="false">PRODUK</router-link>
      <a href="https://blog.adhikonsultanutama.com/" draggable="false">BLOG</a>
      <!-- <router-link to="/article" draggable="false">ARTIKEL</router-link> -->
      <router-link to="/career" draggable="false">KARIR</router-link>
      <!-- <router-link to="/csr" draggable="false">CSR</router-link> -->
    </div>
    <div class="hero-main">
      <img
        class="logo"
        src="@/assets/user/icons/home/hero/logo_alt.svg"
        alt=""
      />
      <h1>PT ADHI KONSULTAN UTAMA</h1>
      <div class="slogan">
        <img
          src="@/assets/user/images/hero/we-build-better-future.gif"
          alt=""
          draggable="false"
        />
      </div>
      <div>
        <button @click="toReachUs()">Dapatkan Penawaran!</button>
      </div>
    </div>
    <div class="backgrounds">
      <img
        class="hero-0 visible"
        src="@/assets/user/images/misc/empty.png"
        alt=""
      />
      <img class="hero-1" src="@/assets/user/images/misc/empty.png" alt="" />
      <img class="hero-2" src="@/assets/user/images/misc/empty.png" alt="" />
      <img class="hero-3" src="@/assets/user/images/misc/empty.png" alt="" />
      <!-- <img class="hero-4" src="@/assets/user/images/misc/empty.png" alt="" />
      <img class="hero-5" src="@/assets/user/images/misc/empty.png" alt="" />
      <img class="hero-6" src="@/assets/user/images/misc/empty.png" alt="" /> -->
    </div>
  </div>
  <div v-if="!isMain && !simpleMode && !article" class="hero prevent-select">
    <div class="navbar prevent-select">
      <router-link to="/home">BERANDA</router-link>
      <router-link to="/about-us">TENTANG</router-link>
      <router-link to="/product">PRODUK</router-link>
      <a href="https://blog.adhikonsultanutama.com/" draggable="false">BLOG</a>
      <!-- <router-link to="/article">ARTIKEL</router-link> -->
      <router-link to="/career">KARIR</router-link>
      <!-- <router-link to="/csr">CSR</router-link> -->
    </div>
    <div class="hero-main">
      <h1>{{ mainTitle }}</h1>
      <h2>{{ title }}</h2>
      <h3>{{ text }}</h3>
      <div>
        <button @click="redirectTo()">{{ buttonText }}</button>
      </div>
    </div>
    <div v-if="heroBg == null" class="backgrounds">
      <img
        class="hero-0 visible"
        src="@/assets/user/images/misc/empty.png"
        alt=""
      />
      <img class="hero-1" src="@/assets/user/images/misc/empty.png" alt="" />
      <img class="hero-2" src="@/assets/user/images/misc/empty.png" alt="" />
      <img class="hero-3" src="@/assets/user/images/misc/empty.png" alt="" />
      <!-- <img class="hero-4" src="@/assets/user/images/misc/empty.png" alt="" />
      <img class="hero-5" src="@/assets/user/images/misc/empty.png" alt="" />
      <img class="hero-6" src="@/assets/user/images/misc/empty.png" alt="" /> -->
    </div>
    <div v-else></div>
  </div>
  <div
    v-if="simpleMode && !isMain && !article"
    class="simple-hero prevent-select"
  >
    <div class="navbar prevent-select">
      <router-link to="/home">BERANDA</router-link>
      <router-link to="/about-us">TENTANG</router-link>
      <router-link to="/product">PRODUK</router-link>
      <a href="https://blog.adhikonsultanutama.com/" draggable="false">BLOG</a>
      <!-- <router-link to="/article">ARTIKEL</router-link> -->
      <router-link to="/career">KARIR</router-link>
      <!-- <router-link to="/csr">CSR</router-link> -->
    </div>
    <div class="hero-main">
      <h1>{{ simpleMode }}</h1>
    </div>
    <div></div>
    <div class="simple-bg"></div>
  </div>
  <div
    v-if="article && !simpleMode && !isMain"
    class="article-hero prevent-select"
  >
    <div class="navbar prevent-select">
      <router-link to="/home">BERANDA</router-link>
      <router-link to="/about-us">TENTANG</router-link>
      <router-link to="/product">PRODUK</router-link>
      <a href="https://blog.adhikonsultanutama.com/" draggable="false">BLOG</a>
      <!-- <router-link
        to="/article"
        class="router-link-active router-link-exact-active"
        >ARTIKEL</router-link
      > -->
      <router-link to="/career">KARIR</router-link>
      <router-link to="/csr">CSR</router-link>
    </div>
    <div></div>
    <div class="hero-main">
      <h1>{{ article }}</h1>
    </div>
    <div class="article-bg"></div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  props: {
    isMain: {
      type: Boolean,
      default: false,
    },
    mainTitle: String,
    title: String,
    text: String,
    buttonText: String,
    buttonDirection: String,
    heroBg: String,
    textSize: {
      type: String,
      default: "2rem",
    },
    simpleMode: String,
    article: String,
    articleBg: String,
    justify: Boolean,
  },
  data() {
    return {};
  },
  mounted() {
    if (this.justify) {
      document.querySelector(".hero .hero-main h3").style.textAlign = "justify";
    }
    if (this.isMain) {
      let speed = 8000;
      let currentImage = 0;
      let numImages = 4;
      let timer = setInterval(() => {
        let e;
        e = document.querySelector(`.hero-${currentImage}`);
        e.classList.remove("visible");

        currentImage++;
        if (currentImage > numImages - 1) {
          currentImage = 0;
        }

        e = document.querySelector(`.hero-${currentImage}`);
        e.classList.add("visible");
      }, speed);
      this.$root.emitter.on("killInterval", () => {
        clearInterval(timer);
      });
    }
    if (!this.isMain) {
      if (!this.simpleMode && !this.article) {
        this.dynamicHero();
        let speed = 8000;
        let currentImage = 0;
        let numImages = 4;
        let allowCarousel = true;
        let timer = setInterval(() => {
          if (!allowCarousel) return;
          let e;
          e = document.querySelector(`.hero-${currentImage}`);
          e.classList.remove("visible");

          currentImage++;
          if (currentImage > numImages - 1) {
            currentImage = 0;
          }

          e = document.querySelector(`.hero-${currentImage}`);
          e.classList.add("visible");
        }, speed);
        this.$root.emitter.on("killInterval", () => {
          clearInterval(timer);
        });
      }
    }
    if (this.simpleMode) {
      this.$root.emitter.emit("killInterval");
      this.setSimpleMode();
    }

    if (this.article) {
      this.$root.emitter.emit("killInterval");
      this.setArticleHero();
    }
  },
  methods: {
    redirectTo() {
      router.replace(this.buttonDirection);
      if (this.buttonDirection == "/home#reach-us") {
        setTimeout(() => {
          this.toReachUs();
        }, 1);
      }
    },
    setArticleHero() {
      document.querySelector(
        ".article-bg"
      ).style.background = `no-repeat linear-gradient(180deg, rgba(255, 255, 255, 0) -38.5%, #000000 112.35%), url(${this.articleBg})`;
      document.querySelector(".article-bg").style.backgroundSize = "100%";
    },
    setSimpleMode() {
      let choosenBg;
      if (this.simpleMode == "TENTANG KAMI") {
        choosenBg = "/user/assets/images/hero/simple/AboutUs.png";
      }
      if (this.simpleMode == "PRODUK") {
        choosenBg = "/user/assets/images/hero/simple/Product.png";
      }
      if (this.simpleMode == "ARTIKEL") {
        choosenBg = "/user/assets/images/hero/simple/Article.png";
      }
      if (this.simpleMode == "KARIR") {
        choosenBg = "/user/assets/images/hero/simple/Career.png";
      }
      const simpleBackground = document.querySelector(".simple-bg");
      simpleBackground.style.background = `url(${choosenBg})`;
    },
    dynamicHero() {
      const Hero = document.querySelector(".hero");
      Hero.style.background = `linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("${this.heroBg}")`;
      const HeroText = document.querySelector(".hero-main h3");
      HeroText.style.fontSize = this.textSize;
    },
    toReachUs() {
      document.querySelector("input#name").focus();
      document.querySelector("#reach-us").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    },
  },
};
</script>

<style scoped>
/* Main hero section */

.main-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
}

.backgrounds img {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity 2s linear;
  -moz-transition: opacity 2s linear;
  -o-transition: opacity 2s linear;
  transition: opacity 2s linear;
}

.visible {
  opacity: 1 !important;
}

.main-hero img.hero-0 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-1.png") center/cover;
}

.main-hero img.hero-1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-2.png") center/cover;
}

.main-hero img.hero-2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-3.png") center/cover;
}

.main-hero img.hero-3 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-4.png") center/cover;
}

/* .main-hero img.hero-4 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-5.png") center/cover;
}

.main-hero img.hero-5 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-6.png") center/cover;
}

.main-hero img.hero-6 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-7.png") center/cover;
} */

/* .main-hero .backgrounds img:nth-of-type(1) {
  animation-delay: 16s;
  -webkit-animation-delay: 16s;
}

.main-hero .backgrounds img:nth-of-type(2) {
  animation-delay: 12s;
  -webkit-animation-delay: 12s;
}

.main-hero .backgrounds img:nth-of-type(3) {
  animation-delay: 8s;
  -webkit-animation-delay: 8s;
}

.main-hero .backgrounds img:nth-of-type(4) {
  animation-delay: 4s;
  -webkit-animation-delay: 4s;
}

.main-hero .backgrounds img:nth-of-type(5) {
  animation-delay: 0;
  -webkit-animation-delay: 0;
}

.main-hero .backgrounds img:nth-of-type(6) {
  animation-delay: 24s;
  -webkit-animation-delay: 24s;
}

.main-hero .backgrounds img:nth-of-type(7) {
  animation-delay: 20s;
  -webkit-animation-delay: 20s;
}

.main-hero .backgrounds img {
  animation-name: backgroundchangeFadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 24s;

  -webkit-animation-name: backgroundchangeFadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 24s;
} */

.main-hero .navbar {
  display: flex;
  flex-flow: row;
  justify-content: right;
  gap: 3.75rem;
  padding-top: 1rem;
  padding-right: 1.75rem;
  z-index: 1;
}

.navbar a,
a:visited {
  font-style: normal;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.4s;
}

.navbar a:hover {
  border-bottom: #ff2230 2px solid;
}

.navbar .router-link-active {
  color: #ff2230;
  font-size: 1.125rem;
  font-weight: 600;
  border-bottom: none !important;
}

.main-hero .hero-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.main-hero .hero-main .logo {
  width: 180px;
  pointer-events: none;
}

.main-hero .hero-main h1 {
  font-family: "Poppins Bold";
  color: #f8f8ff;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.15em;
  font-size: 4rem;
  margin: 0;
  padding-top: 3rem;
  padding-bottom: 1rem;
  text-align: center;
}

.main-hero .hero-main h2 {
  font-family: "Dancing Script";
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 3rem;
  margin: 0;
  padding-bottom: 2.5rem;
  display: inline-block;
}

.main-hero .hero-main div button {
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  color: #ffffff;
  background-color: #ff2230;
  padding: 1.5rem 3.5rem 1.5rem 3.5rem;
  border-radius: 25px;
  border-style: none;
  -webkit-transition: background-color 0.15s ease-out;
  -moz-transition: background-color 0.15s ease-out;
  -o-transition: background-color 0.15s ease-out;
  transition: background-color 0.15s ease-out;
  cursor: pointer;
}

.main-hero .hero-main div button:hover {
  background-color: #f90011;
}

.main-hero .hero-main div button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.main-hero .slogan {
  display: flex;
}

.main-hero .slogan img {
  width: 32rem;
  padding: 0 0 1rem 0;
}

/* Hero section */

.hero {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
  width: 100%;
}

.hero img.hero-0 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-1.png") center/cover;
}

.hero img.hero-1 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-2.png") center/cover;
}

.hero img.hero-2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-3.png") center/cover;
}

.hero img.hero-3 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-4.png") center/cover;
}

/* .hero img.hero-4 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-5.png") center/cover;
}

.hero img.hero-5 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-6.png") center/cover;
}

.hero img.hero-6 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("@/assets/user/images/hero/hero-7.png") center/cover;
} */

/* .hero .backgrounds img:nth-of-type(1) {
  animation-delay: 16s;
  -webkit-animation-delay: 16s;
}

.hero .backgrounds img:nth-of-type(2) {
  animation-delay: 12s;
  -webkit-animation-delay: 12s;
}

.hero .backgrounds img:nth-of-type(3) {
  animation-delay: 8s;
  -webkit-animation-delay: 8s;
}

.hero .backgrounds img:nth-of-type(4) {
  animation-delay: 4s;
  -webkit-animation-delay: 4s;
}

.hero .backgrounds img:nth-of-type(5) {
  animation-delay: 0;
  -webkit-animation-delay: 0;
}

.hero .backgrounds img:nth-of-type(6) {
  animation-delay: 24s;
  -webkit-animation-delay: 24s;
}

.hero .backgrounds img:nth-of-type(7) {
  animation-delay: 20s;
  -webkit-animation-delay: 20s;
}

.hero .backgrounds img {
  animation-name: backgroundchangeFadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 24s;

  -webkit-animation-name: backgroundchangeFadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 24s;
} */

.hero .navbar {
  display: flex;
  flex-flow: row;
  justify-content: right;
  gap: 3.75rem;
  padding-top: 1rem;
  padding-right: 1.75rem;
  z-index: 1;
}

.hero .hero-main {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding-left: 9.5rem;
  gap: 1rem;
  width: 68%;
}

.hero .hero-main * {
  margin: 0;
  z-index: 1;
}

.hero .hero-main h1 {
  font-family: "Poppins Bold";
  color: #ff2230;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.2em;
  font-size: 2rem;
}

.hero .hero-main h2 {
  font-family: "Poppins Bold";
  color: #ffffff;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.15em;
  font-size: 4rem;
}

.hero .hero-main h3 {
  color: #f8f8ff;
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 0.05em;
  padding-bottom: 1rem;
}

.hero .hero-main div button {
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  color: #ffffff;
  background-color: #ff2230;
  padding: 1.5rem 3.5rem 1.5rem 3.5rem;
  border-radius: 25px;
  border-style: none;
  -webkit-transition: background-color 0.15s ease-out;
  -moz-transition: background-color 0.15s ease-out;
  -o-transition: background-color 0.15s ease-out;
  transition: background-color 0.15s ease-out;
  cursor: pointer;
}

.hero .hero-main div button:hover {
  background-color: #f90011;
}

.hero .hero-main div button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

/* Simple Hero */

.simple-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 552px;
  width: 100%;
}

.simple-hero * {
  z-index: 1;
}

.simple-hero div:last-of-type {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.simple-hero .navbar {
  display: flex;
  flex-flow: row;
  justify-content: right;
  gap: 3.75rem;
  padding-top: 1rem;
  padding-right: 1.75rem;
}

.simple-hero .hero-main h1 {
  font-family: "Poppins Bold";
  padding: 0 0 2.5rem 9.5rem;
  color: #ffffff;
  font-size: 4rem;
  font-weight: 800;
  letter-spacing: 0.15em;
}

.simple-bg {
  z-index: 0;
}

/* Article Hero */

.article-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.article-hero * {
  z-index: 1;
}

.article-hero div:last-of-type {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.article-hero .navbar {
  display: flex;
  flex-flow: row;
  justify-content: right;
  gap: 3.75rem;
  padding-top: 1rem;
  padding-right: 1.75rem;
}

.article-bg {
  z-index: 0;
}

.article-hero h1 {
  padding: 0 0 4rem 4rem;
  font-weight: 500;
  font-size: 2.5rem;
  letter-spacing: 0;
  color: #ffffff;
}

/* Animation */

@keyframes backgroundchangeFadeInOut {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes backgroundchangeFadeInOut {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1440px) {
  /* Main hero */
  .main-hero .navbar {
    gap: 3rem;
  }
  .navbar a,
  a:visited {
    font-size: 0.9rem;
  }
  .navbar .router-link-active {
    font-size: 0.9rem;
  }
  .main-hero .hero-main .logo {
    width: 160px;
  }

  .main-hero .slogan img {
    width: 30rem;
  }
  .main-hero .hero-main div button {
    font-size: 1rem;
    padding: 1.25rem 3rem;
  }
  .hero .navbar {
    gap: 3rem;
  }
  .hero .navbar {
    /* gap: 1rem; */
    /* padding: 1rem 1rem; */
    /* justify-content: space-between; */
  }
  .hero .hero-main {
    /* padding-left: 1rem; */
  }
  .hero .hero-main h1 {
    font-size: 1rem;
  }
  .hero .hero-main h2 {
    font-size: 2rem;
  }
  .hero .hero-main h3 {
    font-size: 1.25rem!important;
  }
  .main-hero .hero-main h1 {
    font-size: 3rem;
    padding-top: 2rem;
    padding-bottom: 0;
  }
  .main-hero .hero-main h2 {
    font-size: 3rem;
  }
  .main-hero .hero-main h3 {
    font-size: 1rem;
  }
  .main-hero .slogan img {
    width: 20rem;
  }
  .hero .hero-main button {
    font-size: 1rem;
    padding: 1.25rem 3rem;
  }
  .simple-hero .hero-main h1 {
    font-size: 3rem;
  }
  .simple-hero .navbar {
    gap: 3rem;
  }
}
@media only screen and (max-width: 1050px) {
  .hero .navbar {
    gap: 2rem;
  } 
  .main-hero .hero-main .logo {
    width: 120px;
  }
  .main-hero .hero-main h1 {
    padding-top: 1rem;
    font-size: 2rem;
  }
  .main-hero .hero-main h2 {
    font-size: 2.75rem;
  }
  .main-hero .hero-main h3 {
    font-size: 0.75rem;
  }
  .main-hero .slogan img {
    width: 15rem;
  }
  .hero .hero-main {
    gap: 0.5rem;
    padding-left: 2rem;
  }
  .hero .hero-main h1 {
    font-size: 0.75rem;
  }
  .hero .hero-main h2 {
    font-size: 1.75rem;
    padding: 0.25rem 0;
  }
  .hero .hero-main h3 {
    font-size: 1rem!important;
  }
  .hero .hero-main button {
    font-size: 0.75rem!important;
    padding: 1rem 2.75rem!important;
  }
}
@media only screen and (max-width: 530px) {
  .main-hero .navbar {
    gap: 2rem;
  }
  .navbar a,
  a:visited {
    font-size: 0.75rem;
  }
  .navbar .router-link-active {
    font-size: 0.75rem;
  }
}
@media only screen and (max-width: 700px) {
  .main-hero .hero-main h1 {
    padding-top: 1rem;
    font-size: 1.5rem;
  }
  .main-hero .hero-main h2 {
    font-size: 2.75rem;
  }
  .main-hero .hero-main h3 {
    font-size: 0.75rem;
  }
  .main-hero .hero-main div button {
    font-size: 0.75rem;
    padding: 1rem 2rem;
  }
}
@media only screen and (max-width: 400px) {
  /* Main hero */
  .main-hero .navbar {
    gap: 1rem;
    padding: 1rem 1rem;
    justify-content: space-between;
  }
  .navbar a,
  a:visited {
    font-size: 0.6rem;
  }
  .navbar .router-link-active {
    font-size: 0.6rem;
  }
  .main-hero .hero-main .logo {
    width: 120px;
    transform: translate(0, -10rem);
  }
  .main-hero .hero-main h1 {
    font-size: 1.1rem;
    padding-top: 1rem;
    padding-bottom: 0;
  }

  .main-hero .slogan {
    padding: 0 0 1rem 0;
  }
  .main-hero .slogan img {
    width: 13rem;
  }
  .main-hero .hero-main div button {
    font-size: 0.75rem;
    padding: 1rem 2rem;
  }
  /* Hero */
  .hero .navbar {
    gap: 1rem;
    padding: 1rem 1rem;
    justify-content: space-between;
  }
  .hero .hero-main {
    padding-left: 1rem;
  }
  .hero .hero-main h1 {
    font-size: 1rem;
  }
  .hero .hero-main h2 {
    font-size: 2rem;
  }
  .hero .hero-main h3 {
    font-size: 0.75rem !important;
    max-width: 350px !important;
  }
  .hero button {
    font-size: 0.75rem !important;
    padding: 1rem 2rem !important;
  }
  /* Simple hero */
  .simple-hero .navbar {
    gap: 1rem;
    padding: 1rem 1rem;
    justify-content: space-between;
  }
  .simple-hero .hero-main h1 {
    padding-left: 0;
    font-size: 2rem;
    text-align: center;
  }
}
</style>
