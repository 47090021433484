<template>
  <div class="project-container">
    <div class="project-container-header">
      <div class="header-title">
        <h1>Edit project</h1>
      </div>
      <div class="prevent-select" v-if="!isLoading && !isError">
        <button
          :class="{ 'disabled-button': !valueChanged }"
          @click="editProject"
        >
          Simpan perubahan
        </button>
      </div>
    </div>
    <hr />
    <Loading v-if="isLoading" />
    <Error v-if="!isLoading && isError" />
    <div v-if="!isLoading && !isError" class="edit-container">
      <div class="text-input">
        <label>
          <div class="text-title">
            Nama project/Perusahaan
            <span class="requiredTag">*</span>
          </div>
          <input
            type=""
            name=""
            v-model="project.title"
            placeholder="Masukkan nama project/perusahaan"
          />
        </label>
        <label>
          <div class="text-title">
            Kategori perusahaan
            <span class="requiredTag">*</span>
          </div>
          <input
            type=""
            name=""
            v-model="project.category"
            placeholder="Masukkan kategori perusahaan"
          />
        </label>
        <!-- <label>
          <div class="text-title">
            Link website
            <span class="requiredTag">*</span>
          </div>
          <input
            type=""
            name=""
            v-model="project."
            placeholder="Masukkan link website"
          />
        </label> -->
        <label>
          <div class="text-title">
            Link Embed Map
            <span
              ><a
                href="https://www.embed-map.com/"
                target="_blank"
                rel="noopener noreferrer"
                >Ambil src dari iframe generated HTML</a
              ></span
            >
          </div>
          <input
            type=""
            name=""
            v-model="project.map"
            placeholder="Masukkan link embed map"
          />
        </label>
        <label>
          <div class="text-title">
            Deskripsi project
            <span class="requiredTag">*</span>
          </div>
          <textarea
            v-model="project.desc"
            cols="30"
            rows="6"
            placeholder="Masukkan deskripsi"
          ></textarea>
        </label>
        <label>
          <div class="text-title">
            Status project
            <span class="requiredTag">*</span>
          </div>
          <div class="option-container prevent-select">
            <div
              :class="[{ 'option-box-active': isFinished == 0 }, 'option-box']"
              @click="isFinished = 0"
            >
              <h1>Dalam pengerjaan</h1>
            </div>
            <div
              :class="[{ 'option-box-active': isFinished == 1 }, 'option-box']"
              @click="isFinished = 1"
            >
              <h1>Selesai</h1>
            </div>
          </div>
        </label>
      </div>
      <div class="image-input">
        <div class="logo-input">
          <div class="logo-title">
            <div class="image-title">Logo perusahaan</div>
          </div>
          <img
            v-if="base64.logo"
            class="logo-image"
            :src="base64.logo"
            alt=""
            draggable="false"
          />
          <img
            v-if="project.logo && !base64.logo"
            class="logo-image"
            :src="project.logo"
            alt=""
            draggable="false"
          />
          <div
            v-if="!project.logo && !base64.logo"
            class="image-container prevent-select"
          >
            <img
              src="@/assets/admin/icons/edit/image-big.svg"
              alt=""
              draggable="false"
            />
          </div>
          <input
            type="file"
            id="logo"
            name="logo"
            @change="previewImageLogo"
            class="hide"
          />
          <div class="file-selection-label prevent-select">
            <label for="logo" class="big-file-select">Ubah gambar</label>
            <!-- <div>
              <button
                @click="
                  (project.logo = null),
                    (base64.logo = null),
                    this.deleteList.push(this.rawLogo),
                    (deleteImage = true)
                "
              >
                Hapus gambar
              </button>
            </div> -->
          </div>
        </div>
        <div class="cover-input">
          <div class="image-title">
            Cover project
            <span class="requiredTag">*</span>
          </div>
          <img
            v-if="project.cover && !base64.cover"
            class="cover-image"
            :src="project.cover"
            alt=""
            draggable="false"
          />
          <img
            v-if="base64.cover"
            class="cover-image"
            :src="base64.cover"
            alt=""
            draggable="false"
          />
          <div
            v-if="!project.cover && !base64.cover"
            class="image-container prevent-select"
          >
            <img
              src="@/assets/admin/icons/edit/image-big.svg"
              alt=""
              draggable="false"
            />
          </div>
          <input
            type="file"
            id="cover"
            name="cover"
            class="hide"
            @change="previewImageCover"
          />
        </div>
        <div class="snapshots-container" v-if="project.galery">
          <div class="logo-title">
            <div class="image-title">
              Galeri project
              <!-- <span class="requiredTag">*</span>
            </div>
            <h1>Maks. 6 foto</h1> -->
            </div>
          </div>
          <div class="snapshot-item-container" v-if="project.galery">
            <div
              v-for="(galleryItem, index) in project.galery"
              :class="['snapshot-input', `snapshot-item-${index}`]"
              :key="index"
            >
              <img
                class="snapshot-image"
                :src="galleryItem.image"
                alt=""
                draggable="false"
                @click="setAsCover(gallery[index].image, index)"
              />
              <div class="snapshot-upload-button prevent-select">
                <div>
                  <button
                    @click="
                      (galleryItem.image = null),
                        this.deleteList.push(rawGallery[index].id),
                        (deleteImage = true),
                        hideItem(index)
                    "
                  >
                    Hapus gambar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="image-title image-multi">
            Gambar project
            <span class="warn">Gambar minimal tiga dan maximal sepuluh.</span>
          </div>
          <input
            type="file"
            id="images"
            value=""
            class="hide"
            @change="previewImage"
            multiple
          />
          <div class="file-selection-label prevent-select">
            <label for="images" class="big-file-select"
              >Pilih dari file manager</label
            >
          </div>
          <div v-if="!isFilesNotInputted" class="file-list">
            <div
              v-for="(fileName, index) in fileNames"
              :key="index"
              class="snapshots-container"
            >
              <img class="image-container" :src="base64images[index]" alt="" />
              <h1>• {{ fileName }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons" v-if="!isLoading">
      <div class="back-button">
        <button @click="backToDetail">Kembali</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api";
import Loading from "../Loading.vue";
import Error from "../Error.vue";
import { fileToBase64 } from "@/helpers";

export default {
  data() {
    return {
      directTo: "detail",
      base64: {
        cover: null,
        logo: null,
      },
      base64images: [],
      files: [],
      fileNames: [],
      modalOpened: false,
      ModalType: null,
      isLoading: null,
      isError: null,
      isFinished: null,
      allowSave: true,
      newCover: null,
      newLogo: null,
      rawGallery: [],
      oldValue: {},
      project: {},
      isCoverChanged: false,
      isLogoChanged: false,
      rawLogo: null,
      deleteList: [],
      deleteImage: false,
    };
  },
  components: {
    Loading,
    Error,
  },
  mounted() {
    this.$root.emitter.on("setDirectTo", (e) => {
      this.directTo = e;
    });
    this.$root.emitter.on("fetchDetailProject", (id) => {
      this.getProject(id);
    });
    // setInterval(() => {
    //   console.log(this.valueChanged);
    // }, 1000);
  },
  methods: {
    hideItem(id) {
      document.querySelector(`.snapshot-item-${id}`).classList.add("hide");
    },
    imageDelete() {
      if (!this.deleteImage) return;
      const Token = localStorage.getItem("token");
      for (let i = 0; i < this.deleteList.length; i++) {
        Api.delete(`admin/project/remove/${this.deleteList[i]}`, {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        })
          .then(() => {})
          .catch((e) => {
            console.log(e);
          });
      }
    },
    async previewImage(e) {
      let files = e.target.files;
      if (!files || files.length === 0) return;

      const allowedTypes = ["image/jpeg", "image/png"];
      this.fileNames = [];
      let validFiles = [];

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        if (allowedTypes.includes(file.type)) {
          validFiles.push(file);
          this.fileNames.push(file.name);
        } else {
          alert(
            "Please select a valid image file.\nValid image file types: jpeg, png."
          );
          this.fileNames = [];
          validFiles = [];
          event.target.value = "";
          return;
        }
      }
      if (files.length > 10)
        return (
          alert("Maximal 10 gambar."),
          (event.target.value = ""),
          (this.fileNames = []),
          (validFiles = [])
        );

      let promises = validFiles.map(fileToBase64);

      Promise.all(promises)
        .then((results) => {
          // Assuming this.base64 is an array to store the base64 data for each file.
          // Assuming this.projectInput is an array to store the corresponding files.
          results.forEach((result, index) => {
            this.base64images[index] = result?.src;
            this.files[index] = validFiles[index];
          });
        })
        .catch((error) => {
          console.error("Error while converting files to base64:", error);
        });
    },
    async previewImageLogo(e) {
      let file = e.target.files[0];
      if (!file) return;
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert(
          "Please select a valid image file.\nValid image file type: jpeg, png."
        );
        event.target.value = "";
        return;
      }
      let promise = fileToBase64(file);
      promise.then((result) => {
        this.base64.logo = result?.src;
        this.newLogo = file;
        this.isLogoChanged = true;
      });
    },
    setAsCover(id, index) {
      this.newCover = this.rawGallery[index].image;
      this.base64.cover = this.project.galery[index].image;
      this.isCoverChanged = true;
    },
    getRawGallery(id) {
      const Token = localStorage.getItem("token");
      Api.get(`admin/project/${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      }).then((res) => {
        if (res.data.data.galery.length) {
          this.rawGallery = { ...res.data.data.galery };
        }
      });
    },
    getProject(id) {
      function removeElementTag(text) {
        const textWithoutTags = text.replace(/<\/?p>/gi, "");
        const textWithoutTags2 = textWithoutTags.replace(/<\/?br>/gi, "");
        return textWithoutTags2;
      }
      const Token = localStorage.getItem("token");
      this.isLoading = true;
      Api.get(`admin/project/${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          // console.log(this.project);
          this.project = res.data.data;
          if (this.project.is_finished) {
            this.isFinished = this.project.is_finished;
          }
          if (this.project.desc) {
            this.project.desc = removeElementTag(this.project.desc);
          }
          if (this.project.cover)
            this.project.cover = `${baseURL}images/${this.project.cover}`;
          if (this.project.logo) {
            this.rawLogo = this.project.logo;
            this.project.logo = `${baseURL}images/${this.project.logo}`;
          }
          if (this.project.galery.length) {
            this.gallery = { ...this.project.galery };
            for (let i = 0; i < this.project.galery.length; i++) {
              this.project.galery[
                i
              ].image = `${baseURL}images/${this.project.galery[i].image}`;
            }
          }
          this.oldValue = { ...this.project };
          this.getRawGallery(this.project.id);
        })
        .catch((e) => {
          console.log(e);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    editProject() {
      const Token = localStorage.getItem("token");
      if (!this.valueChanged) return;
      this.isLoading = true;
      Api.post(
        `admin/project/${this.project.id}`,
        {
          title: this.project.title,
          category: this.project.category,
          desc: this.project.description,
          map: this.project.map,
          is_finished: this.project.is_finished,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Token}`,
          },
        }
      )
        .then(() => {
          this.imageDelete();
          if (this.files.length) {
            Api.post(
              `admin/project/upload/${this.project.id}`,
              {
                images: this.files,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${Token}`,
                },
              }
            );
          }
          if (this.isLogoChanged) {
            Api.post(
              `admin/project/${this.project.id}`,
              {
                logo: this.newLogo,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${Token}`,
                },
              }
            );
          }
          if (this.isCoverChanged) {
            Api.post(
              `admin/project/${this.project.id}`,
              {
                cover: this.newCover,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${Token}`,
                },
              }
            );
          }
          this.backToList();
        })
        .catch((e) => {
          console.log(e);
          this.isError = true;
          alert("Gagal menyunting project!");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    backToList() {
      this.$root.emitter.emit("backToProject", this.project.id);
    },
    backToDetail() {
      if (this.directTo == "detail") {
        this.$root.emitter.emit("openDetailProject", this.project.id);
      }
      if (this.directTo == "list") {
        this.backToList();
      }
    },
  },
  computed: {
    isGalleryEmpty() {
      return this.project.galery?.length === 0;
    },
    isCoverEmpty() {
      return !this.project.cover;
    },
    isLogoEmpty() {
      return !this.project.logo;
    },
    valueChanged() {
      return (
        this.project?.title != this.oldValue?.title ||
        this.project?.category != this.oldValue?.category ||
        this.project?.map != this.oldValue?.map ||
        this.project?.desc != this.oldValue?.desc ||
        this.isFinished != this.oldValue?.is_finished ||
        this.newCover != null ||
        this.newLogo != null ||
        this.deleteList.length ||
        this.fileNames?.length > 2
      );
    },
    isFilesNotInputted() {
      return this.fileNames?.length === 0;
    },
    isFilesLessThanThree() {
      return this.fileNames?.length < 3;
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
}

.requiredTag {
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.project-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.project-container hr {
  border: solid #e1e1e1;
  margin: 0;
  border-width: 1px;
}

.project-container-header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.header-title {
  display: flex;
  align-items: center;
}

.project-container h1 {
  font-size: 1.375rem;
  font-weight: 400;
  margin: 0;
  color: #3a3a3a;
}

.project-container-header button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
}

.project-container-header button:hover {
  background-color: #f90011;
}

.project-container-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.edit-container {
  display: flex;
  flex-wrap: wrap;
}

/* Text input */

.text-input {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  width: 52.5%;
  gap: 1rem;
}

.text-input label {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input input {
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  text-indent: 0.5rem;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input input:focus {
  outline: none;
}

.text-input textarea {
  padding: 0.5rem;
  font-family: "Poppins";
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  resize: none;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input label:nth-child(3) .text-title {
  justify-content: space-between;
}

.text-input label:nth-child(3) .text-title a {
  text-decoration: none;
}

.text-input textarea:focus {
  outline: none;
}

.text-title {
  display: flex;
  padding: 0 0.5rem;
}

.option-container {
  display: flex;
  gap: 0.5rem;
}

.option-box {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
}

.option-box:hover {
  background: #e9e9e9;
}

.option-box-active {
  background: #f90011;
  cursor: default;
}

.option-box-active:hover {
  background: #f90011 !important;
}

.option-box-active h1 {
  color: #ffffff !important;
}

.option-box .option-circle {
  width: 10px;
  height: 10px;
  border: #3a3a3a 1px solid;
  border-radius: 5px;
}

.option-box-active .option-circle {
  background: #ffffff;
}

.option-box h1 {
  font-size: 1rem;
  color: #3a3a3a;
}

.image-input {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 1rem;
}

.image-input .image-title {
  display: flex;
  font-size: 1.25rem;
  color: #989898;
}

.image-multi {
  gap: 0.5rem;
}

.cover-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cover-input .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 240px;
  background: #d9d9d9;
}

.cover-image {
  width: 480px;
  height: 240px;
  pointer-events: none;
}

.file-selection-label {
  padding: 0.75rem 0;
  display: flex;
  gap: 0.5rem;
}

.file-selection-label button {
  padding: 0.9rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #ff2230;
  border: #ff2230 solid 1px;
  background: none;
  border-radius: 5px;
  cursor: pointer;
}

.file-selection-label button:hover {
  background: #f0f0f0;
}

.file-selection-label button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}

.warn {
  display: flex;
  align-items: flex-end;
  font-size: 1rem;
}

.big-file-select {
  font-size: 1rem;
  color: #ffffff;
  background: #ff2230;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.big-file-select:hover {
  background-color: #f90011;
}

.big-file-select:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.logo-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.logo-title {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.logo-title h1 {
  font-size: 1rem;
  color: #7a7a7a;
}

.logo-title span {
  color: #ff2230;
}

.logo-input .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 240px;
  background: #d9d9d9;
}

.logo-image {
  width: 240px;
  height: 240px;
  pointer-events: none;
}

.snapshots-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 500px;
}

.file-list h1 {
  font-size: 20px;
  color: #3a3a3a;
}

.snapshots-container .image-container {
  background: #d9d9d9;
  width: 240px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.snapshot-upload-button {
  display: flex;
  justify-content: space-between;
}

.snapshot-upload-button label {
  background: #ff2230;
  color: #ffffff;
  text-align: center;
  padding: 0.75rem 0.25rem;
  border-radius: 5px;
  cursor: pointer;
}

.snapshot-upload-button label:hover {
  background-color: #f90011;
}

.snapshot-upload-button label:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.snapshot-upload-button button {
  padding: 0.9rem 0.25rem;
  font-size: 1rem;
  font-weight: 500;
  color: #ff2230;
  border: #ff2230 solid 1px;
  background: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.snapshot-upload-button div {
  width: 100%;
}
.snapshot-upload-button button:hover {
  background: #f0f0f0;
}

.snapshot-upload-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}

.snapshot-image {
  width: 240px;
  height: 120px;
  cursor: pointer;
}

.snapshot-item-container {
  display: flex;
  flex-wrap: wrap;
  width: 496px;
  gap: 1rem;
}

.snapshot-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 240px;
}
.buttons {
  padding: 0 2rem 1rem 2rem;
}
.back-button button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.back-button button:hover {
  background-color: #f90011;
}

.back-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.disabled-button {
  background-color: #707070 !important;
  cursor: default !important;
}
</style>
