<template>
  <div class="project-container">
    <div class="project-container-header">
      <div class="header-title">
        <h1>Detail project</h1>
      </div>
      <div v-if="!isLoading && !isError" class="prevent-select">
        <button @click="editProject(project.id)">Edit project</button>
      </div>
    </div>
    <hr />
    <Loading v-if="isLoading" />
    <Error v-if="!isLoading && isError" />
    <div v-if="!isLoading && !isError" class="detail-container">
      <div class="image-container">
        <div class="cover-container" v-if="!isCoverEmpty">
          <h1>Cover project</h1>
          <img :src="project.cover" alt="" draggable="false" />
        </div>
        <div class="image-details">
          <div class="logo-container" v-if="!isLogoEmpty">
            <h1>Logo</h1>
            <img :src="project.logo" alt="" draggable="false" />
          </div>
          <div class="snapshot-container" v-if="!isGalleryEmpty">
            <h1>Galeri project</h1>
            <div>
              <img
                v-for="(gallery, index) in project.galery"
                :src="gallery.image"
                :key="index"
                alt=""
                draggable="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="text-container">
        <div>
          <h1>Nama perusahaan</h1>
          <p>{{ project.title }}</p>
        </div>
        <div v-if="!isMapEmpty">
          <h1>Link Embed Map</h1>
          <p>{{ project.map }}</p>
        </div>
        <!-- <div>
          <h1>Link website</h1>
          <p>{{ project.websiteLink }}</p>
        </div> -->
        <div>
          <h1>Tipe perusahaan</h1>
          <p>{{ project.category }}</p>
        </div>
        <div>
          <h1>Status pengerjaan</h1>
          <p>{{ project.is_finished }}</p>
        </div>
        <div>
          <h1>Deskripsi</h1>
          <p v-html="project.desc"></p>
        </div>
      </div>
    </div>
    <div class="buttons" v-if="!isLoading">
      <div class="back-button">
        <button @click="backToList">Kembali</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api";
import Loading from "../Loading.vue";
import Error from "../Error.vue";

export default {
  components: {
    Loading,
    Error,
  },
  data() {
    return {
      isLoading: null,
      isError: null,
      projectId: null,
      project: {
        // name: "Perusahaan air mineral",
        // category: "Pabrik",
        // websiteLink: "https://perusahaanair.com",
        // mapLink: "https://maps.google.com",
        // description:
        //   "Water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu ",
        // status: "ongoing",
        // cover: "/admin/assets/images/project/cover.png",
        // logo: "/admin/assets/images/project/logo.png",
        // snapshot1: "/admin/assets/images/project/snapshot.png",
        // snapshot2: "/admin/assets/images/project/snapshot.png",
        // snapshot3: "/admin/assets/images/project/snapshot.png",
        // snapshot4: "/admin/assets/images/project/snapshot.png",
        // snapshot5: "/admin/assets/images/project/snapshot.png",
        // snapshot6: "/admin/assets/images/project/snapshot.png",
      },
    };
  },
  mounted() {
    this.$root.emitter.on("fetchDetailProject", (id) => {
      // console.log("fetching");
      this.getProject(id);
    });
  },
  methods: {
    getProject(id) {
      const Token = localStorage.getItem("token");
      this.isLoading = true;
      Api.get(`admin/project/${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          this.project = res.data.data;
          if (this.project.is_finished == "1") {
            this.project.is_finished = "Selesai";
          }
          if (this.project.is_finished == "0") {
            this.project.is_finished = "Belum selesai";
          }
          if (this.project.logo) {
            this.project.logo = `${baseURL}images/${this.project.logo}`;
          }

          if (this.project.cover)
            this.project.cover = `${baseURL}images/${this.project.cover}`;
          if (this.project.galery.length) {
            for (let i = 0; i < this.project.galery.length; i++) {
              this.project.galery[
                i
              ].image = `${baseURL}images/${this.project.galery[i].image}`;
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    editProject(e) {
      this.$root.emitter.emit("openEditProject", e);
    },
    backToList() {
      this.$root.emitter.emit("backToProject");
    },
  },
  computed: {
    isGalleryEmpty() {
      return this.project.galery?.length === 0;
    },
    isCoverEmpty() {
      return !this.project.cover;
    },
    isLogoEmpty() {
      return !this.project.logo;
    },
    isMapEmpty() {
      return !this.project.map;
    }
  },
};
</script>

<style scoped>
img {
  pointer-events: none;
}

.project-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.project-container hr {
  border: solid #e1e1e1;
  margin: 0;
  border-width: 1px;
}

.project-container-header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.header-title {
  display: flex;
  align-items: center;
}

.project-container h1 {
  font-size: 1.375rem;
  font-weight: 400;
  margin: 0;
  color: #3a3a3a;
}

.project-container-header button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.project-container-header button:hover {
  background-color: #f90011;
}

.project-container-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.detail-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 2rem 6rem 2rem;
  gap: 1.5rem;
}

.detail-container h1 {
  color: #989898;
  font-size: 1.25rem;
  font-weight: 500;
}

.image-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.image-container img {
  /* width: 720px; */
  height: 360px;
}

.image-details {
  display: flex;
  gap: 1.5rem;
}

.image-details img {
  /* width: 120px; */
  height: 120px;
}

.cover-container,
.logo-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.snapshot-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  width: 488px;
}

.snapshot-container div {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 488px;
}

.snapshot-container img {
  /* width: 240px; */
  height: 120px;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.text-container div {
  display: flex;
  flex-direction: column;
  max-width: 646px;
}

.text-container div p {
  font-size: 1.625rem;
  font-weight: 500;
  color: #3a3a3a;
  margin: 0;
}
.text-container div p:deep(p) {
  margin: 0;
}
.buttons {
  padding: 0 2rem 1rem 2rem;
}
.back-button button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.back-button button:hover {
  background-color: #f90011;
}

.back-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
</style>
