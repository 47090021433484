<template>
  <div class="testimony-paragraph">
    <h1>Testimoni Pelanggan</h1>
    <h2>
      PT Adhi Konsultan Utama telah dipercaya pada banyak pekerjaan yang
      melibatkan klien dari berbagai macam latar belakang, baik perusahaan
      swasta nasional di berbagai sektor usaha, lembaga pemerintah, BUMN,
      pemerintah daerah, yayasan, dan perseorangan.
    </h2>
    <hr />
  </div>
  <div class="testimony-container">
    <div class="testimony-1">
      <div
        class="testimony-item testimony-item-1"
        @mouseover="cardHover(1)"
        @mouseout="cardUnhover(1)"
      >
        <img
          :src="testimony[0]?.pp || '/user/assets/images/testimony/loading.gif'"
          alt=""
        />
        <div class="testimony-person">
          <h1>{{ testimony[0]?.name || "" }}</h1>
          <h2>{{ testimony[0]?.as || "" }}</h2>
        </div>
        <h3>
          {{ testimony[0]?.comment || "Loading" }}
        </h3>
        <div class="testimony-bg"></div>
      </div>
      <div
        class="testimony-item testimony-item-2"
        @mouseover="cardHover(2)"
        @mouseout="cardUnhover(2)"
      >
        <img
          :src="testimony[1]?.pp || '/user/assets/images/testimony/loading.gif'"
          alt=""
        />
        <div class="testimony-person">
          <h1>{{ testimony[1]?.name || "" }}</h1>
          <h2>{{ testimony[1]?.as || "" }}</h2>
        </div>
        <h3>
          {{ testimony[1]?.comment || "Loading" }}
        </h3>
        <div class="testimony-bg"></div>
      </div>
      <div
        class="testimony-item testimony-item-3"
        @mouseover="cardHover(3)"
        @mouseout="cardUnhover(3)"
      >
        <img
          :src="testimony[2]?.pp || '/user/assets/images/testimony/loading.gif'"
          alt=""
        />
        <div class="testimony-person">
          <h1>{{ testimony[2]?.name || "" }}</h1>
          <h2>{{ testimony[2]?.as || "" }}</h2>
        </div>
        <h3>
          {{ testimony[2]?.comment || "Loading" }}
        </h3>
        <div class="testimony-bg"></div>
      </div>
    </div>
    <div class="testimony-2">
      <div
        class="testimony-item testimony-item-4"
        @mouseover="cardHover(4)"
        @mouseout="cardUnhover(4)"
      >
        <img
          :src="testimony[3]?.pp || '/user/assets/images/testimony/loading.gif'"
          alt=""
        />
        <div class="testimony-person">
          <h1>{{ testimony[3]?.name || "" }}</h1>
          <h2>{{ testimony[3]?.as || "" }}</h2>
        </div>
        <h3>
          {{ testimony[3]?.comment || "Loading" }}
        </h3>
        <div class="testimony-bg"></div>
      </div>
      <div
        class="testimony-item testimony-item-5"
        @mouseover="cardHover(5)"
        @mouseout="cardUnhover(5)"
      >
        <img
          :src="testimony[4]?.pp || '/user/assets/images/testimony/loading.gif'"
          alt=""
        />
        <div class="testimony-person">
          <h1>{{ testimony[4]?.name || "" }}</h1>
          <h2>{{ testimony[4]?.as || "" }}</h2>
        </div>
        <h3>
          {{ testimony[4]?.comment || "Loading" }}
        </h3>
        <div class="testimony-bg"></div>
      </div>
      <div
        class="testimony-item testimony-item-6"
        @mouseover="cardHover(6)"
        @mouseout="cardUnhover(6)"
      >
        <img
          :src="testimony[5]?.pp || '/user/assets/images/testimony/loading.gif'"
          alt=""
        />
        <div class="testimony-person">
          <h1>{{ testimony[5]?.name || "" }}</h1>
          <h2>{{ testimony[5]?.as || "" }}</h2>
        </div>
        <h3>
          {{ testimony[5]?.comment || "Loading" }}
        </h3>
        <div class="testimony-bg"></div>
      </div>
    </div>
    <div class="testimony-3">
      <div
        class="testimony-item testimony-item-7"
        @mouseover="cardHover(7)"
        @mouseout="cardUnhover(7)"
      >
        <img
          :src="testimony[6]?.pp || '/user/assets/images/testimony/loading.gif'"
          alt=""
        />
        <div class="testimony-person">
          <h1>{{ testimony[6]?.name || "" }}</h1>
          <h2>{{ testimony[6]?.as || "" }}</h2>
        </div>
        <h3>
          {{ testimony[6]?.comment || "Loading" }}
        </h3>
        <div class="testimony-bg"></div>
      </div>
      <div
        class="testimony-item testimony-item-8"
        @mouseover="cardHover(8)"
        @mouseout="cardUnhover(8)"
      >
        <img
          :src="testimony[7]?.pp || '/user/assets/images/testimony/loading.gif'"
          alt=""
        />
        <div class="testimony-person">
          <h1>{{ testimony[7]?.name || "" }}</h1>
          <h2>{{ testimony[7]?.as || "" }}</h2>
        </div>
        <h3>
          {{ testimony[7]?.comment || "Loading" }}
        </h3>
        <div class="testimony-bg"></div>
      </div>
      <div
        class="testimony-item testimony-item-9"
        @mouseover="cardHover(9)"
        @mouseout="cardUnhover(9)"
      >
        <img
          :src="testimony[8]?.pp || '/user/assets/images/testimony/loading.gif'"
          alt=""
        />
        <div class="testimony-person">
          <h1>{{ testimony[8]?.name || "" }}</h1>
          <h2>{{ testimony[8]?.as || "" }}</h2>
        </div>
        <h3>
          {{ testimony[8]?.comment || "Loading" }}
        </h3>
        <div class="testimony-bg"></div>
      </div>
    </div>
  </div>
  <div class="testimony-navigation">
    <button class="back-button max-page">
      <img
        src="@/assets/user/icons/home/testimony/back-button.svg"
        alt=""
        @click="backPage()"
        draggable="false"
      />
    </button>
    <div class="page-navigation">
      <button class="active" @click="skipPage(0)"></button>
      <button @click="skipPage(1)"></button>
      <button @click="skipPage(2)"></button>
    </div>
    <button class="forward-button">
      <img
        src="@/assets/user/icons/home/testimony/forward-button.svg"
        alt=""
        @click="nextPage()"
        draggable="false"
      />
    </button>
  </div>
  <div class="testimony-mobile-container prevent-select" ref="container">
    <div
      class="testimony-mobile-card"
      v-for="(testimonih, index) in testimony"
      :key="index"
    >
      <div class="testimony-mobile-ghost"></div>
      <img
        :src="testimonih.pp || '/user/assets/images/testimony/loading.gif'"
        alt=""
      />
      <div class="testimony-mobile-person">
        <h1>{{ testimonih.name || "" }}</h1>
        <h2>{{ testimonih.as || "" }}</h2>
      </div>
      <h3>
        {{ testimonih.comment || "Loading" }}
      </h3>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api.js";
import { ref, onMounted } from "vue";

export default {
  setup() {
    const container = ref(null);
    let startPosition = 0;
    let startScrollLeft = 0;

    onMounted(() => {
      container.value.addEventListener("mousedown", handleMouseDown);
      container.value.addEventListener("mousemove", handleMouseMove);
      container.value.addEventListener("mouseup", handleMouseUp);
      container.value.addEventListener("mouseleave", handleMouseUp);
      container.value.addEventListener("touchstart", handleTouchStart);
      container.value.addEventListener("touchmove", handleTouchMove);
      container.value.addEventListener("touchend", handleTouchEnd);
    });

    function handleMouseDown(e) {
      startPosition = e.clientX;
      startScrollLeft = container.value.scrollLeft;
      container.value.classList.add("grabbing");
    }

    function handleMouseMove(e) {
      if (container.value.classList.contains("grabbing")) {
        const deltaX = e.clientX - startPosition;
        container.value.scrollLeft = startScrollLeft - deltaX;
      }
    }

    function handleMouseUp() {
      container.value.classList.remove("grabbing");
    }

    function handleTouchStart(e) {
      startPosition = e.touches[0].clientX;
      startScrollLeft = container.value.scrollLeft;
    }

    function handleTouchMove(e) {
      const deltaX = e.touches[0].clientX - startPosition;
      container.value.scrollLeft = startScrollLeft - deltaX;
    }

    function handleTouchEnd() {}

    return { container };
  },
  data() {
    return {
      testimony: [],
      page: 1,
    };
  },
  mounted() {
    Api.get("home", {
      headers: {
        Accept: "application/json",
      },
    })
      .then((res) => {
        this.testimony = res.data.data.testimoni;
        for (let i = 0; i < this.testimony.length; i++) {
          if (this.testimony[i].pp == null) {
            this.testimony[i].pp = "/user/assets/images/testimony/default.png";
          } else {
            this.testimony[i].pp = `${baseURL}images/${this.testimony[i].pp}`;
          }
          if (this.testimony[i].name == null) {
            this.testimony[i].name = "???";
          }
          if (this.testimony[i].comment == null) {
            this.testimony[i].comment = "???";
          }
          if (this.testimony[i].as == null) {
            this.testimony[i].as = "???";
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    skipPage(e) {
      let activePage = this.page - 1;
      if (e == activePage) return;
      const container = document.querySelector(".testimony-container");
      const backButton = document.querySelector(".back-button");
      const nextButton = document.querySelector(".forward-button");
      const buttons =
        nextButton.parentElement.querySelector(".page-navigation");

      if (e == 0) {
        const distance = 33.3;
        container.style.transform = `translateX(${distance}%)`;
        backButton.classList.add("max-page");
        nextButton.classList.remove("max-page");
      }
      if (e == 1) {
        const distance = 0;
        container.style.transform = `translateX(${distance}%)`;
        backButton.classList.remove("max-page");
        nextButton.classList.remove("max-page");
      }
      if (e == 2) {
        const distance = -33.3;
        container.style.transform = `translateX(${distance}%)`;
        backButton.classList.remove("max-page");
        nextButton.classList.add("max-page");
      }

      this.page = e + 1;
      const skipped = e;
      let childNodes = buttons.childNodes;
      let count = childNodes.length;
      for (let i = 0; i < count; ++i) {
        childNodes[i].classList.remove("active");
      }
      childNodes[skipped].classList.add("active");
    },
    nextPage() {
      if (this.page >= 3) return;
      const container = document.querySelector(".testimony-container");
      const backButton = document.querySelector(".back-button");
      const nextButton = document.querySelector(".forward-button");
      const buttons =
        nextButton.parentElement.querySelector(".page-navigation");

      if (this.page == 1) {
        backButton.classList.remove("max-page");
      }

      if (this.page == 1) {
        const distance = 0;
        container.style.transform = `translateX(${distance}%)`;
      } else if (this.page == 2) {
        const distance = -33.3;
        container.style.transform = `translateX(${distance}%)`;
      }
      this.page++;
      let activePage = this.page - 1;
      let childNodes = buttons.childNodes;
      let count = childNodes.length;
      for (let i = 0; i < count; ++i) {
        childNodes[i].classList.remove("active");
      }
      childNodes[activePage].classList.add("active");

      if (this.page == 3) {
        nextButton.classList.add("max-page");
      }
    },
    backPage() {
      if (this.page <= 1) return;
      const container = document.querySelector(".testimony-container");
      const backButton = document.querySelector(".back-button");
      const nextButton = document.querySelector(".forward-button");
      const buttons =
        nextButton.parentElement.querySelector(".page-navigation");

      if (this.page == 3) {
        nextButton.classList.remove("max-page");
      }

      if (this.page == 3) {
        const distance = 0;
        container.style.transform = `translateX(${distance}%)`;
      } else if (this.page == 2) {
        const distance = 33.3;
        container.style.transform = `translateX(${distance}%)`;
      }
      this.page--;

      let activePage = this.page - 1;
      let childNodes = buttons.childNodes;
      let count = childNodes.length;
      for (let i = 0; i < count; ++i) {
        childNodes[i].classList.remove("active");
      }
      childNodes[activePage].classList.add("active");

      if (this.page == 1) {
        backButton.classList.add("max-page");
      }
    },
    cardHover(e) {
      const cardItem = document.querySelector(`.testimony-item-${e}`);
      const cardImg = cardItem.querySelector("img");
      const personDetail = cardItem.querySelector(".testimony-person");
      const cardDescription = cardItem.querySelector("h3");
      const cardBg = cardItem.querySelector(".testimony-bg");

      cardImg.classList.add("img-hover");
      personDetail.classList.add("person-detail-hover");
      cardDescription.classList.add("description-hover");
      cardBg.classList.add("card-hover");
    },
    cardUnhover(e) {
      const cardItem = document.querySelector(`.testimony-item-${e}`);
      const cardImg = cardItem.querySelector("img");
      const personDetail = cardItem.querySelector(".testimony-person");
      const cardDescription = cardItem.querySelector("h3");
      const cardBg = cardItem.querySelector(".testimony-bg");

      cardImg.classList.remove("img-hover");
      personDetail.classList.remove("person-detail-hover");
      cardDescription.classList.remove("description-hover");
      cardBg.classList.remove("card-hover");
    },
  },
};
</script>

<style scoped>
.testimony-paragraph {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.testimony-paragraph h1 {
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
  padding-bottom: 1.5rem;
}

.testimony-paragraph h2 {
  text-align: center;
  line-height: 2.25rem;
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  margin: 0;
}

.testimony-paragraph hr {
  border-width: 0.15rem;
  border-style: solid;
  border-color: #e4000f;
  width: 15%;
}

.testimony-container {
  display: flex;
  padding-top: 3rem;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  /* overflow: hidden; */
  width: 300%;
  transform: translateX(33.3%);
  /* flex-wrap: nowrap; */
}

.testimony-1,
.testimony-2,
.testimony-3 {
  display: flex;
  justify-content: center;
  gap: 3.2rem;
  width: 100%;
}

.testimony-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  gap: 1.5rem;
  padding: 3rem 0.5rem 4.5rem 0.5rem;
}

.testimony-item img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  z-index: 1;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.testimony-person {
  z-index: 1;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.testimony-item h1 {
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
}

.testimony-item h2 {
  text-align: center;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 30px;
  color: rgba(37, 37, 37, 0.7);
  margin: 0;
}

.testimony-item h3 {
  text-align: center;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #252525;
  width: 83%;
  margin: 0;
  z-index: 1;
  -webkit-transition: transform 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

.testimony-bg {
  position: absolute;
  background-color: #ffffff;
  border: 2px solid #bdbdbd;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  transform: translateY(-3.15rem);
  z-index: 0;
  -webkit-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.testimony-navigation {
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.testimony-navigation .back-button,
.testimony-navigation .forward-button {
  background-color: #f8f8ff;
  border: none;
}

.testimony-navigation .back-button img,
.testimony-navigation .forward-button img {
  cursor: pointer;
}

.testimony-mobile-container {
  display: none;
}

.page-navigation {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.page-navigation button {
  width: 1.25rem;
  height: 1.25rem;
  border-style: none;
  border-radius: 50%;
  background-color: #bdbdbd;
  -webkit-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.page-navigation button:hover {
  background-color: #333333;
}

.page-navigation button.active {
  background-color: #ff2230;
  cursor: default;
}

.testimony-navigation button.max-page img {
  filter: brightness(0) saturate(100%) invert(92%) sepia(1%) saturate(0%)
    hue-rotate(139deg) brightness(85%) contrast(86%);
  cursor: default;
}

.card-hover {
  transform: translateY(-3.75rem);
  box-shadow: 0px 8px 8px -1px rgba(0, 0, 0, 0.25);
}

.img-hover {
  transform: translateY(-0.5rem);
}

.person-detail-hover {
  transform: translateY(-0.55rem);
}

.description-hover {
  transform: translateY(-0.25rem);
}

.grabbing {
  cursor: grabbing;
}

@media only screen and (max-width: 1473px) {
  .testimony-paragraph {
    padding-top: 2rem;
  }
  .testimony-paragraph h1 {
    font-size: 1.75rem;
    padding-bottom: 0.75rem;
  }
  .testimony-paragraph h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media only screen and (max-width: 1440px) {
  .testimony-container h3 {
    font-size: 1rem;
  }
  .testimony-container h2 {
    font-size: 1.25rem;
  }
  .testimony-container h1 {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 1050px) {
  .testimony-container {
    display: none;
  }
  .testimony-navigation {
    display: none;
  }
  .testimony-mobile-container {
    display: flex;
    gap: 1.5rem;
    overflow: auto;
    width: 80%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 1rem 0 2rem 0;
  }
  .testimony-mobile-card {
    display: flex;
    flex-direction: column;
    /* width: 200%; */
    padding: 2rem;
    align-items: center;
    background-color: #ffffff;
    border: 2px solid #bdbdbd;
    border-radius: 15px;
  }
  .testimony-mobile-ghost {
    width: 250px;
  }
  .testimony-mobile-card img {
    width: 100px;
    border-radius: 50%;
  }
  .testimony-mobile-card h1 {
    padding-top: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }
  .testimony-mobile-card h2 {
    margin: 0;
    padding-top: 0.25rem;
    font-weight: 500;
    text-align: center;
  }
  .testimony-mobile-card h3 {
    margin: 0;
    padding-top: 0.25rem;
    font-weight: 400;
    text-align: center;
    /* max-width: 600px; */
  }
}
@media only screen and (max-width: 400px) {
  .testimony-paragraph {
    width: 80%;
  }
  .testimony-paragraph h1 {
    font-size: 1.4rem;
  }
  .testimony-paragraph h2 {
    font-size: 1.2rem;
    text-align: justify;
  }
  .testimony-container {
    display: none;
  }
  .testimony-navigation {
    display: none;
  }
  .testimony-mobile-container {
    display: flex;
    gap: 1.5rem;
    overflow: auto;
    width: 80%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 1rem 0 2rem 0;
  }
  .testimony-mobile-card {
    display: flex;
    flex-direction: column;
    /* width: 200%; */
    padding: 2rem;
    align-items: center;
    background-color: #ffffff;
    border: 2px solid #bdbdbd;
    border-radius: 15px;
  }
  .testimony-mobile-ghost {
    width: 250px;
  }
  .testimony-mobile-card img {
    width: 100px;
    border-radius: 50%;
  }
  .testimony-mobile-card h1 {
    padding-top: 1rem;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }
  .testimony-mobile-card h2 {
    margin: 0;
    padding-top: 0.25rem;
    font-weight: 500;
    text-align: center;
  }
  .testimony-mobile-card h3 {
    margin: 0;
    padding-top: 0.25rem;
    font-weight: 400;
    text-align: center;
    /* max-width: 600px; */
  }
}
</style>
