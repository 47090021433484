<template>
  <div class="account-container">
    <div class="account-container-header">
      <!-- <form class="searchBar" @submit.prevent="searchAccount">
        <img
          src="@/assets/admin/icons/SearchIcon.svg"
          alt=""
          draggable="false"
        />
        <input
          type="text"
          name=""
          id=""
          placeholder="Cari akun"
          v-model="searchQuery"
        />
        <input class="hidden-enter" type="submit" value="" />
      </form> -->
      <h1>Akun</h1>
      <div class="prevent-select" v-if="!isLoading && !isError">
        <button @click="openModal('add')">
          +&nbsp;&nbsp;&nbsp;&nbsp;Tambah data
        </button>
      </div>
    </div>
    <hr />
    <Empty v-if="isEmpty" :type="'akun'" />
    <Error v-if="isError" />
    <Loading v-if="isLoading" />
    <div v-if="!isEmpty && !isError && !isLoading" class="account-list">
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                class="custom-checkbox check-all"
                v-model="selectAll"
                @click="toggleAll"
                @change="checkCheck"
              />
            </th>
            <th>ID</th>
            <th>Username</th>
            <th>Email</th>
            <!-- <th>Role</th> -->
            <th><!--empty table header for fun --></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>
              <input
                type="checkbox"
                class="custom-checkbox"
                v-model="user.checked"
                @change="checkCheck"
              />
            </td>
            <td>{{ user.id }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <!-- <td>{{ user.role }}</td> -->
            <td class="edit-button prevent-select">
              <img
                src="@/assets/admin/icons/table/hapus.svg"
                alt=""
                draggable="false"
                @click="openModal('delete'), selectUserDelete(user.id)"
              />
              <img
                src="@/assets/admin/icons/table/edit.svg"
                alt=""
                draggable="false"
                @click="openModal('edit'), selectUserEdit(user.id)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="showSelectionContainer" class="selection-active-container">
        <h1>{{ checkedAmount }} Seleksi Aktif</h1>
        <button class="prevent-select" @click="openModal('deleteBulk')">
          <img src="@/assets/admin/icons/TrashCan.svg" alt="" />Hapus item
          terpilih
        </button>
      </div>
      <div v-if="pagination && users.length > 10" class="pagination">
        <div class="page">
          <img
            src="@/assets/admin/icons/table/previous-page.svg"
            alt=""
            draggable="false"
          />
          <h1>
            Halaman
            <input type="number" v-model="currentPage" />
            dari
            <span>
              {{ page }}
            </span>
          </h1>
          <img
            src="@/assets/admin/icons/table/next-page.svg"
            alt=""
            draggable="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/admin/Empty.vue";
import Error from "@/components/admin/Error.vue";
import Loading from "@/components/admin/Loading.vue";
import { Api } from "@/api/Api";

export default {
  data() {
    return {
      users: [],
      searchQuery: "",
      isEmpty: false,
      isError: false,
      isLoading: false,
      showOptions: null,
      modalOpened: false,
      ModalType: null,
      currentPage: 1,
      page: 0,
      checkedAmount: 0,
      showSelectionContainer: false,
      meta: [],
      pagination: false,
    };
  },
  created() {
    this.getUsers();
  },
  components: {
    Empty,
    Error,
    Loading,
  },
  computed: {
    selectAll: {
      get() {
        return this.users.every((item) => item.checked);
      },
      set(value) {
        this.users.forEach((item) => {
          item.checked = value;
        });
      },
    },
  },
  methods: {
    getUsers() {
      const Token = localStorage.getItem("token");
      this.isLoading = true;
      Api.get(`admin/account`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          function truncateText(text, maxLength) {
            if (text.length <= maxLength) {
              return text;
            } else {
              return text.slice(0, maxLength) + "...";
            }
          }
          this.users = res.data.data;
          this.users.map((i) => {
            i["checked"] = false;
          });
          if (!this.users.length) this.isEmpty = true;
          if (this.users.length) {
            for (let i = 0; i < this.users.length; i++) {
              this.users[i].username = truncateText(this.users[i].username, 30);
            }
          }
          // console.log(this.users);
        })
        .catch((e) => {
          console.log(e);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
          this.checkCheck();
        });
    },
    openModal(a) {
      if (this.modalOpened) return;
      this.$root.emitter.emit("openModal", a);
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
    },
    checkCheck() {
      const checkChecked = this.users.some((check) => check.checked === true);
      this.checkedAmount = this.users.filter(
        (check) => check.checked === true
      ).length;
      if (checkChecked) {
        this.showSelectionContainer = true;
      } else this.showSelectionContainer = false;
    },
    bulkDelete() {
      const Token = localStorage.getItem("token");
      let selectedIds = this.users
        .filter((item) => item.checked)
        .map((item) => item.id);
      Api.post(
        "admin/account/bulk",
        {
          id: selectedIds,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        }
      ).then(() => {
        this.$root.emitter.emit("resetDeleteButtonUser");
        this.getUsers();
      });
    },
    selectUserEdit(a) {
      this.$root.emitter.emit("editUser", a);
    },
    selectUserDelete(a) {
      this.$root.emitter.emit("deleteUser", a);
    },
  },
  mounted() {
    this.$root.emitter.on("fetchUser", () => {
      const Token = localStorage.getItem("token");
      Api.get(`admin/account?limit=10`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      }).then((res) => {
        this.meta = res.data.meta;
        if (this.currentPage > this.meta.last_page)
          this.currentPage = this.meta.last_page;
        this.getUsers();
      });
      this.getUsers();
    });
    this.$root.emitter.on("bulkDeleteUser", () => {
      this.bulkDelete();
    });
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.account-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.account-container hr {
  border: solid #e1e1e1;
  margin: 0;
  border-width: 1px;
}

.account-container-header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.account-container-header h1 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 550;
  margin: 0;
}

.account-container-header button {
  border: none;
  background: #ff2230;
  box-shadow: 0px 60px 80px rgba(0, 0, 0, 0.03),
    0px 13.4018px 17.869px rgba(0, 0, 0, 0.0178832),
    0px 3.99006px 5.32008px rgba(0, 0, 0, 0.0121168);
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.account-container-header button:hover {
  background-color: #f90011;
}

.account-container-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.hidden-enter {
  display: none;
}

.searchBar {
  display: flex;
  align-items: stretch;
  padding: 0.5rem 1.25rem;
  width: 15%;
  border-radius: 5px;
  align-self: center;
  background: #f0f0f0;
  box-shadow: 0px 28px 53px rgba(122, 125, 126, 0.07);
  gap: 0.3rem;
}

.searchBar > img {
  aspect-ratio: 1/1;
}

.searchBar > input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  background: #f0f0f0;
}

.searchBar input::placeholder {
  color: #3a3a3a;
  font-size: 0.875rem;
  font-weight: 500;
}

.account-list table {
  width: 100%;
  border-collapse: collapse;
}

.account-list table thead th,
.account-list table tbody th {
  color: #393d43;
  font-size: 1rem;
}

.account-list table thead th:nth-of-type(1) {
  width: 3%;
}

.account-list table thead th:nth-of-type(2) {
  width: 4%;
  text-align: left;
}
.account-list table thead th:nth-of-type(3),
/* .account-list table thead th:nth-of-type(4), */
.account-list table thead th:nth-of-type(4) {
  width: 25%;
  text-align: left;
}

.account-list table thead th:nth-of-type(5) {
  /* padding: 0 1rem 0 0; */
  width: 10%;
}
.account-list table thead * {
  padding: 1rem 0;
}

.account-list table tbody tr td {
  padding: 0.75rem 0;
}

.edit-button {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.25rem;
}

.move-button {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  gap: 0;
}

.move-up {
  height: 16px;
  overflow: hidden;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.move-down {
  height: 16px;
  overflow: hidden;
  transform: rotate(180deg);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.move-button img {
  vertical-align: middle;
  padding: 0;
}

.account-list table tbody tr td img {
  vertical-align: middle;
  cursor: pointer;
}

.account-list table tbody tr td img {
  vertical-align: middle;
  cursor: pointer;
}

.account-list table tbody td:nth-of-type(1) {
  text-align: center;
}

.account-list table tbody tr {
  border-bottom: 0.5px solid rgba(225, 225, 225, 0.8);
}

.account-list table thead tr {
  border-bottom: 1px solid rgba(225, 225, 225, 0.8);
}

.account-list table thead tr input,
.account-list table tbody tr input {
  margin: 0;
  vertical-align: middle;
}

.account-list table tbody td:nth-of-type(5) {
  text-align: right;
  padding-right: 2rem;
}

.selection-active-container {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.75rem;
  align-items: center;
}

.selection-active-container h1 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
}

.selection-active-container button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #ff2230;
  border: 1px solid #ff2230;
  border-radius: 5px;
  background: none;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  cursor: pointer;
}

.selection-active-container button:hover {
  background: #f0f0f0;
}

.selection-active-container button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}

/* Pagination */

.pagination {
  display: flex;
  flex-direction: row-reverse;
}

.page {
  display: flex;
  gap: 0.5rem;
  padding: 1rem 2rem 1rem 0;
  align-items: center;
}

.page img {
  cursor: pointer;
  width: 31px;
  height: 31px;
  border-radius: 50%;
}

.page h1 {
  color: #3a3a3a;
  font-size: 1rem;
  font-weight: 500;
  word-spacing: 0.25rem;
}

.page h1 span {
  color: #1f1f1f;
  font-weight: 400;
}

.page input {
  width: 1.6rem;
  height: 1.8rem;
  text-align: center;
  color: #ff2230;
  border: #ff2230 solid 2px;
  font-size: 1rem;
}

.page input[type="number"]:focus {
  outline: none;
}

.custom-checkbox {
  position: relative;
  cursor: pointer;
  outline: none;
}

.custom-checkbox:before,
.custom-checkbox:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -6px;
  left: 0;
  transition: background-color 0.1s ease-out, border-color 0.1s ease-out;
  background-color: #ffffff;
  border: #3a3a3a 1px solid;
}

.custom-checkbox:checked:before {
  background-color: #ff2230;
  border-color: #ff2230;
}

.custom-checkbox:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: -3px;
  left: 7px;
}
</style>
