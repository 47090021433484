<template>
  <ConfirmationModal v-show="confirmationModal" />
  <div class="container">
    <Hero :simpleMode="'KARIR'" />
    <main id="form">
      <CareerMain v-if="activeCareer == 'mainCareer'" />
      <CareerTemp v-if="activeCareer == 'careerTemp'" />
      <Career-1 v-if="activeCareer == 'careerOne'" />
      <Career-2 v-if="activeCareer == 'careerTwo'" />
      <Career-3 v-if="activeCareer == 'careerThree'" />
      <Career-4 v-if="activeCareer == 'careerFour'" />
      <Career-5 v-if="activeCareer == 'careerFive'" />
      <CareerSent v-if="activeCareer == 'careerSent'" />
    </main>
    <Footer />
  </div>
</template>

<script>
import Hero from "@/components/user/Hero.vue";
import CareerMain from "@/components/user/career/CareerMain.vue";
import CareerTemp from "@/components/user/career/Career-Temp.vue";
import Career1 from "@/components/user/career/Career-1.vue";
import Career2 from "@/components/user/career/Career-2.vue";
import Career3 from "@/components/user/career/Career-3.vue";
import Career4 from "@/components/user/career/Career-4.vue";
import Career5 from "@/components/user/career/Career-5.vue";
import CareerSent from "@/components/user/career/CareerSent.vue";
import ConfirmationModal from "@/components/user/career/CareerConfirmation.vue";
import Footer from "@/components/user/Footer.vue";
import { Api } from "@/api/Api";

function replaceNullWith(obj, replacement) {
  // Check if the input is an object
  if (typeof obj === "object" && obj !== null) {
    // Iterate through the object properties
    for (const key in obj) {
      // Using Object.prototype.hasOwnProperty indirectly
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // Check if the property value is null
        if (obj[key] === null) {
          // Replace null with the desired value
          obj[key] = replacement;
        } else if (typeof obj[key] === "object") {
          // If the property value is another object, recursively call the function
          replaceNullWith(obj[key], replacement);
        }
      }
    }
  }
}

export default {
  components: {
    Hero,
    CareerMain,
    CareerTemp,
    Career1,
    Career2,
    Career3,
    Career4,
    Career5,
    CareerSent,
    ConfirmationModal,
    Footer,
  },
  data() {
    return {
      confirmationModal: false,
      activeCareer: "mainCareer",
      isHiring: null,
      jobSelected: "",
      unsavedChanges: false,
      photoResult: {},
      careerResult: {
        as: "Direktur",
        personal: {},
        education: {},
        experience: {},
        job_experience: {},
        extra: {},
      },
    };
  },
  mounted() {
    // this.openConfirmationModal();
    // this.sendCareer();
    this.$root.emitter.on("setPersonal", (personalData) => {
      this.careerResult.personal = { ...personalData };
      // console.log(this.careerResult);
      // console.log(personalData);
    });
    this.$root.emitter.on("setEducation", (educationData) => {
      this.careerResult.education = { ...educationData };
    });
    this.$root.emitter.on("setExperience", (experienceData) => {
      this.careerResult.experience = { ...experienceData };
    });
    this.$root.emitter.on("setJob", (jobData) => {
      this.careerResult.job_experience = { ...jobData };
    });
    this.$root.emitter.on("setExtra", (extraData) => {
      this.careerResult.extra = { ...extraData };
      // console.log(this.careerResult.extra);
      // console.log(this.careerResult);
    });
    this.$root.emitter.on("setPhoto", (photoData) => {
      if (photoData.nama_lengkap)
        this.photoResult.nama_lengkap = photoData.nama_lengkap;
      setTimeout(() => {
        this.careerResult.personal.nama_lengkap = this.photoResult.nama_lengkap;
      }, 2);
      if (photoData.pp) this.photoResult.pp = photoData.pp;
      if (photoData.signature) this.photoResult.signature = photoData.signature;
      if (photoData.pendidikan_1)
        this.photoResult.pendidikan_1 = photoData.pendidikan_1;
      if (photoData.pendidikan_2)
        this.photoResult.pendidikan_2 = photoData.pendidikan_2;
      if (photoData.pendidikan_3)
        this.photoResult.pendidikan_3 = photoData.pendidikan_3;
      if (photoData.seminar_1) this.photoResult.seminar_1 = photoData.seminar_1;
      if (photoData.seminar_2) this.photoResult.seminar_2 = photoData.seminar_2;
      if (photoData.kerja_1) this.photoResult.kerja_1 = photoData.kerja_1;
      if (photoData.kerja_2) this.photoResult.kerja_2 = photoData.kerja_2;
      if (photoData.kerja_3) this.photoResult.kerja_3 = photoData.kerja_3;
    });
    this.$root.emitter.on("selectJob", (selectedCareer) => {
      this.activeCareer = selectedCareer;
      document.querySelector("#form").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    });
    this.$root.emitter.on("applyJob", (a) => {
      this.jobSelected = a;
    });
    this.$root.emitter.on("closeConfirmationModal", () => {
      if (!this.confirmationModal) return;
      const modalContainerAnimationOut = document.querySelector(
        ".confirmation-modal"
      );
      const modalContainer = document.querySelector(".modal-container");
      modalContainerAnimationOut.classList.remove("confirmation-modal-active");
      modalContainer.classList.remove("modal-container-active");
      setTimeout(() => {
        this.confirmationModal = false;
      }, 100);
    });
    this.$root.emitter.on("openConfirmationModal", () => {
      this.openConfirmationModal();
    });
    this.$root.emitter.on("sendCareer", () => {
      this.sendCareer();
    });
  },
  methods: {
    openConfirmationModal() {
      if (this.confirmationModal) return;
      this.confirmationModal = true;
      const modalContainerAnimationIn = document.querySelector(
        ".confirmation-modal"
      );
      const modalContainer = document.querySelector(".modal-container");
      setTimeout(() => {
        modalContainerAnimationIn.classList.add("confirmation-modal-active");
        modalContainer.classList.add("modal-container-active");
      }, 100);
    },
    sendCareer() {
      replaceNullWith(this.careerResult, "-");
      Api.post("photo-career", this.photoResult, {
        headers: {
          "content-type": "multipart/form-data",
        },
      }).then(() => {
        Api.post("form-career", this.careerResult, {
          headers: {
            Accept: "application/json",
          },
        })
          .then(() => {
            // console.log("career sent");
            this.$root.emitter.emit("careerConfirmationSuccessSending");
            // console.log(res);
          })
          .catch(() => {
            // console.log(e);
            this.$root.emitter.emit("careerConfirmationFailedSending");
          });
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges) {
      const confirmMessage =
        "You have unsaved changes. Are you sure you want to leave?";
      if (window.confirm(confirmMessage)) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.container {
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  /* min-height: 100vh; */
  /* background: #f8f8ff; */
}

#form {
  display: flex;
  flex-direction: column;
  background: #f8f8ff;
  padding: 3rem 3rem 6rem 3rem;
  gap: 2rem;
}

footer {
  margin-top: auto;
}
</style>
