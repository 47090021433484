<template>
  <div class="notice prevent-select">
    <div>
      <img src="@/assets/user/icons/career/info.svg" alt="" draggable="false" />
    </div>
    <div>
      <h1>informasi penting</h1>
      <h1>
        isi formulir pendaftaran dengan benar sesuai dengan data diri Anda.
        formulir bertanda <span class="required">*</span> wajib diisi.<br />setelah
        Anda konfirmasi kirim maka Anda tidak dapat merubah data apapun
      </h1>
    </div>
  </div>
  <div class="main-container">
    <div class="form-header">
      <h1>Formulir pendaftaran</h1>
      <hr />
      <img
        src="@/assets/user/images/career/step-1.svg"
        alt=""
        draggable="false"
        class="prevent-select"
      />
    </div>
    <form class="career-form" @submit.prevent>
      <div>
        <h1>DATA PRIBADI</h1>
        <hr />
      </div>
      <div class="photo prevent-select">
        <h2>Foto diri<span class="required">*</span></h2>
        <img
          class="image-previewed"
          v-if="base64"
          :src="base64"
          alt="preview-img"
        />
        <div v-else class="image-preview"><p>120X180</p></div>
        <div class="select-file-div">
          <label for="img-select" id="photo-input"
            >Pilih dari file manager</label
          >
        </div>
        <h3>
          Untuk mengubah Aspect Ratio Anda dapat menggunakan
          <a
            href="https://imresizer.com/resize-image-to-3x4cm"
            target="_blank"
            rel="noopener noreferrer"
            >website ini</a
          >.
        </h3>
        <input
          type="file"
          id="img-select"
          class="hide"
          accept="image/*"
          @change="previewImage"
        />
      </div>
      <div class="form-questions">
        <div class="horizontal-q">
          <h2>Nama Lengkap<span class="required">*</span></h2>
          <input type="text" v-model="imgData.nama_lengkap" />
        </div>
        <div class="horizontal-q">
          <h2>Gender<span class="required">*</span></h2>
          <div class="gender-radio-input">
            <label>
              <input type="radio" value="Laki-Laki" v-model="personal.gender" />
              Laki&#8209;laki
            </label>
            <label>
              <input type="radio" value="Perempuan" v-model="personal.gender" />
              Perempuan
            </label>
          </div>
        </div>
        <div class="textarea-q">
          <h2>Alamat saat ini<span class="required">*</span></h2>
          <textarea rows="4" v-model="personal.alamat"></textarea>
        </div>
        <div class="horizontal-q">
          <h2>RT/RW<span class="required">*</span></h2>
          <input
            type="text"
            @input="rtrwFormat"
            maxlength="5"
            v-model="personal.rt_rw"
          />
        </div>
        <div class="city-q">
          <label>
            <div>Provinsi<span class="required">*</span></div>
            <input type="text" v-model="personal.provinsi" />
          </label>
          <label>
            <div>Kota<span class="required">*</span></div>
            <input type="text" v-model="personal.kota" />
          </label>
          <label>
            <div>Kecamatan<span class="required">*</span></div>
            <input type="text" v-model="personal.kecamatan" />
          </label>
          <label>
            <div>Kelurahan<span class="required">*</span></div>
            <input type="text" v-model="personal.kelurahan" />
          </label>
        </div>
        <div class="horizontal-q">
          <h2>Kontak Darurat<span class="required">*</span></h2>
          <input
            type="text"
            @input="numberOnly"
            v-model="personal.telp_darurat"
            maxlength="13"
          />
        </div>
        <div class="horizontal-q">
          <h2>No. Telepon Rumah<span class="required">*</span></h2>
          <input
            type="text"
            @input="numberOnly"
            v-model="personal.telp_rumah"
            maxlength="13"
          />
        </div>
        <div class="horizontal-q">
          <h2>No. HP 1<span class="required">*</span></h2>
          <input
            type="text"
            @input="numberOnly"
            v-model="personal.no_hp"
            maxlength="13"
          />
        </div>
        <div class="horizontal-q">
          <h2>Email<span class="required">*</span></h2>
          <input type="email" v-model="personal.email" />
        </div>
        <div class="horizontal-q">
          <h2>Tempat &#38; Tgl Lahir<span class="required">*</span></h2>
          <input type="text" v-model="personal.ttl" />
        </div>
        <div class="horizontal-q">
          <h2>Agama<span class="required">*</span></h2>
          <input type="text" v-model="personal.agama" />
        </div>
        <div class="marriage-status">
          <h2>Status Perkawinan<span class="required">*</span></h2>
          <div class="marriage-status-radio">
            <div>
              <label>
                <input
                  type="radio"
                  v-model="personal.status_perkawinan"
                  value="Single"
                />
                Single
              </label>
              <label>
                <input
                  type="radio"
                  v-model="personal.status_perkawinan"
                  value="Menikah"
                />
                Menikah
              </label>
              <label>
                <input
                  type="radio"
                  v-model="personal.status_perkawinan"
                  value="Cerai"
                />
                Cerai
              </label>
            </div>
            <div>
              <input
                type="radio"
                v-model="personal.status_perkawinan"
                :value="otherStatus ?? null"
              />
              <input type="text" placeholder="Lainnya" v-model="otherStatus" />
            </div>
          </div>
        </div>
        <div class="horizontal-q">
          <h2>No. KTP / SIM<span class="required">*</span></h2>
          <input type="text" @input="numberOnly" v-model="personal.ktp_sim" />
        </div>
        <div class="horizontal-q">
          <h2>Jenis Kendaraan 1<span class="required">*</span></h2>
          <input type="text" v-model="personal.kendaraan" />
        </div>
        <div class="horizontal-q">
          <h2>No. Polisi<span class="required">*</span></h2>
          <input type="text" v-model="personal.no_polisi" />
        </div>
        <div class="blood">
          <div class="blood-type">
            <h2>Golongan Darah<span class="required">*</span></h2>
            <div class="radio-selection">
              <label>
                <input type="radio" v-model="personal.gol_darah" value="A" />
                A
              </label>
              <label>
                <input type="radio" v-model="personal.gol_darah" value="B" />
                B
              </label>
              <label>
                <input type="radio" v-model="personal.gol_darah" value="AB" />
                AB
              </label>
              <label>
                <input type="radio" v-model="personal.gol_darah" value="O" />
                O
              </label>
            </div>
          </div>
          <div class="rhesus-type">
            <h2>Rhesus<span class="required">*</span></h2>
            <div class="radio-selection">
              <label>
                <input type="radio" v-model="personal.rhesus" value="+" />
                +
              </label>
              <label>
                <input type="radio" v-model="personal.rhesus" value="-" />
                -
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="next-button prevent-select">
      <button @click="validateCheck">Selanjutnya</button>
    </div>
  </div>
</template>

<script>
import { fileToBase64 } from "@/helpers/index.js";

export default {
  data() {
    return {
      base64: null,
      imgData: {
        nama_lengkap: "",
        pp: null,
      },
      personal: {
        gender: "",
        alamat: "",
        rt_rw: "",
        provinsi: "",
        kota: "",
        kecamatan: "",
        kelurahan: "",
        telp_darurat: null,
        telp_rumah: null,
        no_hp: null,
        email: "",
        ttl: "",
        agama: "",
        status_perkawinan: "",
        ktp_sim: "",
        kendaraan: "",
        no_polisi: "",
        gol_darah: "",
        rhesus: "",
      },
      otherStatus: null,
      allowNext: false,
    };
  },
  methods: {
    async previewImage(e) {
      let file = e.target.files[0];
      if (!file) return;
      const img = new Image();
      const reader = new FileReader();

      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert(
          "Please select a valid image file.\nValid image file type: jpeg, png."
        );
        event.target.value = "";
        return;
      }
      reader.onload = (e) => {
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          const aspectRatio = width / height;

          // Define the expected aspect ratio
          const expectedAspectRatio = 3 / 4;

          // Define the allowed tolerance for aspect ratio comparison
          const tolerance = 0.01;

          // Check if the aspect ratio is within the allowed tolerance
          if (Math.abs(aspectRatio - expectedAspectRatio) > tolerance) {
            alert("Aspect ratio should be 3:4.");
            e.target.value = "";
            // Optionally, you can reset the file input to allow the user to select a new file.
            // event.target.value = '';
          } else {
            let promise = fileToBase64(file);
            promise.then((result) => {
              this.base64 = result?.src;
              this.imgData.pp = file;
            });
          }
        };

        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    rtrwFormat() {
      const value = event.target.value;

      const digitsOnly = value.replace(/\D/g, "");

      let formattedValue = "";
      for (let i = 0; i < digitsOnly.length; i++) {
        if (i === 2) {
          formattedValue += "/";
        }
        if (i === 4 && formattedValue[3] === "/") {
          formattedValue = formattedValue.slice(0, 3) + formattedValue.slice(4);
        }
        formattedValue += digitsOnly[i];
      }

      event.target.value = formattedValue;
    },
    numberOnly() {
      const value = event.target.value;

      const digitsOnly = value.replace(/\D/g, "");

      event.target.value = digitsOnly;
    },
    selectJob(selectedCareer) {
      this.$root.emitter.emit("selectJob", selectedCareer);
    },
    emptyCheck() {
      if (
        !this.imgData.pp ||
        !this.imgData.nama_lengkap ||
        !this.personal.gender ||
        !this.personal.alamat ||
        !this.personal.rt_rw ||
        !this.personal.provinsi ||
        !this.personal.kota ||
        !this.personal.kecamatan ||
        !this.personal.kelurahan ||
        !this.personal.telp_darurat ||
        !this.personal.telp_rumah ||
        !this.personal.no_hp ||
        !this.personal.email ||
        !this.personal.ttl ||
        !this.personal.agama ||
        this.personal.status_perkawinan == "" ||
        !this.personal.ktp_sim ||
        !this.personal.kendaraan ||
        !this.personal.no_polisi ||
        !this.personal.gol_darah ||
        !this.personal.rhesus
      ) {
        this.allowNext = false;
      } else this.allowNext = true;
    },
    validateCheck() {
      this.emptyCheck();
      // console.log(this.allowNext);
      if (this.allowNext) {
        this.personal.rt_rw = this.personal.rt_rw.split("/").join("\\/");
        this.$root.emitter.emit("setPhoto", this.imgData);
        this.$root.emitter.emit("setPersonal", this.personal);
        this.selectJob("careerTwo");
      } else alert("Tolong isi kolom yang wajib diisi.");
    },
  },
};
</script>

<style scoped>
.notice {
  display: flex;
  background: #ffffff;
  padding: 2rem 2rem 2rem 2rem;
  gap: 1rem;
}

.notice div:last-of-type {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.notice h1 {
  margin: 0;
  font-weight: 500;
}

.notice h1:first-of-type {
  font-size: 2rem;
  color: #ff0010;
}

.notice h1:last-of-type {
  font-size: 1.85rem;
  color: #424242;
}

.main-container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.form-header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
}

.form-header h1 {
  margin: 0;
  font-weight: 500;
  font-size: 2rem;
  padding: 2rem 0 0 2rem;
}

.form-header hr {
  width: 100%;
  border-style: solid;
  color: #bcbcbc;
}

.form-header img {
  text-align: center;
  vertical-align: middle;
  width: 95%;
  padding-left: 2.5%;
  pointer-events: none;
}

/* .career-form::before {
  content: '';
  position: absolute;
  background: pink;
  width: 100%;
  height: 100%;
  z-index: -1;
} */

.career-form {
  display: flex;
  flex-direction: column;
  padding: 1rem 4rem 6rem 4rem;
  gap: 1.5rem;
}

.career-form h1 {
  margin: 0;
  padding: 0 0 1rem 2rem;
  font-size: 2rem;
  color: #000000;
}

.career-form hr {
  width: 100%;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
}

.photo {
  padding: 0 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.photo .image-preview {
  display: flex;
  background: #d9d9d9;
  width: 120px;
  height: 180px;
  justify-content: center;
  align-items: center;
}

.photo h2,
.form-questions h2 {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
}

.photo h3 {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  color: #000000;
}

.image-preview p {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

.image-previewed {
  vertical-align: middle;
  width: 120px;
  height: 180px;
  pointer-events: none;
  object-fit: cover;
}

.photo .select-file-div {
  padding: 0.6rem 0 0.75rem 0;
}

.photo label {
  height: 3rem;
  font-weight: 500;
  font-size: 1.25rem;
  color: #ffffff;
  background: #ff2230;
  border-radius: 5px;
  border-style: none;
  padding: 0.6rem 1rem;
  cursor: pointer;
}

.photo label:hover {
  background-color: #f90011;
}

.photo label:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.form-questions {
  display: flex;
  flex-direction: column;
  row-gap: 1.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.form-questions .horizontal-q {
  display: flex;
  align-items: center;
}

.horizontal-q h2:first-of-type {
  width: 25%;
  font-weight: 400;
  font-size: 1.75rem;
}

.horizontal-q input[type="text"] {
  width: 75%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

.horizontal-q input[type="number"] {
  width: 75%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
  -moz-appearance: textfield;
}

.horizontal-q input[type="email"] {
  width: 75%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.horizontal-q label {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1.75rem;
}

input[type="radio"] {
  box-sizing: border-box;
  margin: 0;
  background-color: #fff;
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: #ff2230;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #000000;
  border-radius: 50%;
  transform: translateY(0.25rem);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="radio"]:checked {
  border: 0.1em solid #ff2230;
  cursor: default;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #ff2230;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.gender-radio-input {
  display: flex;
  gap: 6rem;
  height: 3.5rem;
  align-items: center;
}

.textarea-q {
  display: flex;
}

.textarea-q h2 {
  width: 25%;
  padding-top: 0.5rem;
}

.textarea-q textarea {
  width: 73%;
  padding: 1rem;
  font-family: "Poppins";
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  resize: none;
  font-size: 1.5rem;
  color: #3a3a3a;
  outline: none;
}

.city-q {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1.75rem;
}

.city-q label {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 47%;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
  row-gap: 1.25rem;
}

.city-q label:last-of-type {
  padding-bottom: 0.25rem;
}

.city-q input {
  box-sizing: border-box;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

.marriage-status {
  display: flex;
}

.marriage-status h2 {
  width: 25%;
  padding-top: 0.5rem;
}

.marriage-status-radio {
  display: flex;
  flex-direction: column;
  width: 75%;
  row-gap: 1rem;
}

.marriage-status-radio div:first-of-type {
  display: flex;
  gap: 6rem;
  height: 3.5rem;
  align-items: center;
}

.marriage-status-radio div:first-of-type label {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1.75rem;
}

.marriage-status-radio div:last-of-type {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.marriage-status-radio div:last-of-type input[type="text"] {
  width: 96%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

.marriage-status-radio div:last-of-type input[type="radio"] {
  font-size: 1.75rem;
  transform: translateY(0);
}

.blood {
  display: flex;
  gap: 8rem;
}

.blood-type,
.rhesus-type {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.radio-selection {
  display: flex;
  gap: 6rem;
}

.radio-selection label {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1.75rem;
}

.next-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 4rem 4rem 0;
}

.next-button button {
  font-weight: 500;
  font-size: 1.75rem;
  color: #ffffff;
  background: #ff2230;
  border-radius: 5px;
  border-style: none;
  padding: 1rem 1rem;
  cursor: pointer;
}

.next-button button:hover {
  background-color: #f90011;
}

.next-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
@media only screen and (max-width: 1512px) {
  .notice {
    gap: 0.75rem;
  }
  .notice img {
    width: 2rem;
  }
  .notice h1:first-of-type {
    font-size: 1.5rem;
  }
  .notice h1:last-of-type {
    font-size: 1.25rem;
  }
  .notice div:last-of-type {
    gap: 0.5rem;
  }
  .career-form h1 {
    font-size: 1.75rem;
  }
  .photo h2 {
    font-size: 1.5rem;
  }
  .photo h3 {
    font-size: 1rem;
  }
  .photo label {
    font-size: 1rem;
  }
  .form-header h1 {
    font-size: 1.5rem;
  }
  .horizontal-q h2:first-of-type,
  .textarea-q h2 {
    font-size: 1.5rem;
  }
  .horizontal-q input[type="text"],
  .horizontal-q input[type="email"],
  .horizontal-q input[type="number"] {
    height: 3rem;
    font-size: 1.25rem;
  }
  .textarea-q textarea {
    font-size: 1.25rem;
  }
  .city-q label {
    font-size: 1.5rem;
  }
  .marriage-status h2 {
    font-size: 1.5rem;
  }
  .marriage-status-radio div:first-of-type label {
    gap: 1rem;
    font-size: 1.5rem;
  }
  .marriage-status-radio div:last-of-type input[type="text"] {
    font-size: 1.25rem;
    height: 3rem;
  }
  .marriage-status-radio div:last-of-type input[type="radio"] {
    font-size: 1.5rem;
  }
  .blood-type h2,
  .rhesus-type h2 {
    font-size: 1.5rem;
  }
  .radio-selection label {
    font-size: 1.5rem;
    gap: 1rem;
  }
  .gender-radio-input label {
    font-size: 1.5rem;
    gap: 1rem;
  }
  .next-button button {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 400px) {
  .notice {
    padding: 1rem;
  }
  .notice img {
    width: 1.75rem;
  }
  .notice h1:first-of-type {
    font-size: 1rem;
  }
  .notice h1:last-of-type {
    font-size: 0.75rem;
  }
  .notice div:last-of-type {
    gap: 0.5rem;
  }
  .form-header {
    gap: 1rem;
    overflow: auto;
  }
  .form-header h1 {
    padding: 1rem;
    padding-bottom: 0;
    font-size: 1.25rem;
  }
  .form-header img {
    width: 30rem;
  }
  .career-form {
    padding: 1rem 1rem;
  }
  .career-form h1 {
    padding: 0 0 0.25rem 1rem;
    font-size: 1rem;
  }
  .photo {
    padding: 0 0.5rem;
  }
  .photo h2 {
    font-size: 1rem;
  }
  .photo h3 {
    font-size: 0.75rem;
    margin: 0;
  }
  .photo label {
    font-size: 1rem;
  }
  .form-questions {
    padding: 0 0.5rem;
  }
  .form-questions h2 {
    font-size: 1rem;
  }
  .form-questions .horizontal-q {
    flex-direction: column;
    align-items: flex-start;
  }
  .horizontal-q h2:first-of-type {
    font-size: 1rem;
    width: auto;
  }
  .horizontal-q input[type="text"],
  .horizontal-q input[type="email"],
  .horizontal-q input[type="number"] {
    width: 100%;
    height: 2rem;
    box-sizing: border-box;
    font-size: 1rem;
    text-indent: 0.5rem;
  }
  .gender-radio-input {
    height: auto;
    gap: 1rem;
  }
  .gender-radio-input label {
    font-size: 1rem;
    gap: 0.5rem;
  }
  .textarea-q {
    flex-direction: column;
  }
  .textarea-q h2 {
    font-size: 1rem;
    width: auto;
  }
  .textarea-q textarea {
    width: auto;
    height: 4rem;
    font-size: 1rem;
    padding: 0.5rem;
  }
  .city-q label {
    font-size: 1rem;
    gap: 0;
  }
  .city-q label input {
    height: 2rem;
    box-sizing: border-box;
    font-size: 1rem;
    text-indent: 0.5rem;
  }
  .marriage-status {
    flex-direction: column;
  }
  .marriage-status h2 {
    width: auto;
  }
  .marriage-status-radio {
    flex-direction: column;
  }
  .marriage-status-radio div:first-of-type label {
    font-size: 1rem;
  }
  .marriage-status-radio div:first-of-type {
    gap: 1rem;
    flex-wrap: wrap;
    height: auto;
  }
  .marriage-status-radio div:last-of-type input[type="text"] {
    width: 5rem;
    height: 2rem;
    font-size: 1rem;
    text-indent: 0.5rem;
  }
  .marriage-status-radio div:last-of-type input[type="radio"] {
    font-size: 1rem;
  }
  .blood {
    flex-direction: column;
    gap: 1rem;
  }
  .radio-selection {
    gap: 1rem;
  }
  .radio-selection label {
    font-size: 1rem;
    gap: 0.5rem;
  }
  .blood-type,
  .rhesus-type {
    gap: 0;
  }
  .next-button button {
    font-size: 1rem;
    padding: 0.75rem;
  }
  .next-button {
    padding: 1rem 1rem;
  }
}
</style>
