<template>
  <div class="galery-container">
    <h1>GALERI</h1>
    <div class="board-container">
      <div class="empty-image" v-if="!galery.length">
        <img src="@/assets/loading.svg" alt="" />
      </div>
      <div
        v-for="(item, index) in galery"
        :key="index"
        class="board-item"
        v-else
      >
        <img :src="item.img" @click="openImage(item.img)" alt="" />
        <h3>{{ item.desc }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api";
export default {
  data() {
    return {
      galery: [],
    };
  },
  mounted() {
    Api.get("galeries").then((res) => {
      this.galery = res.data.data;
      for (let i = 0; i < this.galery.length; i++) {
        this.galery[i].img = `${baseURL}images/${this.galery[i].img}`;
      }
    });
  },
  methods: {
    openImage(e) {
      this.$root.emitter.emit("openImage", e);
    },
  },
};
</script>

<style scoped>
h1 {
  font-weight: 600;
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  color: #ff2230;
  padding-left: 2rem;
}

img {
  pointer-events: none;
}

.board-container {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 4rem;
}

.board-container img {
  /* width: 600px; */
  height: 260px;
  vertical-align: bottom;
  object-fit: cover;
  aspect-ratio: 4/2;
}

.board-item {
  text-align: center;
  width: 520px;
}

.board-item h2 {
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  color: #252525;
  padding-top: 0.45rem;
  margin: 0;
}

.board-item h3 {
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  color: rgba(37, 37, 37, 0.7);
  margin: 0;
}

.board-item img {
  pointer-events: visible;
  cursor: pointer;
}

.empty-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70rem;
  height: 20rem;
}

.empty-image img {
  mix-blend-mode: multiply;
  object-fit: contain;
}

.empty-image h6 {
  font-size: 2.5rem;
  margin: 0;
  font-weight: 300;
}

@media only screen and (max-width: 1440px) {
  h1 {
    font-size: 2rem;
    margin: 0;
    padding: 2rem 0 2rem 2rem;
  }
  .board-container {
    padding-top: 0;
  }
  .board-item h3 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  h1 {
    padding: 0;
    margin-bottom: 0;
  }
  .galery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .board-container {
    height: auto;
    width: auto;
  }
  .board-container img {
    height: 140px;
  }
  .board-item {
    width: auto;
  }
  .board-item h3 {
    font-size: 1rem;
  }
}
</style>
