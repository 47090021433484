<template>
  <ViewImage />
  <div class="container">
    <Hero
      :mainTitle="'TENTANG KAMI'"
      :title="'TENTANG KAMI'"
      :text="'Dengan berbagai macam pengalaman dan tim yang terlatih, kami siap membantu Anda mengatasi setiap tantangan yang mungkin terjadi dalam project Anda melalui tawaran solusi yang tepat dan inovatif. Segera hubungi kami untuk informasi lebih lanjut tentang layanan kami.'"
      :textSize="'1.5rem'"
      :buttonText="'Hubungi Kami'"
      :buttonDirection="'/home#reach-us'"
      :justify="true"
    />
    <article id="content">
      <div class="content-container">
        <div class="content-navbar">
          <h1>TENTANG KAMI</h1>
          <AboutUsSidebar />
        </div>
        <div class="main-content">
          <router-view />
        </div>
      </div>
    </article>
    <Footer />
  </div>
</template>

<script>
import Hero from "@/components/user/Hero.vue";
import AboutUsSidebar from "@/components/user/about-us/AboutUsSidebar.vue";
import Footer from "@/components/user/Footer.vue";
import ViewImage from "@/components/ViewImage.vue";
// import { API, baseURL } from '@/api/Api';

export default {
  components: {
    Hero,
    AboutUsSidebar,
    Footer,
    ViewImage,
  },
};
</script>

<style scoped>
.container {
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  max-width: 100vw;
}

/* Content */

#content {
  background-color: #f8f8ff;
}

.content-container {
  display: flex;
  padding: 4.5rem 2rem 0 6rem;
  gap: 7rem;
  min-height: 900px;
}

.content-navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 4.5rem;
  height: 25%;
}

.content-navbar h1 {
  color: #ff2230;
  font-size: 2.25rem;
  padding-bottom: 1rem;
}

.main-content {
  max-width: 75%;
}
@media only screen and (max-width: 1440px) {
.content-container {
  padding: 4.5rem 2rem 4.5rem 6rem;

}
  .content-navbar h1 {
    font-size: 2rem;
    margin: 0;
    padding: 2rem 0;
  }
}
@media only screen and (max-width: 1400px) {
  .content-container {
    align-items: flex-start!important;
  }
  .main-content {
    max-width: 65%;
  }
}

@media only screen and (max-width: 1050px) {
  .content-container {
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  .content-container h1 {
    margin-top: 0;
  }
  .content-navbar {
    position: static;
    top: 2rem;
  }
  .content-navbar h1 {
    font-size: 2rem;
    margin-bottom: 0;
    padding: 0 0 1.5rem 0;
  }
  .main-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .content-container {
    padding: 2rem;
    flex-direction: column;
    align-items: center;
  }
  .content-container h1 {
    margin-top: 0;
  }
  .content-navbar {
    position: static;
    top: 2rem;
  }
  .content-navbar h1 {
    font-size: 2rem;
    margin-bottom: 0;
    padding: 0 0 1.5rem 0;
  }
}
</style>
