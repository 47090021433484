<template>
  <div class="temp-container">
    <div>
      ✅ S1 Teknik Lingkungan (memiliki Sertifikat Amdal A atau Amdal B)<br />✅️
      S1 Teknik Arsitektur (Bisa menggambar 3D)<br />✅️ S1 Teknik Elektro Arus
      Kuat (Bisa menghitung diagram wiring dan DIALux evo 10.0)<br />✅️ S1
      Teknik Sipil (Bisa ETABS/SAP 2000)<br />✅ SMK drafter (Bisa menggambar
      3D)<br /><br />Fresh Graduate/ BerSKK/ Berpengalaman<br /><br />Kirimkan:<br />
      <ul>
        <li>Curiculum Vitae</li>
        <li>KTP</li>
        <li>NPWP</li>
        <li>Ijazah</li>
        <li>Transkip Nilai</li>
        <li>Pas Foto</li>
        <li>Dokumen-dokumen pendukung</li>
      </ul>
      <br />Ke email adhikonsultanutama@gmail.com<br /><br />Atau melalui
      website:<br /><a href="https://adhikonsultanutama.com"
        >https://adhikonsultanutama.com</a
      ><br /><br />PT. ADHI KONSULTAN UTAMA<br />WITJITRALAND BLOK E NOMOR 5
      LANGENHARJO KENDAL JATENG<br /><br />WE BUILD BETTER FUTURE
    </div>
    <div>
      <img src="@/assets/user/images/career/banner.jpg" alt="" />
    </div>
  </div>
</template>

<style scoped>
.temp-container {
  display: flex;
  justify-content: space-between;
}

.temp-container div:first-child {
  display: flex;
  flex-direction: column;
  max-width: 42rem;
}

.temp-container div:last-child img {
  max-width: 50rem;
}

@media only screen and (max-width: 1600px) {
  .temp-container {
    flex-direction: column;
    gap: 1rem;
  }

  .temp-container div:last-child {
    display: flex;
    justify-content: center;
  }

  .temp-container div:last-child img {
    width: 100%;
  }
}
</style>
