<template>
  <div class="disallow-mobile" v-if="isMobile">
    <img src="@/assets/admin/images/sorry.svg" alt="">
    <h1>Maaf, panel admin tidak tersedia untuk perangkat seluler.</h1>
  </div>
  <div class="container prevent-select" v-if="!isMobile">
    <div class="left-container">
      <img src="@/assets/admin/images/logo.svg" alt="" draggable="false" />
      <div>
        <h1>SELAMAT DATANG DI</h1>
        <h1>PANEL ADMIN</h1>
      </div>
    </div>
    <div class="right-container">
      <div class="login-form">
        <div>
          <h1>LOGIN</h1>
          <label for=""
            >Username<input
              v-model="loginData.usernameOrEmail"
              type="text"
              class="username-input"
              @input="removeInvalid('username')"
          /></label>
          <h2 v-if="error.username">{{ error.username }}</h2>
        </div>
        <div>
          <label for=""
            >Password<input
              v-model="loginData.password"
              type="password"
              class="password-input"
              @input="removeInvalid('password')"
          /></label>
          <h2 v-if="error.password">{{ error.password }}</h2>
        </div>
        <button @click="login">{{ button.login }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/api/Api";

export default {
  data() {
    return {
      loginData: {
        usernameOrEmail: "",
        password: "",
      },
      error: {
        username: "",
        password: "",
      },
      button: {
        login: "Login",
      },
      loggingIn: false,
      isMobile: null,
    };
  },
  mounted() {
    const loggedIn = localStorage.getItem("token") ? true : false;
    if (loggedIn) {
      // console.log(loggedIn);
      this.$router.push("/account-admin");
    }
    this.isMobileCheck();
    console.log(this.isMobile);
  },
  methods: {
    isMobileCheck() {
      const userAgent = navigator.userAgent.toLowerCase();
      // Common keywords in user agents for mobile devices
      const mobileKeywords = [
        "mobile",
        "android",
        "iphone",
        "ipad",
        "ipod",
        "blackberry",
        "windows phone",
      ];

      this.isMobile = mobileKeywords.some((keyword) =>
        userAgent.includes(keyword)
      );
    },
    login() {
      const button = document.querySelector(".login-form button");
      if (this.loggingIn) return;
      this.loggingIn = true;
      button.classList.add("logging-in");
      this.button.login = "Logging in";
      Api.post("login", this.loginData)
        .then((res) => {
          const token = res.data;
          localStorage.setItem("token", token.token);
          this.$router.push("/account-admin");
        })
        .catch((e) => {
          localStorage.removeItem("token");
          if (!this.loginData.usernameOrEmail) {
            if (
              Object.prototype.hasOwnProperty.call(
                e.response.data.errors,
                "usernameOrEmail"
              )
            )
              this.error.username =
                e.response.data.errors.usernameOrEmail.join(" ");
            document
              .querySelector(".username-input")
              .classList.add("invalid-input");
          }
          if (!this.loginData.password) {
            if (
              Object.prototype.hasOwnProperty.call(
                e.response.data.errors,
                "password"
              )
            )
              this.error.password = e.response.data.errors.password.join(" ");
            document
              .querySelector(".password-input")
              .classList.add("invalid-input");
          }
          if (e.response.data.message == "Bad cred") {
            this.error.password =
              "The username or password you entered is incorrect!";
            document
              .querySelector(".password-input")
              .classList.add("invalid-input");
          }
          // console.log(e);
          this.loggingIn = false;
        })
        .finally(() => {
          button.classList.remove("logging-in");
          this.button.login = "Login";
        });
    },
    removeInvalid(e) {
      document.querySelector(`.${e}-input`).classList.remove("invalid-input");
      if (e == "username") this.error.username = "";
      if (e == "password") this.error.password = "";
    },
  },
};
</script>

<style scoped>
.disallow-mobile {
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.disallow-mobile h1 {
  margin: 0;
  font-size: 1rem;
  text-align: center;
}

.disallow-mobile img {
  width: 20rem;
}

.container {
  position: absolute;
  display: flex;
  background-color: #f8f8ff;
  background-image: url("@/assets/admin/images/login/art-line/red.svg"),
    url("@/assets/admin/images/login/art-line/circle-1.svg");
  background-repeat: no-repeat;
  background-position: top left, bottom right;
  height: 100%;
  width: 100%;
  font-family: "Poppins";
  justify-content: space-between;
}

.left-container {
  display: flex;
  flex-direction: column;
  padding: 4rem;
  gap: 3rem;
}

.left-container img {
  width: 220px;
  pointer-events: none;
}

.left-container h1 {
  margin: 0;
  font-family: "Poppins Bold";
  font-weight: 700;
  font-size: 2.25rem;
  color: #ffffff;
  letter-spacing: 0.05em;
}

.right-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20rem;
  padding-top: 6rem;
}

.right-container .login-form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  width: 30rem;
  padding: 4rem 3rem 3.5rem 3rem;
  gap: 2rem;
}

.login-form h1 {
  text-align: center;
  font-family: "Poppins Bold";
  margin: 0;
  color: #000000;
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.05em;
  padding-bottom: 3rem;
}

.login-form h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  color: red;
}

.login-form label {
  display: flex;
  flex-direction: column;
  color: #000000;
  letter-spacing: 0.05em;
  font-size: 1.5rem;
  gap: 0.25rem;
}

.login-form label:last-of-type {
  /* padding-bottom: 2rem; */
}

.login-form div:last-of-type {
  padding: 0 0 2rem 0;
}

.login-form input {
  height: 4rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

.login-form button {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  background-color: #ff2230;
  border-style: none;
  border-radius: 25px;
  height: 4.5rem;
  -webkit-transition: background-color 0.15s ease-out;
  -moz-transition: background-color 0.15s ease-out;
  -o-transition: background-color 0.15s ease-out;
  transition: background-color 0.15s ease-out;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #f90011;
}

.login-form button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.invalid-input {
  border: red solid 2px !important;
}

.logging-in {
  background-color: #ff737d !important;
  cursor: default !important;
}
</style>
