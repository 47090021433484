<template>
  <div class="project-container">
    <div class="project-container-header">
      <!-- <form class="searchBar" @submit.prevent="searchProject">
        <img
          src="@/assets/admin/icons/SearchIcon.svg"
          alt=""
          draggable="false"
        />
        <input
          type="text"
          name=""
          id=""
          placeholder="Cari akun"
          v-model="searchQuery"
        />
        <input class="hidden-enter" type="submit" value="" />
      </form> -->
      <h1>Project</h1>
      <div class="prevent-select" v-if="!isError && !isLoading">
        <button @click="openAdd">+&nbsp;&nbsp;&nbsp;&nbsp;Tambah data</button>
      </div>
    </div>
    <hr />
    <Empty v-if="isEmpty" :type="'akun'" />
    <Error v-if="isError" />
    <Loading v-if="isLoading" />
    <div v-if="!isEmpty && !isError && !isLoading" class="project-list">
      <table>
        <thead>
          <tr>
            <th>
              <!-- <input
                type="checkbox"
                class="custom-checkbox check-all"
              /> -->
            </th>
            <th>No</th>
            <th>Nama perusahaan</th>
            <th>Kategori perusaahaan</th>
            <th>Status pengerjaan</th>
            <th><!--empty table header for fun --></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in projects" :key="project.id">
            <td>
              <!-- <input
                type="checkbox"
                class="custom-checkbox"
                v-model="project.checked"
              /> -->
            </td>
            <td>{{ project.position }}</td>
            <td>{{ project.title }}</td>
            <td>{{ project.category }}</td>
            <td>{{ project.status }}</td>
            <td class="edit-button prevent-select">
              <img
                src="@/assets/admin/icons/table/hapus.svg"
                alt=""
                draggable="false"
                @click="
                  openModal('delete'),
                    this.$root.emitter.emit('deleteProject', project.id)
                "
              />
              <img
                src="@/assets/admin/icons/table/edit.svg"
                alt=""
                draggable="false"
                @click="editProject(project.id)"
              />
              <div class="move-button">
                <div class="move-up">
                  <img
                    src="@/assets/admin/icons/table/move-button.svg"
                    alt=""
                    draggable="false"
                    @click="
                      moveProject(project.id, parseInt(project.position) - 1)
                    "
                  />
                </div>
                <div class="move-down">
                  <img
                    src="@/assets/admin/icons/table/move-button.svg"
                    alt=""
                    draggable="false"
                    @click="
                      moveProject(project.id, parseInt(project.position) + 1)
                    "
                  />
                </div>
              </div>
              <button @click="openDetail(project.id)">Lihat detail</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="isMoreThanTen">
        <div class="page">
          <img
            class="prev-button"
            src="@/assets/admin/icons/table/previous-page.svg"
            alt=""
            draggable="false"
            @click="previousPage"
          />
          <h1>
            Halaman
            <input
              type="number"
              v-model="currentPage"
              @input="numberOnly"
              @change="goToPage(this.currentPage)"
            />
            dari
            <span>
              {{ page }}
            </span>
          </h1>
          <img
            class="next-button"
            src="@/assets/admin/icons/table/next-page.svg"
            alt=""
            draggable="false"
            @click="nextPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/admin/Empty.vue";
import { Api } from "@/api/Api";
import Loading from "../Loading.vue";
import Error from "../Error.vue";

export default {
  data() {
    return {
      searchQuery: "",
      isEmpty: false,
      isError: null,
      isLoading: null,
      showOptions: null,
      modalOpened: false,
      ModalType: null,
      page: null,
      currentPage: 1,
      projects: [],
      meta: [],
      toMoveId: null,
    };
  },
  components: {
    Empty,
    Loading,
    Error,
  },
  mounted() {
    this.$root.emitter.on("fetchProjects", () => {
      const Token = localStorage.getItem("token");
      Api.get(`admin/project?limit=10`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      }).then((res) => {
        this.meta = res.data.meta;
        if (this.currentPage > this.meta.last_page)
          this.currentPage = this.meta.last_page;
        this.getProjects();
      });
    });
    this.getProjects();
  },
  computed: {
    isMoreThanTen() {
      return this.meta?.total > 10;
    },
  },
  methods: {
    getProjects() {
      if (this.currentPage < 1 || this.currentPage > this.meta.last_page)
        return;
      const Token = localStorage.getItem("token");
      function truncateText(text, maxLength) {
        if (text.length <= maxLength) {
          return text;
        } else {
          return text.slice(0, maxLength) + "...";
        }
      }
      this.isLoading = true;
      Api.get(`admin/project?limit=10&page=${this.currentPage}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          this.meta = res.data.meta;
          this.projects = res.data.data;
          this.page = this.meta.last_page;
          if (!this.projects.length) this.isEmpty = true;
          for (let i = 0; i < this.projects.length; i++) {
            if (this.projects[i].is_finished == "1") {
              this.projects[i].status = "Selesai";
            }
            if (this.projects[i].is_finished == "0") {
              this.projects[i].status = "Belum selesai";
            }
            if (this.projects[i].title) {
              this.projects[i].title = truncateText(this.projects[i].title, 30);
            }
            if (i == this.projects.length) {
              this.pointerStyle();
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    moveProject(id, to) {
      const Token = localStorage.getItem("token");
      if (to == 0 || to == this.meta.total + 1) return;
      Api({
        method: "put",
        url: `admin/project/positioning`,
        params: {
          current: id,
          to: to,
        },
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then(() => {
          this.getProjects();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    pointerStyle() {
      const nextBut = document.querySelector(".next-button");
      const prevBut = document.querySelector(".prev-button");

      nextBut.classList.remove("pointer-default");
      prevBut.classList.remove("pointer-default");

      if (this.currentPage == this.meta.last_page)
        nextBut.classList.add("pointer-default");
      if (this.currentPage == this.meta.from)
        prevBut.classList.add("pointer-default");
    },
    numberOnly() {
      const value = event.target.value;

      const digitsOnly = value.replace(/\D/g, "");

      event.target.value = digitsOnly;
    },
    previousPage() {
      if (this.currentPage < 1 || this.currentPage > this.meta.last_page)
        return;
      if (this.currentPage == this.meta.from) return;
      this.currentPage--;
      this.getProjects();
    },
    nextPage() {
      if (this.currentPage < 1 || this.currentPage > this.meta.last_page)
        return;
      if (this.currentPage == this.meta.last_page) return;
      this.currentPage++;
      this.getProjects();
    },
    goToPage(e) {
      if (e < 1 || e > this.meta.last_page) return;
      this.getProjects();
    },
    openDetail(e) {
      this.$root.emitter.emit("openDetailProject", e);
    },
    openAdd() {
      this.$root.emitter.emit("openAddProject");
    },
    openModal(e) {
      this.$root.emitter.emit("openModal", e);
    },
    editProject(e) {
      this.$root.emitter.emit("openEditProject", e);
      setTimeout(() => {
        this.$root.emitter.emit("setDirectTo", "list");
      }, 1);
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.project-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.project-container hr {
  border: solid #e1e1e1;
  margin: 0;
  border-width: 1px;
}

.project-container-header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.project-container-header h1 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 550;
  margin: 0;
}

.project-container-header button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.project-container-header button:hover {
  background-color: #f90011;
}

.project-container-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.hidden-enter {
  display: none;
}

.searchBar {
  display: flex;
  align-items: stretch;
  padding: 0.5rem 1.25rem;
  width: 15%;
  border-radius: 5px;
  align-self: center;
  background: #f0f0f0;
  box-shadow: 0px 28px 53px rgba(122, 125, 126, 0.07);
  gap: 0.3rem;
}

.searchBar > img {
  aspect-ratio: 1/1;
}

.searchBar > input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  background: #f0f0f0;
}

.searchBar input::placeholder {
  color: #3a3a3a;
  font-size: 0.875rem;
  font-weight: 500;
}

.project-list table {
  width: 100%;
  border-collapse: collapse;
}

.project-list table thead th,
.project-list table tbody th {
  color: #393d43;
  font-size: 1rem;
}

.project-list table thead th:nth-of-type(1) {
  width: 2%;
}

.project-list table thead th:nth-of-type(2) {
  width: 4%;
  text-align: left;
}
.project-list table thead th:nth-of-type(3),
.project-list table thead th:nth-of-type(4),
.project-list table thead th:nth-of-type(5) {
  width: 25%;
  text-align: left;
}
.project-list table thead * {
  padding: 1rem 0;
}

.project-list table tbody tr td {
  padding: 0.75rem 0;
}

.edit-button {
  display: flex;
  flex-direction: row-reverse;
  /* align-items: center; */
  gap: 0.25rem;
}

.move-button {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  gap: 0;
}

.move-up {
  height: 16px;
  overflow: hidden;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.move-down {
  height: 16px;
  overflow: hidden;
  transform: rotate(180deg);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.move-button img {
  vertical-align: middle;
  padding: 0;
}

.edit-button button {
  width: 7rem;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  background-color: #ff2230;
  border-radius: 5px;
  cursor: pointer;
}

.edit-button button:hover {
  background-color: #f90011;
}

.edit-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.project-list table tbody tr td img {
  cursor: pointer;
}

.project-list table tbody td:nth-of-type(1) {
  text-align: center;
}

.project-list table tbody tr {
  border-bottom: 0.5px solid rgba(225, 225, 225, 0.8);
}

.project-list table thead tr {
  border-bottom: 1px solid rgba(225, 225, 225, 0.8);
}

.project-list table thead tr input,
.project-list table tbody tr input {
  margin: 0;
  vertical-align: middle;
}

.project-list table tbody td:nth-of-type(6) {
  text-align: right;
  padding-right: 2rem;
}

.pagination {
  display: flex;
  flex-direction: row-reverse;
}

.page {
  display: flex;
  gap: 0.5rem;
  padding: 1rem 2rem 1rem 0;
  align-items: center;
}

.page img {
  cursor: pointer;
  width: 31px;
  height: 31px;
  border-radius: 50%;
}

.page h1 {
  color: #3a3a3a;
  font-size: 1rem;
  font-weight: 500;
  word-spacing: 0.25rem;
}

.page h1 span {
  color: #1f1f1f;
  font-weight: 400;
}

.page input {
  width: 1.6rem;
  height: 1.8rem;
  text-align: center;
  color: #ff2230;
  border: #ff2230 solid 2px;
  font-size: 1rem;
}

.page input[type="number"]:focus {
  outline: none;
}

.custom-checkbox {
  position: relative;
  cursor: pointer;
}

.custom-checkbox:before,
.custom-checkbox:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -6px;
  left: 0;
  transition: background-color 0.1s ease-out, border-color 0.1s ease-out;
  background-color: #ffffff;
  border: #3a3a3a 1px solid;
}

.custom-checkbox:checked:before {
  background-color: #ff2230;
  border-color: #ff2230;
}

.custom-checkbox:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: -3px;
  left: 7px;
}

.pointer-default {
  cursor: default !important;
}
</style>
