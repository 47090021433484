const authAdmin = function (to, from, next) {
  const loggedIn = localStorage.getItem("token") ? true : false;
  if (!loggedIn) {
    next("/login");
  } else {
    next()
  }
};

export default authAdmin;
