<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

.required {
  color: red;
}

.prevent-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.hide {
  display: none !important;
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins Bold";
  src: url(./assets/fonts/Poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Dancing Script";
  src: url(./assets/fonts/DancingScript/DancingScript-Regular.ttf);
}

@font-face {
  font-family: "Roboto";
  src: url(./assets/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: "Roboto Bold";
  src: url(./assets/fonts/Roboto/Roboto-Bold.ttf);
}
</style>
