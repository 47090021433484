<template>
  <div class="addModal">
    <div class="modal-shadow" @click="closeModal"></div>
    <div class="form" v-show="modalType == 'add'">
      <h1>Tambah akun admin</h1>
      <div class="formInputs">
        <label class="username-title" for=""
          >{{ inputTitle.username
          }}<input
            type="text"
            placeholder="Masukkan username"
            v-model="user.username"
            class="username-input"
            @input="removeInvalid('username')"
          />
        </label>
        <label class="password-title" for=""
          >{{ inputTitle.password
          }}<input
            type="password"
            placeholder="************"
            v-model="user.password"
            class="password-input"
            @input="removeInvalid('password')"
        /></label>
        <label class="passwordcon-title" for=""
          >{{ inputTitle.passwordConfirmation
          }}<input
            type="password"
            placeholder="************"
            v-model="user.passwordConfirmation"
            class="passwordcon-input"
            @input="removeInvalid('passwordcon')"
        /></label>
        <label class="email-title" for=""
          >{{ inputTitle.email
          }}<input
            type="email"
            placeholder="Masukkan email"
            v-model="user.email"
            class="email-input"
            @input="removeInvalid('email')"
        /></label>
      </div>
      <div>
        <button @click="addUser">{{ addAccount.button }}</button>
      </div>
    </div>
    <div class="form form-edit" v-show="modalType == 'edit'">
      <Loading v-if="showLoading" />
      <h1 v-if="!showLoading">Edit akun admin</h1>
      <div v-if="!showLoading" class="formInputs">
        <label class="username-title" for=""
          >{{ inputTitle.username
          }}<input
            type="text"
            placeholder="Masukkan username"
            class="username-input"
            v-model="selectedUserToEdit.username"
            @input="removeInvalid('username')"
        /></label>
        <label class="password-title" for=""
          >{{ inputTitle.password
          }}<input
            type="password"
            placeholder="************"
            class="password-input"
            v-model="editAccount.password"
            @input="removeInvalid('password')"
        /></label>
        <label class="passwordcon-title" for=""
          >{{ inputTitle.passwordConfirmation
          }}<input
            type="password"
            placeholder="************"
            class="passwordcon-input"
            v-model="editAccount.password_confirmation"
            @input="removeInvalid('passwordcon')"
        /></label>
        <label class="email-title" for=""
          >{{ inputTitle.email
          }}<input
            type="email"
            placeholder="Masukkan email"
            class="email-input"
            v-model="selectedUserToEdit.email"
            @input="removeInvalid('email')"
        /></label>
      </div>
      <div v-if="!showLoading">
        <button @click="editUser">Simpan perubahan</button>
      </div>
    </div>
    <div class="form form-delete" v-show="modalType == 'delete'">
      <div>
        <img src="@/assets/admin/images/delete.png" alt="" />
        <h1>Apokah Anda ingin menghapus item terpilih?</h1>
      </div>
      <div>
        <button @click="deleteUser" class="delete-button">
          {{ deleteAccount.button }}
        </button>
        <button @click="closeModal">Batal</button>
      </div>
    </div>
    <div class="form form-delete-bulk" v-show="modalType == 'deleteBulk'">
      <div>
        <img src="@/assets/admin/images/delete.png" alt="" />
        <h1>Apokah Anda ingin menghapus item terpilih?</h1>
      </div>
      <div>
        <button @click="deleteBulk" class="delete-button">
          {{ deleteAccount.button }}
        </button>
        <button @click="closeModal">Batal</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/api/Api";
import Loading from "../Loading.vue";

export default {
  data() {
    return {
      users: [],
      selectedUser: {},
      selectedUserToEdit: {},
      selectedId: null,
      user: {
        username: "",
        email: "",
        password: "",
        passwordConfirmation: "",
      },
      inputTitle: {
        username: "Username",
        password: "Password",
        passwordConfirmation: "Password Confirmation",
        email: "Email",
      },
      addAccount: {
        button: "Tambah akun",
      },
      deleteAccount: {
        button: "Iya",
      },
      editAccount: {
        password: "",
        password_confirmation: "",
      },
      isClicking: false,
      showLoading: false,
    };
  },
  props: {
    modalType: null,
  },
  components: {
    Loading,
  },
  methods: {
    closeModal() {
      this.$root.emitter.emit("closeModal");
      this.user.username =
        this.user.email =
        this.user.password =
        this.user.passwordConfirmation =
          "";
      document
        .querySelector(`.form-edit .username-title`)
        .classList.remove("invalid-input-input");
      document
        .querySelector(`.form-edit .username-input`)
        .classList.remove("invalid-input");
      document
        .querySelector(`.form-edit .password-title`)
        .classList.remove("invalid-input-input");
      document
        .querySelector(`.form-edit .password-input`)
        .classList.remove("invalid-input");
      document
        .querySelector(`.form-edit .passwordcon-title`)
        .classList.remove("invalid-input-input");
      document
        .querySelector(`.form-edit .passwordcon-input`)
        .classList.remove("invalid-input");
      document
        .querySelector(`.form-edit .email-title`)
        .classList.remove("invalid-input-input");
      document
        .querySelector(`.form-edit .email-input`)
        .classList.remove("invalid-input");
      this.inputTitle.username = "Username";
      this.inputTitle.password = "Password";
      this.inputTitle.passwordConfirmation = "Password Confirmation";
      this.inputTitle.email = "Email";
    },
    getUsers() {
      const Token = localStorage.getItem("token");
      Api.get("admin/account", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          this.users = res.data.data;
          // console.log(this.users);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getUser(a) {
      const Token = localStorage.getItem("token");
      this.showLoading = true;
      Api.get(`admin/account/${a}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          this.selectedUser = res.data.data;
          this.selectedUserToEdit = {
            username: `${res.data.data.username}`,
            email: `${res.data.data.email}`,
          };
          // console.log(this.selectedUserToEdit);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    addUser() {
      if (this.isClicking) return;
      this.isClicking = true;
      const Token = localStorage.getItem("token");
      document.querySelector(".form-active button").classList.add("saving");
      this.addAccount.button = "Menyimpan";
      Api.post(
        `admin/account`,
        {
          username: this.user.username,
          email: this.user.email,
          password: this.user.password,
          password_confirmation: this.user.passwordConfirmation,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        }
      )
        .then(() => {
          this.$root.emitter.emit("fetchUser");
          this.$root.emitter.emit("closeModal");
          this.user.username =
            this.user.email =
            this.user.password =
            this.user.passwordConfirmation =
              "";
        })
        .catch((e) => {
          console.log(e);
          if (this.user.username) {
            if (
              Object.prototype.hasOwnProperty.call(
                e.response.data.errors,
                "username"
              )
            ) {
              this.inputTitle.username = `Username - ${e.response.data.errors.username.join(
                " "
              )}`;
              document
                .querySelector(".username-title")
                .classList.add("invalid-input-input");
              document
                .querySelector(".username-input")
                .classList.add("invalid-input");
            }
          } else {
            this.inputTitle.username = `Username - The username field is required.`;
            document
              .querySelector(".username-title")
              .classList.add("invalid-input-input");
            document
              .querySelector(".username-input")
              .classList.add("invalid-input");
          }
          if (this.user.email) {
            if (
              Object.prototype.hasOwnProperty.call(
                e.response.data.errors,
                "email"
              )
            ) {
              this.inputTitle.email = `Email - ${e.response.data.errors.email.join(
                " "
              )}`;
              document
                .querySelector(".email-title")
                .classList.add("invalid-input-input");
              document
                .querySelector(".email-input")
                .classList.add("invalid-input");
            }
          } else {
            this.inputTitle.email = `Email - The email field is required.`;
            document
              .querySelector(".email-title")
              .classList.add("invalid-input-input");
            document
              .querySelector(".email-input")
              .classList.add("invalid-input");
          }
          if (this.user.password) {
            if (
              Object.prototype.hasOwnProperty.call(
                e.response.data.errors,
                "password"
              )
            ) {
              this.inputTitle.password = `Password - ${e.response.data.errors.password.join(
                " "
              )}`;
              document
                .querySelector(".password-title")
                .classList.add("invalid-input-input");
              document
                .querySelector(".password-input")
                .classList.add("invalid-input");
              document
                .querySelector(".passwordcon-title")
                .classList.add("invalid-input-input");
              document
                .querySelector(".passwordcon-input")
                .classList.add("invalid-input");
            }
          } else {
            this.inputTitle.password = `Password - The password field is required.`;
            document
              .querySelector(".password-title")
              .classList.add("invalid-input-input");
            document
              .querySelector(".password-input")
              .classList.add("invalid-input");
          }
          if (!this.user.passwordConfirmation) {
            this.inputTitle.passwordConfirmation = `Password Confirmation - The password confirmation field is required.`;
            document
              .querySelector(".passwordcon-title")
              .classList.add("invalid-input-input");
            document
              .querySelector(".passwordcon-input")
              .classList.add("invalid-input");
          }
        })
        .finally(() => {
          document.querySelector(".form button").classList.remove("saving");
          this.addAccount.button = "Tambah akun";
          this.isClicking = false;
        });
    },
    editUser() {
      if (this.isClicking) return;
      let editToSend = {
        ...this.selectedUserToEdit,
      };
      const Token = localStorage.getItem("token");
      if (this.editAccount.password || this.editAccount.password_confirmation) {
        editToSend = {
          ...this.selectedUserToEdit,
          ...this.editAccount,
        };
      }
      if (this.selectedUserToEdit.username == this.selectedUser.username) {
        delete editToSend.username;
      }
      if (this.selectedUserToEdit.email == this.selectedUser.email) {
        delete editToSend.email;
      }
      Api.put(`admin/account/${this.selectedUser.id}`, editToSend, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then(() => {
          this.$root.emitter.emit("fetchUser");
          this.$root.emitter.emit("closeModal");
          this.editToSend = {};
          this.editAccount.password = "";
          this.editAccount.password_confirmation = "";
        })
        .catch((e) => {
          console.log(e);
          if (
            editToSend.password &&
            editToSend.password_confirmation &&
            e.response.data.error == "password must not be the same"
          ) {
            this.inputTitle.password = `Password - Password must not be the same as the previous password.`;
            document
              .querySelector(".form-edit .password-title")
              .classList.add("invalid-input-input");
            document
              .querySelector(".form-edit .password-input")
              .classList.add("invalid-input");
            document
              .querySelector(".form-edit .passwordcon-title")
              .classList.add("invalid-input-input");
            document
              .querySelector(".form-edit .passwordcon-input")
              .classList.add("invalid-input");
          }
          if (editToSend.username) {
            if (
              Object.prototype.hasOwnProperty.call(
                e.response.data.errors,
                "username"
              )
            ) {
              this.inputTitle.username = `Username - ${e.response.data.errors.username.join(
                " "
              )}`;
              document
                .querySelector(".form-edit .username-title")
                .classList.add("invalid-input-input");
              document
                .querySelector(".form-edit .username-input")
                .classList.add("invalid-input");
            }
          }
          if (editToSend.email) {
            if (
              Object.prototype.hasOwnProperty.call(
                e.response.data.errors,
                "email"
              )
            ) {
              this.inputTitle.email = `Email - ${e.response.data.errors.email.join(
                " "
              )}`;
              document
                .querySelector(".form-edit .email-title")
                .classList.add("invalid-input-input");
              document
                .querySelector(".form-edit .email-input")
                .classList.add("invalid-input");
            }
          }

          if (editToSend.password) {
            if (
              Object.prototype.hasOwnProperty.call(
                e.response.data.errors,
                "password"
              )
            ) {
              this.inputTitle.password = `Password - ${e.response.data.errors.password.join(
                " "
              )}`;
              document
                .querySelector(".form-edit .password-title")
                .classList.add("invalid-input-input");
              document
                .querySelector(".form-edit .password-input")
                .classList.add("invalid-input");
              document
                .querySelector(".form-edit .passwordcon-title")
                .classList.add("invalid-input-input");
              document
                .querySelector(".form-edit .passwordcon-input")
                .classList.add("invalid-input");
            }

            if (!editToSend.password_confirmation && editToSend.password) {
              this.inputTitle.passwordConfirmation = `Password Confirmation - The password confirmation field is required.`;
              document
                .querySelector(".form-edit .passwordcon-title")
                .classList.add("invalid-input-input");
              document
                .querySelector(".form-edit .passwordcon-input")
                .classList.add("invalid-input");
            }
          }
        });
    },
    deleteUser() {
      const Token = localStorage.getItem("token");
      document.querySelector(".delete-button").classList.add("saving");
      this.deleteAccount.button = "Menghapus akun";
      Api.delete(`admin/account/${this.selectedId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then(() => {
          this.$root.emitter.emit("fetchUser");
          this.selectedId = null;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          document.querySelector(".delete-button").classList.remove("saving");
          this.deleteAccount.button = "Iya";
          this.$root.emitter.emit("closeModal");
          this.selectedId = null;
        });
    },
    deleteBulk() {
      document
        .querySelector(".form-delete-bulk .delete-button")
        .classList.add("saving");
      this.deleteAccount.button = "Menghapus akun";
      this.$root.emitter.emit("bulkDeleteUser");
    },
    resetDeleteButton() {
      document
        .querySelector(".form-delete-bulk .delete-button")
        .classList.remove("saving");
      this.deleteAccount.button = "Iya";
      this.$root.emitter.emit("closeModal");
      this.selectedId = null;
    },
    removeInvalid(e) {
      document
        .querySelector(`.${e}-title`)
        .classList.remove("invalid-input-input");
      document.querySelector(`.${e}-input`).classList.remove("invalid-input");
      document
        .querySelector(`.form-edit .${e}-title`)
        .classList.remove("invalid-input-input");
      document
        .querySelector(`.form-edit .${e}-input`)
        .classList.remove("invalid-input");
      if (e == "username") this.inputTitle.username = "Username";
      if (e == "password") this.inputTitle.password = "Password";
      if (e == "passwordcon")
        this.inputTitle.passwordConfirmation = "Password Confirmation";
      if (e == "email") this.inputTitle.email = "Email";
    },
  },
  created() {
    // this.getUsers();
  },
  mounted() {
    this.$root.emitter.on("editUser", (a) => {
      this.getUser(a);
    });
    this.$root.emitter.on("deleteUser", (a) => {
      this.selectedId = a;
    });
    this.$root.emitter.on("resetDeleteButtonUser", () => {
      this.resetDeleteButton();
    });
  },
};
</script>

<style scoped>
.addModal {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: opacity 0.2s;
  opacity: 0;
}

.modal-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.addModal-active {
  opacity: 1;
}

.form {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 5rem;
  width: 5rem;
  background: white;
  border-radius: 50%;
  transition: all 500ms cubic-bezier(0.93, 0.1, 0.42, 0.91);
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  font-family: "Poppins";
  overflow: hidden;
  justify-content: space-between;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.form h1 {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  color: #000000;
}

.formInputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.form label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1rem;
  color: #3a3a3a;
  /* color: red; */
}

.form input {
  height: 2rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
}

.form input::placeholder {
  color: #3a3a3a;
  font-weight: 250;
}

.form button {
  height: 3rem;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  background: #ff2230;
  border-radius: 5px;
  border-style: none;
  padding: 0 1rem;
  cursor: pointer;
}

.form button:hover {
  background-color: #f90011;
}

.form button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.form div:last-of-type {
  text-align: right;
}

.form-active {
  height: 27rem;
  /* height: auto; */
  width: 40%;
  border-radius: 10px;
}

.form-delete div:first-of-type {
  padding: 4rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.form-delete div:last-of-type {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
}

.form-delete div:last-of-type button:last-of-type {
  background: #ffffff;
  border: #ff2230 solid 1px;
  color: #ff2230;
}

.form-delete div:last-of-type button:last-of-type:hover {
  background: #f0f0f0;
}

.form-delete div:last-of-type button:last-of-type:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}

.form-delete img {
  width: 244.36px;
  height: 180px;
}

.form-delete h1 {
  font-family: "Poppins Bold";
  font-size: 1.5625rem;
  max-width: 549px;
  text-align: center;
}

.form-delete-bulk div:first-of-type {
  padding: 4rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.form-delete-bulk div:last-of-type {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
}

.form-delete-bulk div:last-of-type button:last-of-type {
  background: #ffffff;
  border: #ff2230 solid 1px;
  color: #ff2230;
}

.form-delete-bulk div:last-of-type button:last-of-type:hover {
  background: #f0f0f0;
}

.form-delete-bulk div:last-of-type button:last-of-type:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}

.form-delete-bulk img {
  width: 244.36px;
  height: 180px;
}

.form-delete-bulk h1 {
  font-family: "Poppins Bold";
  font-size: 1.5625rem;
  max-width: 549px;
  text-align: center;
}

.invalid-input {
  border: red solid 2px !important;
}

.invalid-input-input {
  color: red !important;
}

.saving {
  background-color: #ff737d !important;
  cursor: default !important;
}
</style>
