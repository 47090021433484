<template>
  <ProjectModal v-show="modalOpened" :modalType="selectedModal" />
  <div class="container">
    <Sidebar />
    <div class="content-container">
      <ProjectTable v-if="ActiveComponent == 'projectTable'" />
      <AddProject v-if="ActiveComponent == 'addProject'" />
      <EditProject v-if="ActiveComponent == 'editProject'" />
      <DetailProject v-if="ActiveComponent == 'detailProject'" />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar.vue";
import ProjectTable from "@/components/admin/project/ProjectTable.vue";
import AddProject from "@/components/admin/project/AddProject.vue";
import EditProject from "@/components/admin/project/EditProject.vue";
import DetailProject from "@/components/admin/project/DetailProject.vue";
import { Api } from "@/api/Api";
import ProjectModal from "@/components/admin/project/ProjectModal.vue";

export default {
  data() {
    return {
      ActiveComponent: "projectTable",
      modalOpened: null,
      selectedModal: null,
    };
  },
  components: {
    Sidebar,
    ProjectTable,
    AddProject,
    EditProject,
    DetailProject,
    ProjectModal,
  },
  mounted() {
    const Token = localStorage.getItem("token");
    this.$root.emitter.on("openModal", (a) => {
      if (this.modalOpened) return;
      this.modalOpened = true;
      const addFormAnimationIn = document.querySelectorAll(".form");
      const AddModal = document.querySelector(".addModal");
      this.selectedModal = a;
      var index = 0,
        length = addFormAnimationIn.length;
      setTimeout(() => {
        for (; index < length; index++) {
          addFormAnimationIn[index].classList.add("form-active");
        }
        AddModal.classList.add("addModal-active");
      }, 100);
    });
    this.$root.emitter.on("closeModal", () => {
      if (!this.modalOpened) return;
      const addFormAnimationOut = document.querySelectorAll(".form");
      const AddModal = document.querySelector(".addModal");
      var index = 0,
        length = addFormAnimationOut.length;
      for (; index < length; index++) {
        addFormAnimationOut[index].classList.remove("form-active");
      }
      AddModal.classList.remove("addModal-active");
      setTimeout(() => {
        this.selectedModal = null;
        this.modalOpened = false;
      }, 100);
    });
    this.$root.emitter.on("openDetailProject", (id) => {
      this.ActiveComponent = "detailProject";
      setTimeout(() => {
        this.$root.emitter.emit("fetchDetailProject", id);
      }, 1);
    });
    this.$root.emitter.on("openEditProject", (id) => {
      this.ActiveComponent = "editProject";
      setTimeout(() => {
        this.$root.emitter.emit("fetchDetailProject", id);
      }, 1);
    });
    this.$root.emitter.on("backToProject", () => {
      this.ActiveComponent = "projectTable";
    });
    this.$root.emitter.on("openAddProject", () => {
      this.ActiveComponent = "addProject";
    });
    Api.get("is-authenticated", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    }).catch((e) => {
      if (e.response.data.message == "Unauthenticated.") {
        localStorage.clear();
      }
    });
  },
};
</script>

<style scoped>
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f8f8ff;
  font-family: "Poppins";
}

.content-container {
  padding: 4rem 2rem 4rem;
  flex: 1;
  overflow: auto;
}

.sidebar {
  height: 100vh !important;
  position: sticky;
  top: 0;
}
</style>
