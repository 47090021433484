<template>
  <div class="testimony-container">
    <div class="testimony-container-header">
      <div class="header-title">
        <h1>Detail Testimoni</h1>
      </div>
      <div class="prevent-select" v-if="!isLoading && !isError">
        <button @click="editTestimony(this.testimony.id)">Edit testimoni</button>
      </div>
    </div>
    <hr />
    <Loading v-if="isLoading" />
    <Error v-if="!isLoading && isError" />
    <div v-if="!isLoading && !isError" class="detail-container">
      <div class="image-container">
        <div class="cover-container">
          <img
            v-if="testimony.pp"
            :src="testimony.pp"
            alt=""
            draggable="false"
          />
          <h2>{{ testimony.name }}</h2>
          <h1>{{ testimony.as }}</h1>
        </div>
      </div>
      <div class="text-container">
        <div>
          <h1>Komentar</h1>
          <p>{{ testimony.comment }}</p>
        </div>
      </div>
    </div>
    <div class="buttons" v-if="!isLoading">
      <div class="back-button">
        <button @click="backToList">Kembali</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api";
import Loading from "../Loading.vue";
import Error from "../Error.vue";

export default {
  data() {
    return {
      isLoading: null,
      isError: null,
      article: {
        title: "Penemu Air Mineral",
        uploadDate: "1 Mei 2023",
        writer: "Yohanes Succubus",
        description:
          "Water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu water wo'oh air banyu mizu ",
        cover: "/admin/assets/images/testimony/testimony.png",
      },
      testimony: [],
    };
  },
  components: {
    Loading,
    Error,
  },
  mounted() {
    this.$root.emitter.on("fetchDetailTestimony", (id) => {
      this.getTestimony(id);
    });
  },
  methods: {
    getTestimony(id) {
      const Token = localStorage.getItem("token");
      this.isLoading = true;
      Api.get(`admin/testimoni/${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          this.testimony = res.data.data;
          // console.log(this.testimony);
          if (this.testimony.pp) {
            this.testimony.pp = `${baseURL}images/${this.testimony.pp}`;
          }
          // console.log(this.users);
        })
        .catch((e) => {
          console.log(e);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    editTestimony(e) {
      this.$root.emitter.emit("openEditTestimony", e);
      setTimeout(() => {
        this.$root.emitter.emit("setDirectTo", "detail");
      }, 1);
    },
    backToList() {
      this.$root.emitter.emit("backToTestimony");
    },
  },
};
</script>

<style scoped>
img {
  pointer-events: none;
}

.testimony-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.testimony-container hr {
  border: solid #e1e1e1;
  margin: 0;
  border-width: 1px;
}

.testimony-container-header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.header-title {
  display: flex;
  align-items: center;
}

.testimony-container h1 {
  font-size: 1.375rem;
  font-weight: 400;
  margin: 0;
  color: #3a3a3a;
}

.testimony-container-header button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.testimony-container-header button:hover {
  background-color: #f90011;
}

.testimony-container-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.detail-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1.5rem 2rem 6rem 2rem;
  gap: 1.5rem;
}

.detail-container h1 {
  color: #989898;
  font-size: 1.25rem;
  font-weight: 500;
}

.image-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.image-container img {
  width: 240px;
  height: 240px;
}

.image-details {
  display: flex;
  gap: 1.5rem;
}

.image-details img {
  width: 120px;
  height: 120px;
}

.cover-container,
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.cover-container h2 {
  margin: 0;
}

.snapshot-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;
  width: 488px;
}

.snapshot-container div {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 488px;
}

.snapshot-container img {
  width: 240px;
  height: 120px;
}

.text-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.text-container div {
  display: flex;
  flex-direction: column;
  /* max-width: 646px; */
}

.text-container div p {
  font-size: 1.625rem;
  font-weight: 500;
  color: #3a3a3a;
  margin: 0;
}
.buttons {
  padding: 0 2rem 1rem 2rem;
}
.back-button button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.back-button button:hover {
  background-color: #f90011;
}

.back-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
</style>
