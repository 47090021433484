<template>
  <div class="inner-sections">
    <div class="inner-sections-header prevent-select">
      <ManagementProfileMain v-if="activeComponent == 'main'" />
      <AddCommissioner v-if="activeComponent == 'addCommissioner'" />
      <EditCommissioner v-if="activeComponent == 'editCommissioner'" />
      <AddDirector v-if="activeComponent == 'addDirector'" />
      <EditDirector v-if="activeComponent == 'editDirector'" />
      <AddOurTeam v-if="activeComponent == 'addOurTeam'" />
      <EditOurTeam v-if="activeComponent == 'editOurTeam'" />
      <AddGallery v-if="activeComponent == 'addGallery'" />
      <EditGallery v-if="activeComponent == 'editGallery'" />
    </div>
  </div>
</template>

<script>
import AddCommissioner from "./management-profile/AddCommissioner.vue";
import AddDirector from "./management-profile/AddDirector.vue";
import AddGallery from "./management-profile/AddGallery.vue";
import AddOurTeam from "./management-profile/AddOurTeam.vue";
import EditCommissioner from "./management-profile/EditCommissioner.vue";
import EditDirector from "./management-profile/EditDirector.vue";
import EditGallery from './management-profile/EditGallery.vue';
import EditOurTeam from "./management-profile/EditOurTeam.vue";
import ManagementProfileMain from "./management-profile/ManagementProfileMain.vue";

export default {
  data() {
    return {
      activeComponent: "main",
    };
  },
  components: {
    ManagementProfileMain,
    AddCommissioner,
    AddDirector,
    AddOurTeam,
    AddGallery,
    EditCommissioner,
    EditDirector,
    EditOurTeam,
    EditGallery,
  },
  mounted() {
    this.$root.emitter.on("openManagementMain", () => {
      this.activeComponent = "main";
    });
    this.$root.emitter.on("openEdit", (e) => {
      this.activeComponent = e;
    });
    this.$root.emitter.on("openAdd", (e) => {
      this.activeComponent = e;
    });
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6,
hr {
  margin: 0;
}

.inner-sections hr {
  color: #e1e1e1;
  border-style: solid;
}

.inner-sections-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.75rem 3rem 1rem 3rem;
}

.inner-sections-header h1 {
  color: #3a3a3a;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0 0 0 1rem;
  /* cursor: pointer; */
}
</style>
