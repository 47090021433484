<template>
  <ManageAccountModal v-show="modalOpened" :modalType="selectedModal" />
  <div class="container">
    <Sidebar />
    <div class="content-container">
      <AccountTable />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar.vue";
import ManageAccountModal from "@/components/admin/account/ManageAccountModal.vue";
import AccountTable from "@/components/admin/account/AccountTable.vue";
import { Api } from '@/api/Api';

export default {
  components: {
    Sidebar,
    ManageAccountModal,
    AccountTable,
  },
  mounted() {
    const Token = localStorage.getItem("token");
    this.$root.emitter.on("closeModal", () => {
      if (!this.modalOpened) return;
      const addFormAnimationOut = document.querySelectorAll(".form");
      const AddModal = document.querySelector(".addModal");
      var index = 0,
        length = addFormAnimationOut.length;
      for (; index < length; index++) {
        addFormAnimationOut[index].classList.remove("form-active");
      }
      AddModal.classList.remove("addModal-active");
      setTimeout(() => {
        this.selectedModal = null;
        this.modalOpened = false;
      }, 100);
    });
    this.$root.emitter.on("openModal", (a) => {
      if (this.modalOpened) return;
      this.modalOpened = true;
      const addFormAnimationIn = document.querySelectorAll(".form");
      const AddModal = document.querySelector(".addModal");
      this.selectedModal = a;
      var index = 0,
        length = addFormAnimationIn.length;
      setTimeout(() => {
        for (; index < length; index++) {
          addFormAnimationIn[index].classList.add("form-active");
        }
        AddModal.classList.add("addModal-active");
      }, 100);
    });
    Api.get("is-authenticated", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    }).catch((e) => {
      if (e.response.data.message == "Unauthenticated.") {
        localStorage.clear();
      }
    });
  },
  methods: {
    
  },
  data() {
    return {
      modalOpened: false,
      selectedModal: null,
    };
  },
};
</script>

<style scoped>
.container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #f8f8ff;
  font-family: "Poppins";
}

.content-container {
  padding: 4rem 2rem 0;
  flex: 1;
  overflow-x: auto;
}
</style>
