<template>
  <div class="article-paragraph">
    <h1>Artikel Terbaru</h1>
    <h2>
      Artikel terbaru dari PT Adhi Konsultan Utama seputar kontruksi,
      arsitektural, civil engineering, dan lain-lain
    </h2>
    <hr />
  </div>
  <Loading v-if="isLoading || isImageLoaded" />
  <div
    v-if="!isArticleEmpty && !isLoading && !isImageLoaded"
    class="article-card-container prevent-select"
    ref="container"
  >
    <div
      class="article-card-item"
      v-for="(article, index) in articles"
      :key="index"
    >
      <img
        class="article-cover"
        :src="displayedCovers[index]"
        alt=""
        draggable="false"
      />
      <div class="article-card-details">
        <h1>{{ article.title.rendered }}</h1>
        <div class="article-card-user-timestamp">
          <div>
            <img :src="displayedAuthors[index]" alt="" draggable="false" />
            <h2>{{ articleAuthor[index] }}</h2>
          </div>
          <div>
            <img
              src="@/assets/user/icons/home/article/clock.svg"
              alt=""
              draggable="false"
            />
            <h2>{{ articleTime[index] }}</h2>
          </div>
        </div>
        <h3>{{ formattedText[index] }}</h3>
        <div class="article-more">
          <a :href="article.link">
            <h4>Selengkapnya</h4>
            <img
              src="@/assets/user/icons/home/article/arrow.svg"
              alt=""
              draggable="false"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="isArticleEmpty && !isLoading"
    class="article-empty"
    ref="container"
  >
    <h1>Tidak ada artikel</h1>
  </div>
  <div class="more-article">
    <p>
      <a href="https://blog.adhikonsultanutama.com/"> • Artikel Lainnya • </a>
    </p>
  </div>
</template>

<script>
import { blogApi } from "@/api/Api";
import axios from "axios";
import Loading from "@/components/admin/Loading.vue";

export default {
  mounted() {
    this.fetchArticle();
  },
  computed: {
    isArticleEmpty() {
      return this.articles?.length === 0;
    },
    isImageLoaded() {
      return (
        this.articleCovers?.length === 0 &&
        this.articleAuthor?.length === 0 &&
        this.authorPicture?.length === 0
      );
    },
  },
  components: {
    Loading,
  },
  methods: {
    fetchArticle() {
      this.isLoading = true;
      blogApi
        .get("posts")
        .then((res) => {
          function removeTimeFromISODate(isoDateTime) {
            const datePart = isoDateTime.split("T")[0];
            return datePart;
          }
          function convertToIndonesianDate(isoDate) {
            const months = [
              "Januari",
              "Februari",
              "Maret",
              "April",
              "Mei",
              "Juni",
              "Juli",
              "Agustus",
              "September",
              "Oktober",
              "November",
              "Desember",
            ];

            const [year, month, day] = isoDate.split("-");

            const monthName = months[parseInt(month, 10) - 1];
            const indonesianDate = `${parseInt(day, 10)} ${monthName} ${year}`;

            return indonesianDate;
          }
          function removeElementTag(text) {
            const textWithoutTags = text.replace(/<\/?p>/gi, "");
            return textWithoutTags;
          }
          function truncateText(text, maxLength) {
            if (text.length <= maxLength) {
              return text;
            } else {
              return text.slice(0, maxLength) + "...";
            }
          }
          // console.log(res);
          this.articles = res.data;
          for (let i = 0; i < this.articles.length; i++) {
            axios
              .get(this.articles[i]._links["wp:featuredmedia"][0].href)
              .then((res) => {
                // console.log(res);
                this.articleCovers.push(
                  res.data.media_details.sizes.full.source_url
                );
                this.displayedCovers[i] =
                  res.data.media_details.sizes.full.source_url;
                // console.log(this.articleCovers);
              });
            blogApi.get(`users/${this.articles[i].author}`).then((res) => {
              this.articleAuthor.push(res.data.name);
              this.authorPicture.push(res.data.avatar_urls["96"]);
              this.displayedAuthors[i] = res.data.avatar_urls["96"];
            });

            this.articleTime.push(
              convertToIndonesianDate(
                removeTimeFromISODate(this.articles[i].date_gmt)
              )
            );
            this.formattedText.push(
              truncateText(
                removeElementTag(this.articles[i].excerpt.rendered),
                105
              )
            );
          }
          this.checkIfLoaded();
          // for (let i = 0; i < this.articles.length; i++) {
          // }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    toTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleMouseDown(e) {
      this.startPosition = e.clientX;
      this.startScrollLeft = this.container.scrollLeft;
      this.container.classList.add("grabbing");
    },

    handleMouseMove(e) {
      if (this.container.classList.contains("grabbing")) {
        const deltaX = e.clientX - this.startPosition;
        this.container.scrollLeft = this.startScrollLeft - deltaX;
      }
    },

    handleMouseUp() {
      this.container.classList.remove("grabbing");
    },

    handleTouchStart(e) {
      this.startPosition = e.touches[0].clientX;
      this.startScrollLeft = this.container.scrollLeft;
    },

    handleTouchMove(e) {
      const deltaX = e.touches[0].clientX - this.startPosition;
      this.container.scrollLeft = this.startScrollLeft - deltaX;
    },

    handleTouchEnd() {},
    initScrolling() {
      if (this.isLoading && !this.isImageLoaded) return;
      this.container = this.$refs.container;

      this.container.addEventListener("mousedown", this.handleMouseDown);
      this.container.addEventListener("mousemove", this.handleMouseMove);
      this.container.addEventListener("mouseup", this.handleMouseUp);
      this.container.addEventListener("mouseleave", this.handleMouseUp);
      this.container.addEventListener("touchstart", this.handleTouchStart);
      this.container.addEventListener("touchmove", this.handleTouchMove);
      this.container.addEventListener("touchend", this.handleTouchEnd);
    },
    checkIfLoaded() {
      let checking = setInterval(() => {
        if (!this.isLoading && !this.isImageLoaded) {
          clearInterval(checking);
          this.initScrolling();
        }
      }, 1);
    },
  },
  data() {
    return {
      isLoading: null,
      articles: [],
      articleCovers: [],
      displayedCovers: [null, null, null, null, null, null],
      articleAuthor: [],
      displayedAuthors: [null, null, null, null, null, null],
      authorPicture: [],
      articleTime: [],
      formattedText: [],
      article: {
        container: null,
        startPosition: 0,
        startScrollLeft: 0,
      },
    };
  },
};
</script>

<style scoped>
img {
  pointer-events: none;
}

.more-article {
  padding: 1rem 0 3rem 0;
}

.more-article p {
  justify-content: center;
  font-weight: 600;
  font-size: 1.5rem;
}

.more-article a,
.more-article a:visited,
.more-article a:link {
  color: #000000;
  text-decoration: none;
}

.article-paragraph {
  padding-top: 3rem;
  padding-bottom: 4rem;
  width: 60%;
}

.article-paragraph h1 {
  font-weight: 600;
  font-size: 2rem;
  color: #252525;
  margin-top: 0;
}

.article-paragraph h2 {
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  color: #252525;
}

.article-paragraph hr {
  border-width: 0.15rem;
  border-style: solid;
  border-color: #e4000f;
  width: 15%;
}

.article-card-container {
  display: flex;
  gap: 1.5rem;
  overflow: auto;
  width: 72%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.11);
}

.article-card-container::-webkit-scrollbar {
  display: none;
}

.article-card-item {
  background-color: #ffffff;
  text-align: left;
}

.article-card-item a {
  text-decoration: none;
  color: #000000;
}

.article-card-item a:link {
  text-decoration: none;
  color: #000000;
}

.article-card-item a:visited {
  text-decoration: none;
  color: #000000;
}

.article-cover {
  width: ;
  height: 300px;
}

.article-card-details {
  padding: 0.25rem 1rem 1rem 1rem;
}

.article-card-user-timestamp {
  display: flex;
  gap: 1rem;
}

.article-card-user-timestamp div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.article-card-item div div img {
  object-fit: cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.article-card-item h1 {
  font-weight: 500;
  font-size: 1.25rem;
  margin: 0;
}

.article-card-item h2 {
  font-weight: 400;
  font-size: 1rem;
}

.article-card-item h3 {
  font-weight: 400;
  font-size: 1rem;
}

.article-card-item h4 {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

.article-empty {
}

.article-empty h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

.article-more {
  /* margin-top: auto; */
}

.article-more a {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  width: 8.5rem;
}

.grabbing {
  cursor: grabbing;
}

@media only screen and (max-width: 1473px) {
  .article-paragraph {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .article-paragraph h1 {
    font-size: 1.75rem;
    margin: 0;
    padding-bottom: 0.75rem;
  }
  .article-paragraph h2 {
    font-size: 1.25rem;
    margin-top: 0;
  }
  .article-cover {
    height: 250px;
  }
  .article-card-item {

  }
  .article-card-item h1 {
    font-size: 0.95rem;
  }
  .article-card-item h2 {
    font-size: 0.8rem;
  }
  .article-card-item h3 {
    font-size: 0.8rem;
  }
  .article-card-item h4 {
    font-size: 0.8rem;
  }
  .more-article {
    padding-bottom: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .article-paragraph {
    width: 80%;
    padding: 2rem 0;
  }
  .article-card-item div div img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  .article-paragraph h1 {
    font-size: 1.4rem;
  }
  .article-paragraph h2 {
    font-size: 1.1rem;
    /* letter-spacing: 0.1em; */
  }
  .article-card-container {
    width: 85%;
  }
  .article-card-details {
    padding-bottom: 1rem;
  }
  .article-cover {
    width: 250px;
    height: 174.41px;
  }
  .article-card-item h1 {
    font-size: 0.75rem;
  }
  .article-card-item h2 {
    font-size: 0.6rem;
  }
  .article-card-item h3 {
    font-size: 0.6rem;
  }
  .article-card-item h4 {
    font-size: 0.6rem;
  }
  .more-article {
    padding: 2rem 0;
  }
  .more-article a {
    font-size: 1.25rem;
  }
  .more-article p {
    margin: 0;
  }
}
</style>
