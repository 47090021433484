<template>
  <div class="background-container">
    <h1>Latar Belakang</h1>
    <h2>
      PT. Adhi Konsultan Utama bergerak di bidang jasa Konsultan Perencanaan dan
      Pengawasan, Perdagangan Umum, dan Pengurusan Perizinan. Pelayanan jasa
      berdasarkan manajemen yang cermat, akurat, dan profesional. Tujuannya
      ialah mencapai efisiensi dan efektivitas pemanfaatan sumber daya yang
      tersedia, khususnya di dalam memberikan pelayanan yang diperlukan oleh
      masyarakat. PT. Adhi Konsultan Utama telah membina sumber daya manusia
      dari berbagai macam disiplin ilmu yaitu tenaga ahli yang berkualitas dan
      berpengalaman untuk menunjang tujuan tersebut. Saat ini perusahaan telah
      memiliki tenaga kerja yang berpengalaman di bidang arsitektur, struktur,
      mekanikal elektrikal serta lingkungan.
    </h2>
  </div>
</template>

<style scoped>
h1 {
  font-weight: 600;
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  color: #ff2230;
  padding-left: 2rem;
}

h2 {
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  color: #252525;
  padding-top: 1rem;
  text-align: justify;
}

@media only screen and (max-width: 1440px) {
  h1 {
    font-size: 2rem;
    margin: 0;
    padding: 2rem 0 2rem 2rem;
  }
  h2 {
    font-size: 1.25rem;
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 1050px) {
  h1 {
    font-size: 1.75rem;
  }
  h2 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 0;
    padding: 0;
  }
  h2 {
    font-size: 1rem;
  }
}
</style>
