<template>
  <div class="notice prevent-select">
    <div>
      <img src="@/assets/user/icons/career/info.svg" alt="" draggable="false" />
    </div>
    <div>
      <h1>Lamaran Anda berhasil terkirim!</h1>
      <h1>
        Cek berkala email anda untuk mengetahui informasi seputar penerimaan
        anda di perusahaan kami.
      </h1>
    </div>
  </div>
  <div class="main-container">
    <div class="form-header">
      <h1>DATA PENDAFTARAN</h1>
    </div>
    <div class="data-container">
      <h1>DATA PRIBADI</h1>
      <div class="d-e-container">
        <div class="photo-container">
          <img src="@/assets/user/images/career/person.png" alt="" />
        </div>
        <div class="data">
          <div class="data-item">
            <h1>Nama Lengkap</h1>
            <h2>Fajar Wrapped Lawang</h2>
          </div>
          <div class="data-item">
            <h1>Gender</h1>
            <h2>Laki-Laki</h2>
          </div>
          <div class="data-item">
            <h1>Alamat saat ini</h1>
            <h2>Jl. Soekarno Hatta 999</h2>
          </div>
          <div class="data-item">
            <h1>RT / RW</h1>
            <h2>99 / 99</h2>
          </div>
          <div class="data-item">
            <h1>Kota</h1>
            <h2>London</h2>
          </div>
          <div class="data-item">
            <h1>Kecamatan</h1>
            <h2>Bekasi</h2>
          </div>
          <div class="data-item">
            <h1>Kelurahan</h1>
            <h2>Kebumen</h2>
          </div>
          <div class="data-item">
            <h1>No. Telepon Rumah</h1>
            <h2>999 999 999 999</h2>
          </div>
          <div class="data-item">
            <h1>No. Telepon Darurat</h1>
            <h2>666 666 666 666</h2>
          </div>
          <div class="data-item">
            <h1>No. HP 1</h1>
            <h2>999 999 999 999</h2>
          </div>
          <div class="data-item">
            <h1>Email</h1>
            <h2>example@example.com</h2>
          </div>
          <div class="data-item">
            <h1>Tempat &#38; Tgl Lahir</h1>
            <h2>Kendal, 30 Februari 2929</h2>
          </div>
          <div class="data-item">
            <h1>Agama</h1>
            <h2>Jawa</h2>
          </div>
          <div class="data-item">
            <h1>Status Perkawinan</h1>
            <h2>Single</h2>
          </div>
          <div class="data-item">
            <h1>No. KTP / SIM</h1>
            <h2>999999999</h2>
          </div>
          <div class="data-item">
            <h1>Jenis Kendaraan 1</h1>
            <h2>Motor</h2>
          </div>
          <div class="data-item">
            <h1>No. Polisi</h1>
            <h2>AB 023499 KT</h2>
          </div>
          <div class="data-item">
            <h1>Golongan Darah</h1>
            <h2>A</h2>
          </div>
          <div class="data-item">
            <h1>Rhesus</h1>
            <h2>+</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="answer-box">
      <h1>RIWAYAT PENDIDIKAN</h1>
      <div>
        <div class="answer-container">
          <div class="answer-item">
            <h2>Agama</h2>
            <h3>Jawa</h3>
          </div>
          <div class="answer-item">
            <h2>Status Perkawinan</h2>
            <h3>Single</h3>
          </div>
          <div class="answer-item">
            <h2>No. KTP / SIM</h2>
            <h3>999999999</h3>
          </div>
          <div class="answer-item">
            <h2>Jenis Kendaraan 2</h2>
            <h3>Motor</h3>
          </div>
          <div class="answer-item">
            <h2>No. Polisi</h2>
            <h3>AB 023499 KT</h3>
          </div>
          <div class="answer-item">
            <h2>Golongan Darah</h2>
            <h3>A</h3>
          </div>
          <div class="answer-item">
            <h2>Rhesus</h2>
            <h3>+</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="answer-box">
      <h1>KURSUS, SEMINAR &#38; PELATIHAN</h1>
      <div>
        <div class="answer-container">
          <div class="answer-item">
            <h2>Nama kursus</h2>
            <h3>SMK A</h3>
          </div>
          <div class="answer-item">
            <h2>Penyelenggara</h2>
            <h3>SMK</h3>
          </div>
          <div class="answer-item">
            <h2>Kota sekolah</h2>
            <h3>Kudus</h3>
          </div>
          <div class="answer-item">
            <h2>Jurusan</h2>
            <h3>RPL</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="answer-box">
      <h1>RIWAYAT PEKERJAAN</h1>
      <div>
        <div class="answer-container">
          <div class="answer-item">
            <h2>Nama perusahaan</h2>
            <h3>SMK A</h3>
          </div>
          <div class="answer-item">
            <h2>Alamat perusahaan</h2>
            <h3>Jalan taman siswa 6a soekarno hatta tangeran banten</h3>
          </div>
          <div class="answer-item">
            <h2>Bergerak dibidang</h2>
            <h3>Industri makanan</h3>
          </div>
          <div class="answer-item">
            <h2>Jabatan</h2>
            <h3>Direksi utama</h3>
          </div>
          <div class="answer-item">
            <h2>Gaji</h2>
            <h3>Rp 10.000.000</h3>
          </div>
          <div class="answer-item">
            <h2>Tahun mulai bekerja</h2>
            <h3>2019</h3>
          </div>
          <div class="answer-item">
            <h2>Tahun keluar kerja</h2>
            <h3>2020</h3>
          </div>
          <div class="answer-item">
            <h2>Alasan keluar</h2>
            <h3>Mendapatkan pekerjaan yang lebih disukai</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="answer-box">
      <h1>KETERANGAN LAIN</h1>
      <div>
        <div class="answer-container">
          <div class="etc-item">
            <h2>Apakah anda siap lembur?</h2>
            <h3>YA</h3>
          </div>
          <div class="etc-item">
            <h2>Apakah anda siap kerja pada hari minggu, hari libur nasional?</h2>
            <h3>YA</h3>
          </div>
          <div class="etc-item">
            <h2>Apakah anda siap bekerja shift? &#40;pagi, siang, malam&#41;</h2>
            <h3>YA</h3>
          </div>
          <div class="etc-item">
            <h2>Apakah anda bersedia ditempatkan di seluruh cabang PT. ADHI KONSULTAN?</h2>
            <h3>YA</h3>
          </div>
          <div class="etc-item">
            <h2>Gaji minimum yang diinginkan?</h2>
            <h3>300.000.000</h3>
          </div>
          <div class="etc-item">
            <h2>Tanggal berapa Anda ingin bergabung?</h2>
            <h3>10 November 2030</h3>
          </div>
          <div class="etc-item hide">
            <h2>Ttd</h2>
            <img src="" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
img {
  pointer-events:  none;
}

.notice {
  display: flex;
  background: #ffffff;
  padding: 2rem 2rem 2rem 2rem;
  gap: 1rem;
}

.notice div:last-of-type {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.notice h1 {
  margin: 0;
  font-weight: 500;
}

.notice h1:first-of-type {
  font-size: 2rem;
  color: #ff0010;
}

.notice h1:last-of-type {
  font-size: 1.85rem;
  color: #424242;
}

.main-container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.form-header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
}

.form-header h1 {
  margin: 0;
  font-weight: 600;
  font-size: 2rem;
  padding: 2rem 0 0 2rem;
}

.data-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 2rem 4rem;
  gap: 1rem;
}

.data-container::after {
  content: "";
  position: absolute;
  top: 1.25rem;
  right: 2rem;
  left: 2rem;
  bottom: 0;
  border-radius: 3px;
  border: #424242 1px solid;
  pointer-events: none;
}

.data-container h1 {
  margin: 0;
  font-weight: 500;
  font-size: 2rem;
}

.d-e-container {
  display: flex;
  gap: 5rem;
}

.photo-container img {
  height: 360px;
  width: 240px;
}

.data {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.data-item {
  display: flex;
}

.data-item h1,
.data-item h2 {
  margin: 0;
}

.data-item h1:first-of-type {
  font-size: 2rem;
  font-weight: 300;
  width: 25rem;
}

.data-item h2:last-of-type {
  font-size: 2rem;
  font-weight: 500;
}

.answer-box {
  position: relative;
  padding: 2rem 3.5rem 0 3.5rem;
}

.answer-box::after {
  content: "";
  position: absolute;
  top: 2rem;
  right: 2rem;
  left: 2rem;
  bottom: 0;
  border-radius: 3px;
  border: #424242 1px solid;
  pointer-events: none;

}
.answer-box:last-of-type {
  position: relative;
  padding: 2rem 3.5rem 3rem 3.5rem;
}

.answer-box:last-of-type:after {
  content: "";
  position: absolute;
  top: 2rem;
  right: 2rem;
  left: 2rem;
  bottom: 3rem;
  border-radius: 3px;
  border: #424242 1px solid;
  pointer-events: none;
}

.answer-box h1 {
  margin: 0;
  padding-left: 0.75rem;
  padding-top: 2rem;
  font-size: 2rem;
  font-weight: 500;
}

.answer-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 0 2rem 0.75rem;
}

.answer-item {
  display: flex;
}

.answer-item h2:first-of-type {
  font-size: 2rem;
  font-weight: 300;
  width: 25rem;
  margin: 0;
}

.answer-item h3:last-of-type {
  font-size: 2rem;
  font-weight: 500;
  margin: 0;
}

.etc-item {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.etc-item * {
  margin: 0;
}

.etc-item h2:first-of-type {
  font-size: 2rem;
  font-weight: 300;
  max-width: 47rem;
}

.etc-item h3:first-of-type {
  font-size: 2rem;
  font-weight: 500;
}
</style>
