<template>
  <div class="notice prevent-select">
    <div>
      <img src="@/assets/user/icons/career/info.svg" alt="" draggable="false" />
    </div>
    <div>
      <h1>informasi penting</h1>
      <h1>
        isi formulir pendaftaran dengan benar sesuai dengan data diri Anda.
        formulir bertanda <span class="required">*</span> wajib diisi.<br />setelah
        Anda konfirmasi kirim maka Anda tidak dapat merubah data apapun
      </h1>
    </div>
  </div>
  <div class="main-container">
    <div class="form-header">
      <h1>Formulir pendaftaran</h1>
      <hr />
      <img
        src="@/assets/user/images/career/step-5.svg"
        alt=""
        draggable="false"
        class="prevent-select"
      />
    </div>
    <form class="career-form" @submit.prevent>
      <div>
        <h1>KETERANGAN LAIN</h1>
        <hr />
      </div>
      <div class="form-questions">
        <div class="horizontal-q">
          <h2>Apakah Anda siap kerja lembur?<span class="required">*</span></h2>
          <div>
            <label>
              <input type="radio" value="true" v-model="extra.ready_lembur" />
              Ya
            </label>
            <label>
              <input type="radio" value="false" v-model="extra.ready_lembur" />
              Tidak
            </label>
          </div>
        </div>
        <div class="horizontal-q">
          <h2>
            Apakah Anda siap kerja pada hari minggu, hari libur nasional?<span
              class="required"
              >*</span
            >
          </h2>
          <div>
            <label>
              <input type="radio" value="true" v-model="extra.ready_rodi" />
              Ya
            </label>
            <label>
              <input type="radio" value="false" v-model="extra.ready_rodi" />
              Tidak
            </label>
          </div>
        </div>
        <div class="horizontal-q">
          <h2>
            Apakah anda siap bekerja shift? &#40;pagi, siang, malam&#41;<span
              class="required"
              >*</span
            >
          </h2>
          <div>
            <label>
              <input type="radio" value="true" v-model="extra.ready_shift" />
              Ya
            </label>
            <label>
              <input type="radio" value="false" v-model="extra.ready_shift" />
              Tidak
            </label>
          </div>
        </div>
        <div class="horizontal-q">
          <h2>
            Apakah anda bersedia ditempatkan di seluruh cabang PT. ADHI
            KONSULTAN?<span class="required">*</span>
          </h2>
          <div>
            <label>
              <input type="radio" value="true" v-model="extra.ready_deploy" />
              Ya
            </label>
            <label>
              <input type="radio" value="false" v-model="extra.ready_deploy" />
              Tidak
            </label>
          </div>
        </div>
        <div class="horizontal-q wage">
          <h2>
            Gaji minimum yang Anda inginkan?<span class="required">*</span>
          </h2>
          <input
            type="text"
            v-model="formattedValue"
            @input="updateValue"
            @keypress="preventNonNumericInput"
            maxlength="22"
          />
        </div>
        <div class="horizontal-date-q">
          <h2>Kapan Anda dapat bergabung?<span class="required">*</span></h2>
          <input type="date" id="date-input" v-model="rawDate" />
        </div>
        <div class="sign prevent-select">
          <h2>
            Tanda tangan online &#40;jpg atau png&#41;<span class="required"
              >*</span
            >
          </h2>
          <img
            class="image-previewed"
            v-if="base64"
            :src="base64"
            alt="preview-img"
          />
          <div v-else class="image-preview"><p>1:1</p></div>
          <div class="select-file-div">
            <label for="img-select">Pilih dari file manager</label>
          </div>
          <input
            type="file"
            id="img-select"
            class="hide"
            accept="image/*"
            @change="previewImage"
          />
          <h3>
            Untuk mengubah ukuran gambar Anda dapat menggunakan
            <a
              href="https://zeeconvert.com/image-resizer/resize-image-to-1:1.php"
              target="_blank"
              rel="noopener noreferrer"
              >website ini</a
            >.
            <br />
            <span class="required">
              Tinggi gambar tidak boleh lebih dari 150px.</span
            >
          </h3>
        </div>
      </div>
    </form>
    <div class="next-button prevent-select">
      <button class="hide">Sebelumnya</button>
      <button @click="validateCheck">Selanjutnya</button>
    </div>
  </div>
</template>

<script>
import { fileToBase64 } from "@/helpers/index.js";

export default {
  data() {
    return {
      base64: null,
      value: 0,
      allowNext: false,
      rawDate: null,
      file: {
        signature: null,
      },
      extra: {
        ready_lembur: null,
        ready_rodi: null,
        ready_shift: null,
        ready_deploy: null,
        gaji_minimum: null,
        siap_kerja: null,
      },
    };
  },
  mounted() {
    const today = new Date().toISOString().split("T")[0];
    document.querySelector("#date-input").setAttribute("min", today);
  },
  methods: {
    async previewImage(e) {
      let file = e.target.files[0];
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!file) {
        alert("Please select an image file.");
        return;
      }
      if (!allowedTypes.includes(file.type)) {
        alert(
          "Please select a valid image file.\nValid image file type: jpeg, png."
        );
        e.target.value = "";
        return;
      }
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const heightInCentimeters = img.height;

        if (heightInCentimeters < 151) {
          this.file.signature = file;
          let promise = fileToBase64(file);
          promise.then((result) => {
            this.base64 = result?.src;
          });
        } else {
          alert("Image height must be smaller than 4cm.");
          e.target.value = "";
          return;
        }
      };
    },
    updateValue(event) {
      this.extra.gaji_minimum = event.target.value
        .replace(/^Rp\s*/, "")
        .replace(/\./g, "");

      const rawValue = event.target.value.replace(/\D/g, "");

      this.value = Number(rawValue);
    },
    preventNonNumericInput(event) {
      const allowedKeys = /[0-9.]/;
      if (!allowedKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    selectJob(selectedCareer) {
      this.$root.emitter.emit("selectJob", selectedCareer);
    },
    openConfirmationModal() {
      this.$root.emitter.emit("openConfirmationModal");
    },
    emptyCheck() {
      if (
        !this.file.signature ||
        !this.extra.ready_lembur ||
        !this.extra.ready_rodi ||
        !this.extra.ready_shift ||
        !this.extra.ready_deploy ||
        !this.extra.gaji_minimum ||
        !this.rawDate
      ) {
        this.allowNext = false;
      } else this.allowNext = true;
    },
    rearrangeDate() {
      const inputDate = this.rawDate;
      const dateObject = new Date(inputDate);

      // Get day, month, and year components from the date object
      const day = dateObject.getDate();
      const month = dateObject.getMonth() + 1; // Months are 0-based, so add 1
      const year = dateObject.getFullYear();

      // Format day, month, and year with leading zeros if needed
      const formattedDay = String(day).padStart(2, "0");
      const formattedMonth = String(month).padStart(2, "0");

      // Create the "mm/dd/yyyy" formatted date string
      const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;

      // Set the formatted date back to the input field
      this.extra.siap_kerja = formattedDate;
    },
    validateCheck() {
      this.emptyCheck();
      // console.log(this.extra);
      // console.log(this.allowNext);
      if (this.allowNext) {
        // this.extra.ready_deploy = parseInt(this.extra.ready_deploy);
        // this.extra.ready_lembur = parseInt(this.extra.ready_lembur);
        // this.extra.ready_rodi = parseInt(this.extra.ready_rodi);
        // this.extra.ready_shift = parseInt(this.extra.ready_shift);
        this.extra.ready_deploy = /true/.test(this.extra.ready_deploy);
        this.extra.ready_lembur = /true/.test(this.extra.ready_lembur);
        this.extra.ready_rodi = /true/.test(this.extra.ready_rodi);
        this.extra.ready_shift = /true/.test(this.extra.ready_shift);
        this.extra.gaji_minimum = parseInt(this.extra.gaji_minimum);
        this.rearrangeDate();
        this.$root.emitter.emit("setExtra", this.extra);
        this.$root.emitter.emit("setPhoto", this.file);
        this.openConfirmationModal();
      } else alert("Tolong isi kolom yang wajib diisi.");
    },
  },
  computed: {
    formattedValue() {
      const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });
      return `${formatter.format(this.value)}`;
    },
  },
};
</script>

<style scoped>
.notice {
  display: flex;
  background: #ffffff;
  padding: 2rem 2rem 2rem 2rem;
  gap: 1rem;
}

.notice div:last-of-type {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.notice h1 {
  margin: 0;
  font-weight: 500;
}

.notice h1:first-of-type {
  font-size: 2rem;
  color: #ff0010;
}

.notice h1:last-of-type {
  font-size: 1.85rem;
  color: #424242;
}

.main-container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.form-header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
}

.form-header h1 {
  margin: 0;
  font-weight: 500;
  font-size: 2rem;
  padding: 2rem 0 0 2rem;
}

.form-header hr {
  width: 100%;
  border-style: solid;
  color: #bcbcbc;
}

.form-header img {
  text-align: center;
  vertical-align: middle;
  width: 95%;
  padding-left: 2.5%;
  pointer-events: none;
}

.career-form {
  display: flex;
  flex-direction: column;
  padding: 1rem 4rem 6rem 4rem;
  gap: 1.5rem;
}

.career-form h1 {
  margin: 0;
  padding: 0 0 1rem 2rem;
  font-size: 2rem;
  color: #000000;
}

.career-form hr {
  width: 100%;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
}

.form-questions {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.form-questions .horizontal-q {
  display: flex;
  align-items: center;
}

.horizontal-q {
  gap: 2rem;
}

.horizontal-q div {
  display: flex;
  gap: 3rem;
}

.wage {
  justify-content: space-between;
}

.wage input[type="text"] {
  width: 50.75% !important;
}

.horizontal-q h2:first-of-type,
.horizontal-3-q h2:first-of-type {
  font-weight: 400;
  font-size: 1.75rem;
}

.horizontal-q input[type="text"] {
  width: 75%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

.horizontal-date-q {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.horizontal-date-q input[type="date"] {
  width: 50%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 0.5rem;
  font-size: 1.5rem;
  padding-right: 1rem;
  color: #3a3a3a;
  font-family: "Poppins";
}

.horizontal-q input[type="number"] {
  width: 60%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
  -moz-appearance: textfield;
}

.horizontal-q input[type="email"] {
  width: 75%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.horizontal-q label {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1.75rem;
}

input[type="radio"] {
  box-sizing: border-box;
  margin: 0;
  background-color: #fff;
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: #ff2230;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #000000;
  border-radius: 50%;
  transform: translateY(0.25rem);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="radio"]:checked {
  border: 0.1em solid #ff2230;
  cursor: default;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #ff2230;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.education-radio-input {
  display: flex;
  gap: 6rem;
  height: 3.5rem;
  align-items: center;
}

.enter-leave-q {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1.75rem;
  padding-top: 1rem;
}

.enter-leave-q label {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 47%;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
  row-gap: 1.25rem;
}

.enter-leave-q label:last-of-type {
  padding-bottom: 1rem;
}

.enter-leave-q input {
  box-sizing: border-box;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

.next-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 4rem 4rem 0;
  gap: 1.25rem;
}

.next-button button:first-of-type {
  font-weight: 500;
  font-size: 1.75rem;
  color: #ffffff;
  background: #1d1d1d;
  border-radius: 5px;
  border-style: none;
  padding: 1rem 1rem;
  cursor: pointer;
}

.next-button button:first-of-type:hover {
  background-color: #1e1e1e;
}

.next-button button:first-of-type:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #1f1f1f;
}

.next-button button:last-of-type {
  font-weight: 500;
  font-size: 1.75rem;
  color: #ffffff;
  background: #ff2230;
  border-radius: 5px;
  border-style: none;
  padding: 1rem 1rem;
  cursor: pointer;
}

.next-button button:last-of-type:hover {
  background-color: #f90011;
}

.next-button button:last-of-type:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
.sign {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sign .image-preview {
  display: flex;
  background: #d9d9d9;
  width: 180px;
  height: 180px;
  justify-content: center;
  align-items: center;
}

.sign h2,
.form-questions h2 {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
}

.sign h3 {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
  color: #000;
}

.image-preview p {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

.image-previewed {
  vertical-align: middle;
  /* width: 180px; */
  max-width: 360px;
  height: 180px;
  pointer-events: none;
}

.sign .select-file-div {
  padding: 0.6rem 0 0.75rem 0;
}

.sign label {
  height: 3rem;
  font-weight: 500;
  font-size: 1.25rem;
  color: #ffffff;
  background: #ff2230;
  border-radius: 5px;
  border-style: none;
  padding: 0.6rem 1rem;
  cursor: pointer;
}

.sign label:hover {
  background-color: #f90011;
}

.sign label:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
@media only screen and (max-width: 1512px) {
  .notice {
    gap: 0.75rem;
  }
  .notice img {
    width: 2rem;
  }
  .notice h1:first-of-type {
    font-size: 1.5rem;
  }
  .notice h1:last-of-type {
    font-size: 1.25rem;
  }
  .notice div:last-of-type {
    gap: 0.5rem;
  }
  .career-form h1 {
    font-size: 1.75rem;
  }
  .horizontal-q h2:first-of-type,
  .textarea-q h2 {
    font-size: 1.5rem;
  }
  .horizontal-q label {
    font-size: 1.5rem;
    gap: 1rem;
  }
  .horizontal-q input[type="text"],
  .horizontal-q input[type="email"],
  .horizontal-q input[type="number"] {
    height: 3rem;
    font-size: 1.25rem;
  }
  .horizontal-date-q input[type="date"] {
    height: 3rem;
    font-size: 1.25rem;
    width: 49.5%;
  }
  .horizontal-date-q h2 {
    font-size: 1.5rem;
  }
  .sign h2 {
    font-size: 1.5rem;
  }
  .sign label {
    font-size: 1rem;
  }
  .next-button button:last-of-type {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 400px) {
  h2 {
    margin: 0;
  }
  .notice {
    padding: 1rem;
  }
  .notice img {
    width: 1.75rem;
  }
  .notice h1:first-of-type {
    font-size: 1rem;
  }
  .notice h1:last-of-type {
    font-size: 0.75rem;
  }
  .notice div:last-of-type {
    gap: 0.5rem;
  }
  .form-header {
    gap: 1rem;
    overflow: auto;
  }
  .form-header h1 {
    padding: 1rem;
    padding-bottom: 0;
    font-size: 1.25rem;
  }
  .form-header img {
    width: 30rem;
  }
  .career-form {
    padding: 1rem 1rem;
  }
  .career-form h1 {
    padding: 0 0 0.25rem 1rem;
    font-size: 1rem;
  }
  .photo {
    padding: 0 0.5rem;
  }
  .photo label {
    font-size: 1rem;
  }
  .form-questions {
    padding: 0 0.5rem;
  }
  .form-questions h2 {
    font-size: 1rem;
  }
  .form-questions .horizontal-q {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
  .horizontal-q h2:first-of-type {
    font-size: 1rem;
    width: auto;
  }
  .horizontal-q input[type="text"],
  .horizontal-q input[type="email"],
  .horizontal-q input[type="number"] {
    width: 100%;
    height: 2rem;
    box-sizing: border-box;
    font-size: 1rem;
    text-indent: 0.5rem;
  }
  .horizontal-q div {
    gap: 1rem;
  }
  .horizontal-q div label {
    font-size: 1rem;
    gap: 0.5rem;
  }
  .horizontal-date-q {
    flex-direction: column;
    gap: 0;
  }
  .horizontal-date-q input[type="date"] {
    height: 2rem;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
  }
  .wage input[type="text"] {
    width: 100% !important;
  }
  .sign label {
    font-size: 1rem;
  }
  .enter-leave-q {
  }
  .enter-leave-q label {
    font-size: 1rem;
    gap: 0;
  }
  .enter-leave-q input {
    height: 2rem;
    font-size: 1rem;
    text-indent: 0.5rem;
  }
  .next-button button:first-of-type,
  .next-button button:last-of-type {
    font-size: 1rem;
    padding: 0.75rem;
  }
  .next-button {
    padding: 1rem 1rem;
    gap: 0;
    /* justify-content: space-between; */
    justify-content: right;
  }
}
</style>
