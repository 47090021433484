<template>
  <div class="confirmation-modal">
    <div class="modal-shadow" @click="closeConfirmationModal"></div>
    <div class="modal-container">
      <img class="prevent-select" :src="img" alt="" draggable="false" />
      <div>
        <h1>{{ h1msg }}</h1>
        <h2>
          {{ h2msg }}
        </h2>
      </div>
      <div class="prevent-select">
        <button class="black-button" @click="closeConfirmationModal">
          Batal
        </button>
        <button class="red-button" @click="sendCareer">{{ redButton }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      img: "/user/assets/icons/career/business.svg",
      h1msg: "Apakah Anda sudah yakin ingin mengirim lamaran ini?",
      h2msg:
        "Data yang sudah Anda kirim tidak dapat dirubah kembali! pastikan data yang Anda masukan sudah sesuai.",
      redButton: "Kirim lamaran",
      sending: false,
      allowClose: false,
    };
  },
  methods: {
    closeConfirmationModal() {
      this.$root.emitter.emit("closeConfirmationModal");
    },
    sendCareer() {
      if (this.allowClose) return this.closeConfirmationModal();
      if (this.sending) return;
      this.sending = true;
      this.redButton = "Mengirim lamaran";
      document.querySelector(".red-button").classList.add("saving");
      this.$root.emitter.emit("sendCareer");
    },
  },
  mounted() {
    this.$root.emitter.on("careerConfirmationButtonSending", () => {
      this.redButton = "Mengirim lamaran";
      document.querySelector(".red-button").classList.add("saving");
    });
    this.$root.emitter.on("careerConfirmationSuccessSending", () => {
      this.img = "/user/assets/icons/career/redsuccess.svg";
      this.h1msg = "Lamaran berhasil terkirim!";
      this.h2msg =
        "Terima kasih atas partisipasi Anda! cek email Anda secara berkala untuk informasi lebih lanjut.";
      this.redButton = "Tutup";
      this.allowClose = true;
      document.querySelector(".red-button").classList.remove("saving");
      document.querySelector(".black-button").classList.add("hide");
    });
    this.$root.emitter.on("careerConfirmationFailedSending", () => {
      this.img = "/user/assets/icons/career/redfail.svg";
      this.h1msg = "Lamaran gagal terkirim!";
      this.h2msg =
        "Silakan coba kembali mengisi formulir setelah Anda memuat ulang halaman ini. Jika masalah masih terus terjadi harap hubungi admin.";
      this.redButton = "Tutup";
      this.allowClose = true;
      document.querySelector(".red-button").classList.remove("saving");
      document.querySelector(".black-button").classList.add("hide");
    });
  },
};
</script>

<style scoped>
.confirmation-modal {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: opacity 0.2s;
  opacity: 0;
}

.modal-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.confirmation-modal-active {
  opacity: 1;
}

.modal-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 5rem;
  width: 5rem;
  background: white;
  border-radius: 50%;
  transition: all 500ms cubic-bezier(0.93, 0.1, 0.42, 0.91);
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  font-family: "Poppins";
  overflow: hidden;
  justify-content: space-between;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.modal-container div:first-of-type {
  padding-bottom: 5rem;
}

.modal-container img {
  margin-right: auto;
  margin-left: auto;
  width: 18rem;
}

.modal-container h1 {
  margin: 0;
  font-weight: 700;
  font-size: 1.5rem;
  color: #3a3a3a;
  text-align: center;
  padding-bottom: 1rem;
}

.modal-container h2 {
  margin: 0;
  font-weight: 500;
  font-size: 1.5rem;
  color: #3a3a3a;
  text-align: center;
}

.modal-container button:first-of-type {
  height: 3rem;
  font-weight: 500;
  font-size: 1rem;
  color: #ff2230;
  background: #ffffff;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #ff2230;
  padding: 0 1rem;
  cursor: pointer;
}

.modal-container button:first-of-type:hover {
  background-color: #f9f9f9;
}

.modal-container button:first-of-type:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #f1f1f1;
}
.modal-container button:last-of-type {
  height: 3rem;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  background: #ff2230;
  border-radius: 5px;
  border-style: none;
  padding: 0 1rem;
  cursor: pointer;
}

.modal-container button:last-of-type:hover {
  background-color: #f90011;
}

.modal-container button:last-of-type:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.modal-container div:last-of-type {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.modal-container-active {
  height: 28rem;
  width: 45%;
  border-radius: 10px;
}

.saving {
  background-color: #ff737d !important;
  cursor: default !important;
}

@media only screen and (max-width: 1512px) {
  .modal-container h1 {
    font-size: 1.25rem;
  }
  .modal-container h2 {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 400px) {
  .modal-container-active {
    width: 70%;
    height: 10rem;
  }
  .modal-container div:first-of-type {
    padding-bottom: 0.5rem;
  }
  .modal-container img {
    /* width: 10rem; */
    display: none;
  }
  .modal-container h1 {
    font-size: 1rem;
    padding-bottom: 0.25rem;
  }
  .modal-container h2 {
    font-size: 0.75rem;
  }
  .modal-container button:first-of-type {
    height: 2rem;
  }
  .modal-container button:last-of-type {
    height: 2rem;
  }
  .modal-container div:last-of-type {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 1570px) {
  .modal-container div:first-of-type {
    padding-bottom: 3.3rem;
  }
}
@media only screen and (max-width: 1481px) {
  .modal-container div:first-of-type {
    padding-bottom: 1.1rem;
  }
}
</style>
