<template>
  <div class="project-container">
    <div class="project-container-header">
      <div class="header-title">
        <h1>Tambah project</h1>
      </div>
      <div class="header-buttons prevent-select" v-if="!isLoading && !isError">
        <button
          :class="[{ 'disabled-button': !valueChanged }, 'save-button']"
          @click="addNoImage"
        >
          Simpan sebagai draf &#40;Tanpa gambar&#41;
        </button>
        <button
          :class="[{ 'disabled-button': !isAllowedForSave }, 'save-button']"
          @click="addProject"
        >
          Tambah data
        </button>
      </div>
    </div>
    <hr />
    <Loading v-if="isLoading" />
    <Error v-if="!isLoading && isError" />
    <div v-if="!isLoading && !isError" class="add-container">
      <div class="text-input">
        <label>
          <div class="text-title">
            Nama project/Perusahaan
            <span class="requiredTag">*</span>
          </div>
          <input
            type=""
            name=""
            placeholder="Masukkan nama project/perusahaan"
            v-model="projectInput.title"
          />
        </label>
        <label>
          <div class="text-title">
            Kategori perusahaan
            <span class="requiredTag">*</span>
          </div>
          <input
            type=""
            name=""
            placeholder="Masukkan kategori perusahaan"
            v-model="projectInput.category"
          />
        </label>
        <!-- <label>
          <div class="text-title">
            Link website
            <span class="requiredTag">*</span>
          </div>
          <input type="" name="" value="" placeholder="Masukkan link website" />
        </label> -->
        <label>
          <div class="text-title">Link Embed Map
            <span
              ><a
                href="https://www.embed-map.com/"
                target="_blank"
                rel="noopener noreferrer"
                >Ambil src dari iframe generated HTML</a
              ></span
            >
          </div>
          <input
            type=""
            name=""
            v-model="projectInput.map"
            placeholder="Masukkan link embed map"
          />
        </label>
        <label>
          <div class="text-title">
            Deskripsi project
            <span class="requiredTag">*</span>
          </div>
          <textarea
            cols="30"
            rows="3"
            placeholder="Masukkan deskripsi"
            v-model="projectInput.description"
          ></textarea>
        </label>
        <label>
          <div class="text-title">
            Status pengerjaan
            <span class="requiredTag">*</span>
          </div>
          <div class="option-container prevent-select">
            <div
              :class="[
                { 'option-box-active': projectInput.isFinished == 0 },
                'option-box',
              ]"
              @click="projectInput.isFinished = 0"
            >
              <h1>Dalam pengerjaan</h1>
            </div>
            <div
              :class="[
                { 'option-box-active': projectInput.isFinished == 1 },
                'option-box',
              ]"
              @click="projectInput.isFinished = 1"
            >
              <h1>Selesai</h1>
            </div>
          </div>
        </label>
      </div>
      <div class="image-input">
        <div class="cover-input">
          <div class="logo-input">
            <div class="logo-title">
              <div class="image-title">Logo perusahaan</div>
            </div>
            <div v-if="!base64logo" class="image-container prevent-select">
              <img
                src="@/assets/admin/icons/edit/image-big.svg"
                alt=""
                draggable="false"
              />
            </div>
            <img
              v-if="base64logo"
              class="image-container"
              :src="base64logo"
              alt=""
            />
            <input
              type="file"
              id="logo"
              name="logo"
              @change="previewImageLogo"
              class="hide"
            />
            <div class="file-selection-label prevent-select">
              <label for="logo" class="big-file-select"
                >Pilih dari file manager</label
              >
            </div>
          </div>
          <div class="image-title">
            Gambar project
            <span>Gambar minimal tiga dan maximal sepuluh.</span>
          </div>
          <input
            type="file"
            id="cover"
            name="cover"
            value=""
            class="hide"
            @change="previewImage"
            multiple
          />
          <div class="file-selection-label prevent-select">
            <label for="cover" class="big-file-select"
              >Pilih dari file manager</label
            >
          </div>
          <div v-if="!isFilesNotInputted" class="file-list">
            <div
              v-for="(fileName, index) in fileNames"
              :key="index"
              class="snapshots-container"
            >
              <img class="image-container" :src="base64[index]" alt="" />
              <h1>• {{ fileName }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons" v-if="!isLoading">
      <div class="back-button">
        <button @click="backToList">Kembali</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/api/Api";
import { fileToBase64 } from "@/helpers";
import Loading from "../Loading.vue";
import Error from "../Error.vue";

export default {
  data() {
    return {
      files: [],
      fileNames: [],
      modalOpened: false,
      ModalType: null,
      isLoading: null,
      isError: null,
      base64logo: null,
      base64: [],
      projectInput: {
        title: "",
        category: "",
        description: "",
        isFinished: null,
        // cover: null,
        logo: null,
        map: null,
        // galery: [],
      },
    };
  },
  components: {
    Loading,
    Error,
  },
  methods: {
    async previewImageLogo(e) {
      let file = e.target.files[0];
      if (!file) return;
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert(
          "Please select a valid image file.\nValid image file type: jpeg, png."
        );
        event.target.value = "";
        return;
      }
      let promise = fileToBase64(file);
      promise.then((result) => {
        this.base64logo = result?.src;
        this.projectInput.logo = file;
      });
    },
    async previewImage(e) {
      let files = e.target.files;
      if (!files || files.length === 0) return;

      const allowedTypes = ["image/jpeg", "image/png"];
      this.fileNames = [];
      let validFiles = [];

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        if (allowedTypes.includes(file.type)) {
          validFiles.push(file);
          this.fileNames.push(file.name);
        } else {
          alert(
            "Please select a valid image file.\nValid image file types: jpeg, png."
          );
          this.fileNames = [];
          validFiles = [];
          event.target.value = "";
          return;
        }
      }
      if (files.length > 10)
        return (
          alert("Maximal 10 gambar."),
          (event.target.value = ""),
          (this.fileNames = []),
          (validFiles = [])
        );

      let promises = validFiles.map(fileToBase64);

      Promise.all(promises)
        .then((results) => {
          // Assuming this.base64 is an array to store the base64 data for each file.
          // Assuming this.projectInput is an array to store the corresponding files.
          results.forEach((result, index) => {
            this.base64[index] = result?.src;
            this.files[index] = validFiles[index];
          });
        })
        .catch((error) => {
          console.error("Error while converting files to base64:", error);
        });
    },
    backToList() {
      this.$root.emitter.emit("backToProject");
    },
    addNoImage() {
      const Token = localStorage.getItem("token");
      if (!this.valueChanged) return;
      this.isLoading = true;
      Api.post(
        `admin/project`,
        {
          title: this.projectInput.title,
          category: this.projectInput.category,
          desc: this.projectInput.description,
          is_finished: this.projectInput.isFinished,
          map: this.projectInput.map,
          logo: this.projectInput.logo,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Token}`,
          },
        }
      )
        .then(() => {
          this.backToList();
        })
        .catch((e) => {
          console.log(e);
          alert("Gagal menunggah project!");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addProject() {
      const Token = localStorage.getItem("token");
      if (!this.isAllowedForSave) return;
      this.isLoading = true;
      Api.post(
        `admin/project`,
        {
          title: this.projectInput.title,
          category: this.projectInput.category,
          desc: this.projectInput.description,
          is_finished: this.projectInput.isFinished,
          map: this.projectInput.map,
          logo: this.projectInput.logo,
          images: this.files,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Token}`,
          },
        }
      )
        .then(() => {
          this.backToList();
        })
        .catch((e) => {
          console.log(e);
          alert("Gagal menunggah project!");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    valueChanged() {
      return (
        this.projectInput.title != "" &&
        this.projectInput.category != "" &&
        this.projectInput.description != "" &&
        this.projectInput.isFinished != null
      );
    },
    isFilesNotInputted() {
      return this.fileNames?.length === 0;
    },
    isFilesLessThanThree() {
      return this.fileNames?.length < 3;
    },
    isAllowedForSave() {
      return (
        this.fileNames?.length > 2 &&
        this.projectInput.title != "" &&
        this.projectInput.category != "" &&
        this.projectInput.description != "" &&
        this.projectInput.isFinished != null
      );
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
}

.requiredTag {
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.project-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.project-container hr {
  border: solid #e1e1e1;
  margin: 0;
  border-width: 1px;
}

.project-container-header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.header-title {
  display: flex;
  align-items: center;
}

.header-buttons {
  display: flex;
  gap: 0.5rem;
}

.project-container h1 {
  font-size: 1.375rem;
  font-weight: 400;
  margin: 0;
  color: #3a3a3a;
}

.project-container-header button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.project-container-header button:hover {
  background-color: #f90011;
}

.project-container-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.add-container {
  display: flex;
  flex-wrap: wrap;
}

/* Text input */

.text-input {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  width: 52.5%;
  gap: 1rem;
}

.text-input label {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input input {
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  text-indent: 0.5rem;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input input:focus {
  outline: none;
}

.text-input textarea {
  padding: 0.5rem;
  font-family: "Poppins";
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  resize: none;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input textarea:focus {
  outline: none;
}

.text-title {
  display: flex;
  padding: 0 0.5rem;
}

.text-input label:nth-child(3) .text-title {
  justify-content: space-between;
}

.text-input label:nth-child(3) .text-title a {
  text-decoration: none;
}

.option-container {
  display: flex;
  gap: 0.5rem;
}

.option-box {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
}

.option-box:hover {
  background: #e9e9e9;
}

.option-box-active {
  background: #f90011;
  cursor: default;
}

.option-box-active:hover {
  background: #f90011 !important;
}

.option-box-active h1 {
  color: #ffffff !important;
}

.option-box .option-circle {
  width: 10px;
  height: 10px;
  border: #3a3a3a 1px solid;
  border-radius: 5px;
}

.option-box-active .option-circle {
  background: #ffffff;
}

.option-box h1 {
  font-size: 1rem;
  color: #3a3a3a;
}

.image-input {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 1rem;
}

.image-input .image-title {
  display: flex;
  font-size: 1.25rem;
  color: #989898;
  gap: 0.5rem;
}

.image-title span {
  display: flex;
  align-items: flex-end;
  font-size: 1rem;
}

.cover-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cover-input .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 240px;
  background: #d9d9d9;
}

.file-selection-label {
  padding: 0.75rem 0;
}

.big-file-select {
  font-size: 1rem;
  color: #ffffff;
  background: #ff2230;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.big-file-select:hover {
  background-color: #f90011;
}

.big-file-select:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.logo-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.logo-title {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.logo-title h1 {
  font-size: 1rem;
  color: #7a7a7a;
}

.logo-title span {
  color: #ff2230;
}

.logo-input .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 240px;
  background: #d9d9d9;
}

.snapshots-container .image-container {
  width: 240px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.snapshot-upload-button {
  display: flex;
}

.snapshot-upload-button label {
  background: #ff2230;
  color: #ffffff;
  width: 240px;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 5px;
  cursor: pointer;
}

.snapshot-upload-button label:hover {
  background-color: #f90011;
}

.snapshot-upload-button label:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.snapshot-item-container {
  display: flex;
  flex-wrap: wrap;
  width: 496px;
  gap: 1rem;
}

.snapshot-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 240px;
}

.snapshots-container {
  display: flex;
  flex-direction: column;
}

.file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 500px;
}

.file-list h1 {
  font-size: 20px;
  color: #3a3a3a;
}

.buttons {
  padding: 0 2rem 1rem 2rem;
}
.back-button button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.back-button button:hover {
  background-color: #f90011;
}

.back-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.disabled-button {
  background-color: #707070 !important;
  cursor: default !important;
}
</style>
