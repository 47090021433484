<template>
  <div class="reach-us-container">
    <div class="reach-us-paragraph">
      <h1>Dapatkan Penawaran</h1>
      <hr />
      <h2>
        PT Adhi Konsultan Utama melayani Anda kapan pun dan dimana pun Anda
        berada
      </h2>
    </div>
    <form @submit.prevent="sendEmail()" class="reach-us-form">
      <div class="row-input first-input">
        <label for="name">
          Nama Anda
          <div>
            <input
              type="text"
              name="name"
              id="name"
              v-model="name"
              @input="emptyCheck('name')"
            />
            <h1 v-if="error.name">{{ error.name }}</h1>
          </div>
        </label>
        <label for="phoneNumber">
          No HP
          <div>
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              v-model="phoneNumber"
              @input="numberOnly"
              maxlength="13"
            />
            <h1 v-if="error.phoneNumber">{{ error.phoneNumber }}</h1>
          </div>
        </label>
      </div>
      <div>
        <label for="email">
          Email Anda
          <div>
            <input
              type="email"
              name="email"
              id="email"
              v-model="email"
              @input="validateEmail()"
            />
            <h1 v-if="error.email">{{ error.email }}</h1>
          </div>
        </label>
      </div>
      <div>
        <label for="selectService">
          Jenis Penawaran
          <div>
            <select
              id="selectService"
              v-model="service"
              @change="removeWarning()"
            >
              <option value="kpp">
                &nbsp;&nbsp; Konsultan Perencanaan dan Pengawasan
              </option>
              <option value="pi">&nbsp;&nbsp;&nbsp;Pengurusan Izin</option>
              <option value="pu">&nbsp;&nbsp;&nbsp;Perdagangan Umum</option>
              <option value="jasa">&nbsp;&nbsp;&nbsp;Jasa</option>
              <option value="empty">&nbsp;&nbsp;&nbsp;Pilih Penawaran</option>
            </select>
            <h1 v-if="error.service">{{ error.service }}</h1>
          </div>
        </label>
      </div>
      <div>
        <label for="message">
          Pesan
          <div>
            <textarea
              rows="4"
              cols=""
              id="message"
              v-model="message"
              @input="emptyCheck('message')"
            ></textarea>
            <h1 v-if="error.message">{{ error.message }}</h1>
          </div>
        </label>
      </div>
      <div class="send-button-container">
        <button @click="isAllValid" class="button-send">
          {{ buttonText }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { Api } from "@/api/Api";

export default {
  data() {
    return {
      name: "",
      phoneNumber: "",
      email: "",
      service: "empty",
      message: "",
      isEmailValidated: "",
      isAllValidated: false,
      error: {
        name: "",
        phoneNumber: "",
        email: "",
        service: "",
        message: "",
      },
      allowSend: true,
      buttonText: "Kirim Pesan",
    };
  },
  mounted() {
    this.$root.emitter.on("selectService", (a) => {
      if (!a) return;
      document.querySelector("input#name").focus();
      document.querySelector("#reach-us").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      this.removeWarning();
      if (a == "kpp") {
        this.service = "kpp";
      } else if (a == "pi") {
        this.service = "pi";
      } else if (a == "pu") {
        this.service = "pu";
      } else if (a == "jasa") {
        this.service = "jasa";
      } else return;
    });
  },
  methods: {
    validateEmail() {
      var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (regex.test(this.email)) {
        document.querySelector("#email").classList.remove("invalid");
        this.isEmailValidated = true;
        this.error.email = "";
      } else {
        document.querySelector("#email").classList.add("invalid");
        this.isEmailValidated = false;
        this.error.email = "Email tidak valid.";
      }
    },
    emptyCheck(e) {
      if (e == "name" && this.name === "") {
        document.querySelector(`#${e}`).classList.add("invalid");
        this.error.name = "Nama tidak boleh kosong.";
      } else if (e == "name" && this.name != "") {
        document.querySelector(`#${e}`).classList.remove("invalid");
        this.error.name = "";
      }

      if (e == "phoneNumber" && this.phoneNumber === "") {
        document.querySelector(`#${e}`).classList.add("invalid");
        this.error.phoneNumber = "Nomor telepon tidak boleh kosong.";
      } else if (e == "phoneNumber" && this.phoneNumber != "") {
        document.querySelector(`#${e}`).classList.remove("invalid");
        this.error.phoneNumber = "";
      }

      if (e == "message" && this.message == "") {
        document.querySelector(`#${e}`).classList.add("invalid");
        this.error.message = "Pesan tidak boleh kosong.";
      } else if (e == "message" && this.message != "") {
        document.querySelector(`#${e}`).classList.remove("invalid");
        this.error.message = "";
      }
    },
    isAllValid() {
      if (
        this.name != "" &&
        this.phoneNumber != "" &&
        this.isEmailValidated == true &&
        this.service != "empty" &&
        this.message != ""
      ) {
        this.isAllValidated = true;
        // console.log("valid");
      }
    },
    sendEmail() {
      // if (!this.isAllValidated) return;
      const sendButton = document.querySelector(".button-send");
      if (!this.allowSend) return;
      sendButton.classList.add("button-disabled");
      this.buttonText = "Mengirim";
      this.allowSend = false;
      Api.post(
        "send-mail",
        {
          bidder_name: this.name,
          no_hp: this.phoneNumber,
          email: this.email,
          offer: this.service,
          message: this.message,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then(() => {
          // console.log(res);
          sendButton.classList.add("button-success");
          this.buttonText = "Pesan Terkirim!";
          // console.log("success");
          this.name = "";
          this.phoneNumber = "";
          this.email = "";
          this.message = "";
          this.service = "empty";
          setTimeout(() => {
            sendButton.classList.remove("button-success");
            this.buttonText = "Kirim Pesan";
            this.allowSend = true;
          }, 5000);
        })
        .catch((e) => {
          this.buttonText = "Gagal Mengirim Pesan!";
          if (e.response.data.errors.bidder_name.length) {
            e.response.data.errors.bidder_name.forEach((nameError) => {
              document.querySelector(`#name`).classList.add("invalid");
              if (nameError == "The bidder name field is required.") {
                this.error.name = "Nama tidak boleh kosong.";
              }
            });
          }
          if (
            e.response.data.errors.no_hp[0] == "The no hp field is required."
          ) {
            document.querySelector(`#phoneNumber`).classList.add("invalid");
            this.error.phoneNumber = "Nomor telepon tidak boleh kosong.";
          }
          if (
            e.response.data.errors.email[0] == "The email field is required."
          ) {
            document.querySelector(`#email`).classList.add("invalid");
            this.error.email = "Email tidak boleh kosong.";
          }
          if (
            e.response.data.errors.offer[0] ==
              "offer must be filled with kpp/pi/pu/jasa" ||
            this.service == "empty"
          ) {
            document.querySelector(`#selectService`).classList.add("invalid");
            this.error.service = "Pilih salah satu jasa.";
          }
          if (
            e.response.data.errors.message[0] ==
            "The message field is required."
          ) {
            document.querySelector(`#message`).classList.add("invalid");
            this.error.message = "Pesan tidak boleh kosong.";
          }
          setTimeout(() => {
            sendButton.classList.remove("button-success");
            this.buttonText = "Kirim Pesan";
            this.allowSend = true;
          }, 5000);
        })
        .finally(() => {
          sendButton.classList.remove("button-disabled");
          // this.buttonText = "Kirim Pesan";
          // this.allowSend = true;
        });
    },
    numberOnly() {
      this.emptyCheck("phoneNumber");
      const value = event.target.value;

      const digitsOnly = value.replace(/\D/g, "");

      event.target.value = digitsOnly;
    },
    removeWarning() {
      this.error.service = "";
      document.querySelector(`#selectService`).classList.remove("invalid");
    },
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.reach-us-container {
  display: flex;
  justify-content: space-evenly;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.reach-us-paragraph {
  width: 30%;
  padding-left: 3rem;
  padding-top: 3rem;
  background-image: url("@/assets/user/images/home/reach-us/mail.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.reach-us-paragraph h1 {
  font-weight: 600;
  font-size: 2.5rem;
  margin: 0;
  padding-bottom: 0.25rem;
  color: #001858;
}

.reach-us-paragraph hr {
  border-style: solid;
  border-width: 0.15rem;
  border-color: #e4000f;
  width: 43%;
  margin: 0;
}

.reach-us-paragraph h2 {
  font-weight: 400;
  font-size: 2rem;
  letter-spacing: 0.05em;
  padding-top: 1.5rem;
  color: #001858;
  margin: 0;
}

.reach-us-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 30%;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 3rem 4rem 3rem 4rem;
}

.reach-us-form h1 {
  font-size: 0.9rem;
  margin: 0;
  color: red;
  text-align: left;
}

.row-input {
  display: flex;
  justify-content: space-between;
}

.reach-us-form label {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 1.5rem;
  color: #252525;
  gap: 1rem;
}

.row-input input {
  width: 16rem;
}

.reach-us-form input,
.reach-us-form select {
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  height: 3.75rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 1rem;
  -webkit-transition: border-color 0.2s ease-in-out;
  -moz-transition: border-color 0.2s ease-in-out;
  -o-transition: border-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;
  outline: none;
}

#email,
#selectService {
  box-sizing: border-box;
  width: 100%;
  height: 4.125rem;
}

#selectService[value="empty"] {
  color: #333333cc !important;
}

#message {
  box-sizing: border-box;
  width: 100%;
}

.reach-us-form input:focus,
.reach-us-form select:focus {
  border-color: #1ba8f0;
}

.reach-us-form select {
  text-indent: 0;
  background-image: url("@/assets/user/icons/home/reach-us/arrow-down.svg");
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 96%;
}

.reach-us-form select option:last-child {
  display: none;
}

.reach-us-form textarea {
  font-family: "Poppins";
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  font-size: 1.5rem;
  font-weight: 400;
  color: #000000;
  padding: 0.5rem 1rem 1rem 1rem;
  resize: none;
  outline: none;
  -webkit-transition: border-color 0.2s ease-in-out;
  -moz-transition: border-color 0.2s ease-in-out;
  -o-transition: border-color 0.2s ease-in-out;
  transition: border-color 0.2s ease-in-out;
}

.reach-us-form textarea:focus {
  border-color: #1ba8f0;
}

.reach-us-form button {
  border-radius: 25px;
  background-color: #ff2230;
  border-style: none;
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: 600;
  padding: 1.5rem 9rem 1.5rem 9rem;
  cursor: pointer;
  -webkit-transition: background-color 0.15s ease-out;
  -moz-transition: background-color 0.15s ease-out;
  -o-transition: background-color 0.15s ease-out;
  transition: background-color 0.15s ease-out;
  cursor: pointer;
}

.reach-us-form button:hover {
  background-color: #f90011;
}

.reach-us-form button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.reach-us-form div:last-child {
  text-align: center;
}

.invalid {
  border-color: red !important;
}

.button-success {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #3cda2e !important;
  cursor: default !important;
}

.button-disabled {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #707070 !important;
  cursor: default !important;
}

@media only screen and (max-width: 1473px) {
  .reach-us-container {
    padding: 2rem 0;
  }
  .reach-us-paragraph {
  }
  .reach-us-paragraph h1 {
    font-size: 1.75rem;
  }
  .reach-us-paragraph h2 {
    padding-top: 0.75rem;
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 1850px) {
  .row-input input {
    width: 15rem;
  }
}

@media only screen and (max-width: 1720px) {
  .row-input input {
    width: 14rem;
  }
}
@media only screen and (max-width: 1600px) {
  .row-input input {
    width: 13.5rem;
  }
}

@media only screen and (max-width: 1550px) {
  .row-input input {
    width: 13.2rem;
    height: 2.5rem;
    font-size: 1.25rem;
  }
  #email,
  #selectService {
    height: 2.875rem;
    font-size: 1.25rem;
  }
  #message {
    font-size: 1.25rem;
  }
  .reach-us-form button {
    font-size: 1.25rem;
    padding: 1.5rem 0;
    width: 100%;
  }
}

@media only screen and (max-width: 1510px) {
  .row-input input {
    width: 12rem;
  }
}

@media only screen and (max-width: 1450px) {
  .row-input input {
    width: 11.5rem;
  }
}

@media only screen and (max-width: 1440px) {
}

@media only screen and (max-width: 1320px) {
  .first-input {
  }
  .reach-us-container {
    padding: 3rem 0;
    flex-direction: row;
    /* align-items: center; */
    gap: 3rem;
    justify-content: flex-start;
  }
  .reach-us-paragraph {
    padding: 0 0 4rem 0;
    /* background: none; */
    background-size: 80%;
    width: 80%;
  }
  .reach-us-paragraph h1 {
    font-size: 1.4rem;
  }
  .reach-us-paragraph h2 {
    font-size: 1.1rem;
    max-width: 30rem;
    /* letter-spacing: 0.1em; */
  }

  /* reach us form  */
  .reach-us-form {
    padding: 2rem 2rem;
    width: auto;
  }
  .reach-us-form label {
    width: auto;
    font-size: 1rem;
  }
  .row-input input {
    width: 7.5rem;
    height: 2rem;
    font-size: 1rem;
    /* border-width: 1px; */
  }
  #email,
  #selectService {
    width: 17rem;
    height: 2.5rem;
    font-size: 1rem;
  }

  #message {
    width: 17rem;
    font-size: 1rem;
  }
  .reach-us-form button {
    padding: 1rem 0;
    font-size: 1.25rem;
    width: 100%;
  }
  .send-button-container {
    width: auto;
  }
}

@media only screen and (max-width: 930px) {
  .reach-us-container {
    padding: 3rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .reach-us-paragraph {
    padding: 0 0 0 0;
    background: none;
    width: 80%;
  }
  .reach-us-paragraph h1 {
    font-size: 1.4rem;
  }
  .reach-us-paragraph h2 {
    font-size: 1.1rem;
    /* letter-spacing: 0.1em; */
  }

  /* reach us form  */
  .reach-us-form {
    padding: 2rem 2rem;
    width: auto;
  }
  .reach-us-form label {
    width: auto;
    font-size: 1rem;
  }
  .row-input input {
    width: 7.5rem;
    height: 2rem;
    font-size: 1rem;
    /* border-width: 1px; */
  }
  #email,
  #selectService {
    width: 17rem;
    height: 2.5rem;
    font-size: 1rem;
  }

  #message {
    width: 17rem;
    font-size: 1rem;
  }
  .reach-us-form button {
    padding: 1rem 2rem;
    font-size: 1.25rem;
  }
}
@media only screen and (max-width: 400px) {
  .reach-us-container {
    padding: 3rem 0;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .reach-us-paragraph {
    padding: 0 0 4rem 0;
    background: none;
    width: 80%;
  }
  .reach-us-paragraph h1 {
    font-size: 1.4rem;
  }
  .reach-us-paragraph h2 {
    font-size: 1.1rem;
    /* letter-spacing: 0.1em; */
  }

  /* reach us form  */
  .reach-us-form {
    padding: 2rem 2rem;
    width: auto;
  }
  .reach-us-form label {
    width: auto;
    font-size: 1rem;
  }
  .row-input input {
    width: 7.5rem;
    height: 2rem;
    font-size: 1rem;
    /* border-width: 1px; */
  }
  #email,
  #selectService {
    width: 17rem;
    height: 2.5rem;
    font-size: 1rem;
  }

  #message {
    width: 17rem;
    font-size: 1rem;
  }
  .reach-us-form button {
    padding: 1rem 2rem;
    font-size: 1.25rem;
  }
}
</style>
