<template>
  <!-- <div class="finally-wrapper" > -->
  <!-- <h6>{{ loadingText }}</h6> -->
  <Loading v-if="showLoading" />
  <!-- </div> -->
  <div v-else>
    <div class="page-changer">
      <div
        class="page-button"
        :class="{ 'page-inactive': currentPage == meta.from }"
        @click="previousPage"
      >
        Prev
      </div>
      <input
        type="number"
        v-model="currentPage"
        @input="numberOnly"
        @change="goToPage(this.currentPage)"
      />
      <div
        class="page-button"
        :class="{ 'page-inactive': currentPage == meta.last_page }"
        @click="nextPage"
      >
        Next
      </div>
    </div>
    <div class="card-wrapper">
      <router-link
        v-for="(item, index) in companies"
        :key="index"
        :to="`/product/${item.id}`"
        class="card-item"
        @click="toTop()"
      >
        <div class="image">
          <img class="thumbnail" :src="item.cover" alt="" />
          <img
            class="status hide"
            :src="`/user/assets/images/product/${item.is_finished}.svg`"
            alt=""
            draggable="false"
          />
        </div>
        <h1>{{ item.title }}</h1>
        <h2>{{ item.category }}</h2>
      </router-link>
    </div>
    <div class="page-changer">
      <div
        class="page-button"
        :class="{ 'page-inactive': currentPage == meta.from }"
        @click="previousPage"
      >
        Prev
      </div>
      <input
        type="number"
        v-model="currentPage"
        @input="numberOnly"
        @change="goToPage(this.currentPage)"
      />
      <div
        class="page-button"
        :class="{ 'page-inactive': currentPage == meta.last_page }"
        @click="nextPage"
      >
        Next
      </div>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api.js";
import Loading from "@/components/admin/Loading.vue";

export default {
  data() {
    return {
      companies: [],
      loadingText: "Memuat",
      showLoading: null,
      page: null,
      currentPage: 1,
      projects: [],
      meta: [],
      toMoveId: null,
    };
  },
  mounted() {
    this.fetchProjects();
  },
  methods: {
    toTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    fetchProjects() {
      if (this.currentPage < 1 || this.currentPage > this.meta.last_page)
        return;
      this.showLoading = true;
      Api.get(`/projects?page=${this.currentPage}`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => {
          this.companies = res.data.data;
          this.meta = res.data.meta;
          this.projects = res.data.data;
          this.page = this.meta.last_page;

          for (let i = 0; i < this.companies.length; i++) {
            if (this.companies[i].cover == null) {
              this.companies[i].cover =
                "/user/assets/images/project/noimage.png";
            } else {
              this.companies[
                i
              ].cover = `${baseURL}images/${this.companies[i].cover}`;
            }
            if (this.companies[i].title == null) {
              this.companies[i].title = "???";
            }
            if (this.companies[i].is_finished == 1) {
              this.companies[i].is_finished = "finished";
            } else if (this.companies[i].is_finished == 0) {
              this.companies[i].is_finished = "ongoing";
            }
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            this.loadingText = "Gagal memuat";
          } else {
            console.log(error.message);
          }
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    numberOnly() {
      const value = event.target.value;

      const digitsOnly = value.replace(/\D/g, "");

      event.target.value = digitsOnly;
    },
    previousPage() {
      if (this.currentPage < 1 || this.currentPage > this.meta.last_page)
        return;
      if (this.currentPage == this.meta.from) return;
      this.currentPage--;
      this.fetchProjects();
    },
    nextPage() {
      if (this.currentPage < 1 || this.currentPage > this.meta.last_page)
        return;
      if (this.currentPage == this.meta.last_page) return;
      this.currentPage++;
      this.fetchProjects();
    },
    goToPage(e) {
      if (e > this.meta.last_page) {
        this.currentPage = this.meta.last_page;
      } else if (e < 1) {
        this.currentPage = 1;
      }
      this.fetchProjects();
    },
  },
  components: {
    Loading,
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Filter */
.filter {
  display: flex;
  padding: 1rem 0 4rem 0;
  justify-content: center;
  gap: 6rem;
}

.filter select {
  padding-right: 6rem;
  padding-bottom: 0.25rem;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  border-style: none;
  background-color: #f8f8ff;
  border-bottom: 2px solid #252525;
  cursor: pointer;
  outline: none;
}

.thumbnail {
  width: 400px;
  height: 257px;
  vertical-align: bottom;
}

#status option:last-child,
#type option:last-child {
  display: none;
}

/* Page Switcher */
.page-changer {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  gap: 0.5rem;
}

.page-changer:nth-of-type(1) {
  padding: 8rem 0 1rem;
}

.page-changer:last-of-type {
  padding: 0 0 4rem;
}

.page-changer input {
  width: 3rem;
  text-align: center;
  font-size: 1.25rem;
}

.page-button {
  padding: 0.5rem 1rem;
  background: #bb000c;
  color: #f8f8ff;
  border-radius: 0.25rem;
  cursor: pointer;
  user-select: none;
}

.page-inactive {
  background: #646464;
  cursor: default;
}

/* Card */
.card-wrapper {
  padding: 1rem 5.5% 1rem 5.5%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* display: grid;
  grid-template-columns: repeat(4, 1fr); */
  gap: 2rem;
  /* width: 100%; */
}

.card-wrapper a,
a:visited {
  text-decoration: none;
}

.card-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}

.image {
  position: relative;
}

.card-item img.thumbnail {
  pointer-events: none;
  z-index: 0;
}

.card-item img.status {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translate(-0.15rem, -0.15rem);
}

.card-item h1 {
  margin: 0;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  padding-top: 1rem;
  color: #252525;
  width: 400px;
  text-align: center;
}

.card-item h2 {
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: rgba(37, 37, 37, 0.7);
}

.finally-wrapper {
  padding: 6rem 0;
}

.finally-wrapper h6 {
  font-size: 3rem;
  margin: 0;
  font-weight: 300;
}

@media only screen and (max-width: 1366px) {
  .card-wrapper {
    padding: 5rem 2.5% 4rem 2.5%;
  }
}

@media only screen and (max-width: 848px) {
  .card-wrapper {
    justify-content: center;
  }
}

@media only screen and (max-width: 400px) {
  .card-wrapper {
    justify-content: center;
    padding: 2rem 0;
  }
  .card-item img.thumbnail {
    width: 300px;
    height: 200px;
  }
  .card-item h1 {
    font-size: 1rem;
    width: 300px;
  }
  .card-item h2 {
    font-size: 1rem;
    font-size: 0.75rem;
  }
}
</style>
