const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          type: file.type,
          src: reader.result,
        });
      reader.onerror = (error) => reject(error);
    });
  };
  
  export default fileToBase64;