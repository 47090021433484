<template>
  <div class="notice prevent-select">
    <div>
      <img src="@/assets/user/icons/career/info.svg" alt="" draggable="false" />
    </div>
    <div>
      <h1>informasi penting</h1>
      <h1>
        isi formulir pendaftaran dengan benar sesuai dengan data diri Anda.
        formulir bertanda <span class="required">*</span> wajib diisi.<br />setelah
        Anda konfirmasi kirim maka Anda tidak dapat merubah data apapun
      </h1>
    </div>
  </div>
  <div class="main-container">
    <div class="form-header">
      <h1>Formulir pendaftaran</h1>
      <hr />
      <img
        src="@/assets/user/images/career/step-3.svg"
        alt=""
        draggable="false"
        class="prevent-select"
      />
    </div>
    <form class="career-form" @submit.prevent>
      <div>
        <h1>KURSUS, SEMINAR &#38; PELATIHAN</h1>
        <hr />
      </div>
      <div class="form-questions">
        <div class="horizontal-q">
          <h2>Nama Kursus</h2>
          <input type="text" v-model="experience.nama_kursus[0]" />
        </div>
        <div class="horizontal-q">
          <h2>Penyelenggara</h2>
          <input type="text" v-model="experience.penyelenggara[0]" />
        </div>
        <div class="horizontal-q">
          <h2>Tempat</h2>
          <input type="text" v-model="experience.tempat[0]" />
        </div>
        <div class="horizontal-q">
          <h2>Lama kursus &#40;Tahun&#41;</h2>
          <input
            type="text"
            @keypress="preventNonNumericInput"
            maxlength="2"
            v-model="experience.lama_kursus[0]"
          />
        </div>
        <div class="horizontal-q sertifikat">
          <input
            id="sertifikat-1"
            class="hide"
            type="file"
            @input="previewFileName($event, 0)"
            ref="fileInput_0"
          />
          <h2>File sertifikat</h2>
          <div>
            <label for="sertifikat-1">Unggah</label>
          </div>
          <h2 class="sertifikat-name">{{ selectedFileNames[0] }}</h2>
        </div>
        <hr />
        <div class="horizontal-q">
          <h2>Nama Kursus</h2>
          <input type="text" v-model="experience2.nama_kursus[0]" />
        </div>
        <div class="horizontal-q">
          <h2>Penyelenggara</h2>
          <input type="text" v-model="experience2.penyelenggara[0]" />
        </div>
        <div class="horizontal-q">
          <h2>Tempat</h2>
          <input type="text" v-model="experience2.tempat[0]" />
        </div>
        <div class="horizontal-q">
          <h2>Lama kursus &#40;Tahun&#41;</h2>
          <input
            type="text"
            @keypress="preventNonNumericInput"
            maxlength="2"
            v-model="experience2.lama_kursus[0]"
          />
        </div>
        <div class="horizontal-q sertifikat">
          <input
            id="sertifikat-2"
            class="hide"
            type="file"
            @input="previewFileName($event, 1)"
            ref="fileInput_1"
          />
          <h2>File sertifikat</h2>
          <div>
            <label for="sertifikat-2">Unggah</label>
          </div>
          <h2 class="sertifikat-name">{{ selectedFileNames[1] }}</h2>
        </div>
      </div>
    </form>
    <div class="next-button prevent-select">
      <button class="hide">Sebelumnya</button>
      <button @click="validateCheck">Selanjutnya</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedFileNames: {},
      file: {
        seminar_1: null,
        seminar_2: null,
      },
      experience: {
        nama_kursus: [],
        penyelenggara: [],
        lama_kursus: [],
        tempat: [],
      },
      experience2: {
        nama_kursus: [],
        penyelenggara: [],
        lama_kursus: [],
        tempat: [],
      },
    };
  },
  methods: {
    previewFileName(event, key) {
      const fileInput = this.$refs[`fileInput_${key}`];
      const file = fileInput.files[0];
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (!allowedTypes.includes(file.type)) {
        alert(
          "Please select a valid file type.\nValid image file type: jpeg, png, and pdf."
        );
        file.value = "";
        // this.$refs[`fileInput_${key}`] = null;
        return;
      }
      if (file && key == 0) {
        this.file.seminar_1 = file;
      }
      if (file && key == 1) {
        this.file.seminar_2 = file;
      }
      if (file) {
        this.selectedFileNames[key] = file.name;
      } else {
        this.selectedFileNames[key] = null;
      }
    },
    preventNonNumericInput(event) {
      const allowedKeys = /[0-9]/;
      if (!allowedKeys.test(event.key)) {
        event.preventDefault();
      }
    },
    selectJob(selectedCareer) {
      this.$root.emitter.emit("selectJob", selectedCareer);
    },
    validateCheck() {
      // console.log(this.experience);
      // console.log(this.file);
      this.experience.nama_kursus = [ ...this.experience.nama_kursus, ...this.experience2.nama_kursus];
      this.experience.penyelenggara = [ ...this.experience.penyelenggara, ...this.experience2.penyelenggara];
      this.experience.lama_kursus = [ ...this.experience.lama_kursus, ...this.experience2.lama_kursus];
      this.experience.tempat = [ ...this.experience.tempat, ...this.experience2.tempat];
      this.$root.emitter.emit("setExperience", this.experience);
      this.$root.emitter.emit("setPhoto", this.file);
      this.selectJob("careerFour");
    },
  },
};
</script>

<style scoped>
.notice {
  display: flex;
  background: #ffffff;
  padding: 2rem 2rem 2rem 2rem;
  gap: 1rem;
}

.notice div:last-of-type {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.notice h1 {
  margin: 0;
  font-weight: 500;
}

.notice h1:first-of-type {
  font-size: 2rem;
  color: #ff0010;
}

.notice h1:last-of-type {
  font-size: 1.85rem;
  color: #424242;
}

.main-container {
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.form-header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
}

.form-header h1 {
  margin: 0;
  font-weight: 500;
  font-size: 2rem;
  padding: 2rem 0 0 2rem;
}

.form-header hr {
  width: 100%;
  border-style: solid;
  color: #bcbcbc;
}

.form-header img {
  text-align: center;
  vertical-align: middle;
  width: 95%;
  padding-left: 2.5%;
  pointer-events: none;
}

.career-form {
  display: flex;
  flex-direction: column;
  padding: 1rem 4rem 6rem 4rem;
  gap: 1.5rem;
}

.career-form h1 {
  margin: 0;
  padding: 0 0 1rem 2rem;
  font-size: 2rem;
  color: #000000;
}

.career-form hr {
  width: 100%;
  border: 1px solid #bcbcbc;
  box-sizing: border-box;
}

.form-questions {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.form-questions .horizontal-q {
  display: flex;
  align-items: center;
}

.horizontal-q h2:first-of-type {
  width: 25%;
  font-weight: 400;
  font-size: 1.75rem;
}

.horizontal-q input[type="text"] {
  width: 75%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

.horizontal-q input[type="number"] {
  width: 75%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
  -moz-appearance: textfield;
}

.horizontal-q input[type="email"] {
  width: 75%;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.horizontal-q label {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 1.75rem;
}
.sertifikat label {
  font-weight: 500;
  font-size: 1.25rem;
  color: #ffffff;
  background: #ff2230;
  border-radius: 5px;
  border-style: none;
  padding: 0.6rem 1rem;
  cursor: pointer;
  width: 5rem;
}
.sertifikat label:hover {
  background-color: #f90011;
}

.sertifikat label:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
.sertifikat-name {
  padding: 0 0 0 1rem;
  font-size: 1.25rem;
  font-weight: 400;
}
input[type="radio"] {
  box-sizing: border-box;
  margin: 0;
  background-color: #fff;
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: #ff2230;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #000000;
  border-radius: 50%;
  transform: translateY(0.25rem);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="radio"]:checked {
  border: 0.1em solid #ff2230;
  cursor: default;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #ff2230;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.education-radio-input {
  display: flex;
  gap: 6rem;
  height: 3.5rem;
  align-items: center;
}

.enter-leave-q {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1.75rem;
}

.enter-leave-q label {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 47%;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
  row-gap: 1.25rem;
}

.enter-leave-q input {
  box-sizing: border-box;
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 1rem;
  font-size: 1.5rem;
  color: #3a3a3a;
}

.next-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 4rem 4rem 0;
  gap: 1.25rem;
}

.next-button button:first-of-type {
  font-weight: 500;
  font-size: 1.75rem;
  color: #ffffff;
  background: #1d1d1d;
  border-radius: 5px;
  border-style: none;
  padding: 1rem 1rem;
  cursor: pointer;
}

.next-button button:first-of-type:hover {
  background-color: #1e1e1e;
}

.next-button button:first-of-type:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #1f1f1f;
}

.next-button button:last-of-type {
  font-weight: 500;
  font-size: 1.75rem;
  color: #ffffff;
  background: #ff2230;
  border-radius: 5px;
  border-style: none;
  padding: 1rem 1rem;
  cursor: pointer;
}

.next-button button:last-of-type:hover {
  background-color: #f90011;
}

.next-button button:last-of-type:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
@media only screen and (max-width: 1512px) {
  .notice {
    gap: 0.75rem;
  }
  .notice img {
    width: 2rem;
  }
  .notice h1:first-of-type {
    font-size: 1.5rem;
  }
  .notice h1:last-of-type {
    font-size: 1.25rem;
  }
  .notice div:last-of-type {
    gap: 0.5rem;
  }
  .career-form h1 {
    font-size: 1.75rem;
  }
  .horizontal-q h2:first-of-type,
  .textarea-q h2 {
    font-size: 1.5rem;
  }
  .horizontal-q input[type="text"],
  .horizontal-q input[type="email"],
  .horizontal-q input[type="number"] {
    height: 3rem;
    font-size: 1.25rem;
  }
  .sertifikat label {
    font-size: 1rem;
    width: 4rem;
  }
  .sertifikat-name {
    font-size: 1rem;
  }
  .next-button button:last-of-type {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 400px) {
  h2 {
    margin: 0;
  }
  .notice {
    padding: 1rem;
  }
  .notice img {
    width: 1.75rem;
  }
  .notice h1:first-of-type {
    font-size: 1rem;
  }
  .notice h1:last-of-type {
    font-size: 0.75rem;
  }
  .notice div:last-of-type {
    gap: 0.5rem;
  }
  .form-header {
    gap: 1rem;
    overflow: auto;
  }
  .form-header h1 {
    padding: 1rem;
    padding-bottom: 0;
    font-size: 1.25rem;
  }
  .form-header img {
    width: 30rem;
  }
  .career-form {
    padding: 1rem 1rem;
  }
  .career-form h1 {
    padding: 0 0 0.25rem 1rem;
    font-size: 1rem;
  }
  .form-questions {
    padding: 0 0.5rem;
  }
  .form-questions h2 {
    font-size: 1rem;
  }
  .form-questions .horizontal-q {
    flex-direction: column;
    align-items: flex-start;
  }
  .horizontal-q h2:first-of-type {
    font-size: 1rem;
    width: auto;
  }
  .horizontal-q input[type="text"],
  .horizontal-q input[type="email"],
  .horizontal-q input[type="number"] {
    width: 100%;
    height: 2rem;
    box-sizing: border-box;
    font-size: 1rem;
    text-indent: 0.5rem;
  }
  .sertifikat label {
    font-size: 1rem;
    width: 4rem;
  }
  .sertifikat-name {
    padding: 0;
    font-size: 0.75rem !important;
  }
  .education-radio-input {
    row-gap: 1rem;
    height: auto;
    flex-wrap: wrap;
  }
  .next-button button:first-of-type,
  .next-button button:last-of-type {
    font-size: 1rem;
    padding: 0.75rem;
  }
  .next-button {
    padding: 1rem 1rem;
    gap: 0;
    /* justify-content: space-between; */
    justify-content: right;
  }
}
</style>
