<template>
  <div class="commissioners">
    <div class="divider-header">
      <div class="commissioners-header">
        <h1>Dewan Komisaris</h1>
        <div
          class="add-button"
          v-if="!loading.commissioner && !error.commissioner"
        >
          <button
            @click="this.$root.emitter.emit('openAdd', 'addCommissioner')"
          >
            Tambah data
          </button>
        </div>
      </div>
      <hr />
    </div>
    <div class="commissioner-card-container">
      <div
        class="commissioner-loading"
        v-if="loading.commissioner && !error.commissioner"
      >
        <Loading />
      </div>
      <div
        class="commissioner-loading"
        v-if="!loading.commissioner && error.commissioner"
      >
        <Error />
      </div>
      <div
        class="commissioner-card"
        v-for="commissioner in commissioners"
        :key="commissioner.id"
      >
        <img :src="commissioner.pp" alt="" draggable="false" />
        <div class="card-text">
          <h1>{{ commissioner.name }}</h1>
          <h2>{{ commissioner.as }}</h2>
        </div>
        <div class="edit-button">
          <button @click="openEdit('editCommissioner', commissioner.id)">
            Edit
          </button>
        </div>
        <div class="delete-button">
          <button
            @click="
              openModal('delete'),
                this.$root.emitter.emit('setDeleteType', 'mp'),
                this.$root.emitter.emit('setDeleteId', commissioner.id)
            "
          >
            Hapus
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="directors">
    <div class="divider-header">
      <div class="directors-header">
        <h1>Dewan Direksi</h1>
        <div class="add-button" v-if="!loading.director && !error.director">
          <button @click="this.$root.emitter.emit('openAdd', 'addDirector')">
            Tambah data
          </button>
        </div>
      </div>
      <hr />
    </div>
    <div class="director-card-container">
      <div class="director-loading" v-if="loading.director && !error.director">
        <Loading />
      </div>
      <div class="director-loading" v-if="!loading.director && error.director">
        <Error />
      </div>
      <div
        class="director-card"
        v-for="director in directors"
        :key="director.id"
      >
        <img :src="director.pp" alt="" draggable="false" />

        <div class="card-text">
          <h1>{{ director.name }}</h1>
          <h2>{{ director.as }}</h2>
        </div>
        <div class="edit-button">
          <button @click="openEdit('editDirector', director.id)">Edit</button>
        </div>
        <div class="delete-button">
          <button
            @click="
              openModal('delete'),
                this.$root.emitter.emit('setDeleteType', 'mp'),
                this.$root.emitter.emit('setDeleteId', director.id)
            "
          >
            Hapus
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="our-team">
    <div class="divider-header">
      <div class="our-teams-header">
        <h1>Tim Kami</h1>
        <div class="add-button" v-if="!loading.ourTeam && !error.ourTeam">
          <button @click="this.$root.emitter.emit('openAdd', 'addOurTeam')">
            Tambah data
          </button>
        </div>
      </div>
      <hr />
    </div>
    <div class="our-team-card-container">
      <div class="our-team-loading" v-if="loading.ourTeam && !error.ourTeam">
        <Loading />
      </div>
      <div class="our-team-loading" v-if="!loading.ourTeam && error.ourTeam">
        <Error />
      </div>
      <div class="our-team-card" v-for="team in ourTeam" :key="team.id">
        <div class="gallery-height">
          <img
            class="gallery-image"
            :src="team.value"
            alt=""
            draggable="false"
          />
        </div>
        <div class="card-text">
          <h1>{{ team.desc }}</h1>
        </div>
        <div class="edit-button">
          <button @click="openEdit('editOurTeam', team.id)">Edit</button>
        </div>
        <div class="delete-button">
          <button
            @click="
              openModal('delete'),
                this.$root.emitter.emit('setDeleteType', 'gallery'),
                this.$root.emitter.emit('setDeleteId', team.id)
            "
          >
            Hapus
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="galleries">
    <div class="divider-header">
      <div class="galleries-header">
        <h1>Galeri</h1>
        <div class="add-button" v-if="!loading.galleries && !error.galleries">
          <button @click="this.$root.emitter.emit('openAdd', 'addGallery')">
            Tambah data
          </button>
        </div>
      </div>
      <hr />
    </div>
    <div class="galleries-card-container">
      <div
        class="galleries-loading"
        v-if="loading.galleries && !error.galleries"
      >
        <Loading />
      </div>
      <div
        class="galleries-loading"
        v-if="!loading.galleries && error.galleries"
      >
        <Error />
      </div>
      <div
        class="galleries-card"
        v-for="gallery in galleries"
        :key="gallery.id"
      >
        <div class="gallery-height">
          <img
            class="gallery-image"
            :src="gallery.value"
            alt=""
            draggable="false"
          />
        </div>
        <div class="card-text">
          <h1>{{ gallery.desc }}</h1>
        </div>
        <div class="edit-button">
          <button @click="openEdit('editGallery', gallery.id)">Edit</button>
        </div>
        <div class="delete-button">
          <button
            @click="
              openModal('delete'),
                this.$root.emitter.emit('setDeleteType', 'gallery'),
                this.$root.emitter.emit('setDeleteId', gallery.id)
            "
          >
            Hapus
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api";
import Loading from "../../Loading.vue";
import Error from "../../Error.vue";

export default {
  data() {
    return {
      modalOpened: false,
      loading: {
        commissioner: null,
        director: null,
        ourTeam: null,
        galleries: null,
      },
      error: {
        commissioner: null,
        director: null,
        ourTeam: null,
        galleries: null,
      },
      rawMp: [],
      rawGalleries: [],
      commissioners: [],
      directors: [],
      ourTeam: [],
      galleries: [],
    };
  },
  components: {
    Loading,
    Error,
  },
  mounted() {
    this.$root.emitter.on("fetchMp", () => {
      (this.commissioners = []), (this.directors = []), this.getMp();
    });
    this.$root.emitter.on("fetchGallery", () => {
      (this.ourTeam = []), (this.galleries = []), this.getGallery();
    });
    this.$root.emitter.on("allowModalOpen", () => {
      this.modalOpened = false;
    });
    this.getMp();
    this.getGallery();
  },
  methods: {
    openModal(a) {
      if (this.modalOpened) return;
      this.modalOpened = true;
      this.$root.emitter.emit("openModal", a);
    },
    openEdit(e, a) {
      this.$root.emitter.emit("openEdit", e);
      setTimeout(() => {
        this.$root.emitter.emit("setItemId", a);
      }, 1);
    },
    getMp() {
      const Token = localStorage.getItem("token");
      this.error.commissioner = false;
      this.error.director = false;
      this.loading.commissioner = true;
      this.loading.director = true;
      Api.get(`admin/mp`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          this.rawMp = res.data.data;
          if (this.rawMp.length) {
            for (let i = 0; i < this.rawMp.length; i++) {
              this.rawMp[i].pp = `${baseURL}images/${this.rawMp[i].pp}`;
            }
          }
          for (let i = 0; i < this.rawMp.length; i++) {
            if (this.rawMp[i].category == "komisaris") {
              this.commissioners.push(this.rawMp[i]);
            }
            if (this.rawMp[i].category == "direksi") {
              this.directors.push(this.rawMp[i]);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.error.commissioner = true;
          this.error.director = true;
        })
        .finally(() => {
          this.loading.commissioner = false;
          this.loading.director = false;
        });
    },
    getGallery() {
      const Token = localStorage.getItem("token");
      this.error.ourTeam = false;
      this.error.galleries = false;
      this.loading.ourTeam = true;
      this.loading.galleries = true;
      Api.get(`admin/galeries`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          function truncateText(text, maxLength) {
            if (text.length <= maxLength) {
              return text;
            } else {
              return text.slice(0, maxLength) + "...";
            }
          }
          this.rawGalleries = res.data.data;
          if (this.rawGalleries.length) {
            for (let i = 0; i < this.rawGalleries.length; i++) {
              this.rawGalleries[
                i
              ].value = `${baseURL}images/${this.rawGalleries[i].value}`;
              this.rawGalleries[i].desc = truncateText(
                this.rawGalleries[i].desc,
                40
              );
            }
          }
          for (let i = 0; i < this.rawGalleries.length; i++) {
            if (this.rawGalleries[i].key == "our-team") {
              this.ourTeam.push(this.rawGalleries[i]);
            }
            if (this.rawGalleries[i].key == "galeries") {
              this.galleries.push(this.rawGalleries[i]);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.error.ourTeam = true;
          this.error.galleries = true;
        })
        .finally(() => {
          this.loading.ourTeam = false;
          this.loading.galleries = false;
        });
    },
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6,
hr {
  margin: 0;
}

.galleries,
.our-team,
.commissioners,
.directors {
  width: 100%;
}

.galleries-header,
.our-teams-header,
.commissioners-header,
.directors-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.galleries-header h1,
.our-teams-header h1,
.commissioners-header h1,
.directors-header h1 {
  color: #3a3a3a;
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0 0 0 1rem;
}

.divider-header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 1rem 0 0 0;
  gap: 1rem;
  width: 100%;
}
.active-section {
  color: #ff2230 !important;
  cursor: default !important;
}

.inner-sections-header h1:nth-of-type(1) {
  width: 11.3125rem;
}

.inner-sections-header h1:nth-of-type(2) {
  width: 9rem;
}

.inner-sections-header h1:nth-of-type(3) {
  width: 9rem;
}

.inner-sections-header h1:nth-of-type(4) {
  width: 11.25rem;
}
.add-button {
  padding: 0 1rem 0 0;
}
.add-button button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.add-button button:hover {
  background-color: #f90011;
}

.add-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.galleries-card-container,
.our-team-card-container,
.commissioner-card-container,
.director-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 2rem 0;
}

.galleries-card,
.our-team-card,
.commissioner-card,
.director-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  max-width: 400px;
}
.galleries-card img,
.our-team-card img,
.commissioner-card img,
.director-card img {
  width: 400px;
  vertical-align: bottom;
  object-fit: contain;
  aspect-ratio: 3/4;
}
.galleries-loading,
.our-team-loading,
.commissioner-loading,
.director-loading {
  display: flex;
  justify-content: center;
  width: 100%;
}

.card-text {
  display: flex;
  flex-direction: column;
}
.card-text h1 {
  width: auto !important;
  font-size: 1.5rem;
  font-weight: 400;
  color: #252525;
  padding: 0;
  text-align: center;
}

.card-text h2 {
  color: rgba(37, 37, 37, 0.7);
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
}

.gallery-height {
  display: flex;
  align-items: center;
  height: 400px;
}
.gallery-image {
  vertical-align: middle;
  max-width: 100%;
  max-height: 400px;
  width: auto;
  height: auto;
}
.edit-button {
  width: 100%;
}

.edit-button button {
  width: 100%;
  background-color: #ff2230;
  color: #fff;
  border: none;
  padding: 1rem 0;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.edit-button button:hover {
  background-color: #f90011;
}

.edit-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.delete-button {
  width: 100%;
}

.delete-button button {
  width: 100%;
  background-color: #ffffff;
  color: #ff2230;
  padding: 1rem 0;
  border-radius: 5px;
  border: #ff2230 2px solid;
  font-size: 1rem;
  cursor: pointer;
}

.delete-button button:hover {
  background: #f0f0f0;
}

.delete-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}
</style>
