<template>
  <div class="testimony-container">
    <div class="testimony-container-header">
      <div class="header-title">
        <h1>Tambah Testimoni</h1>
      </div>
      <div class="prevent-select">
        <button
          :class="{ 'disabled-button': !valueChanged }"
          @click="uploadTestimony"
        >
          Simpan data
        </button>
      </div>
    </div>
    <hr />
    <Loading v-if="isLoading" />
    <div v-if="!isLoading" class="add-container">
      <div class="text-input">
        <label>
          <div class="text-title">
            Nama Testimoni
            <span class="requiredTag">*</span>
          </div>
          <input
            type=""
            name=""
            v-model="testimony.name"
            placeholder="Masukkan nama testimoni"
          />
        </label>
        <label>
          <div class="text-title">
            Status
            <span class="requiredTag">*</span>
          </div>
          <input
            type=""
            name=""
            v-model="testimony.as"
            placeholder="Masukkan status testimoni"
          />
        </label>
        <label>
          <div class="text-title">
            Komentar Testimoni
            <span class="requiredTag">*</span>
          </div>
          <textarea
            cols="30"
            rows="3"
            v-model="testimony.comment"
            placeholder="Masukkan komentar testimoni"
          ></textarea>
        </label>
      </div>
      <div class="image-input">
        <div class="logo-input">
          <div class="image-title">
            Foto Testimoni
          </div>
          <div class="image-container prevent-select">
            <img
              v-if="!base64"
              src="@/assets/admin/icons/edit/image-big.svg"
              alt=""
              draggable="false"
            />
            <img
              class="previewing-image"
              v-if="base64"
              :src="base64"
              alt=""
              draggable="false"
            />
          </div>
          <input
            type="file"
            id="cover"
            name="cover"
            @change="previewImage"
            class="hide"
          />
          <div class="file-selection-label prevent-select">
            <label for="cover" class="big-file-select"
              >Pilih dari file manager</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="buttons" v-if="!isLoading">
      <div class="back-button">
        <button @click="backToList">Kembali</button>
      </div>
    </div>
  </div>
</template>

<script>
import { fileToBase64 } from "@/helpers/index.js";
import { Api } from "@/api/Api";
import Loading from "../Loading.vue";

export default {
  data() {
    return {
      base64: null,
      modalOpened: false,
      ModalType: null,
      isLoading: null,
      testimony: {
        name: "",
        status: "",
        comment: "",
        photo: null,
      },
    };
  },
  components: {
    Loading,
  },
  methods: {
    backToList() {
      this.$root.emitter.emit("backToTestimony");
    },
    async previewImage(e) {
      let file = e.target.files[0];
      if (!file) return;
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert(
          "Please select a valid image file.\nValid image file type: jpeg, png."
        );
        event.target.value = "";
        return;
      }
      let promise = fileToBase64(file);
      promise.then((result) => {
        this.base64 = result?.src;
        this.testimony.photo = file;
      });
    },
    uploadTestimony() {
      const Token = localStorage.getItem("token");
      if (!this.valueChanged) return;
      this.isLoading = true;
      Api.post(
        `admin/testimoni`,
        {
          name: this.testimony.name,
          as: this.testimony.as,
          comment: this.testimony.comment,
          pp: this.testimony.photo,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Token}`,
          },
        }
      )
        .then(() => {
          this.backToList();
        })
        .catch((e) => {
          console.log(e);
          this.isLoading = false;
          alert("Gagal menunggah testimoni!");
        });
    },
  },
  computed: {
    valueChanged() {
      return (
        this.testimony.name != "" &&
        this.testimony.as != "" &&
        this.testimony.comment != ""
      );
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
}

.requiredTag {
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.testimony-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.testimony-container hr {
  border: solid #e1e1e1;
  margin: 0;
  border-width: 1px;
}

.testimony-container-header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.header-title {
  display: flex;
  align-items: center;
}

.testimony-container h1 {
  font-size: 1.375rem;
  font-weight: 400;
  margin: 0;
  color: #3a3a3a;
}

.testimony-container-header button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
}

.testimony-container-header button:hover {
  background-color: #f90011;
}

.testimony-container-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.add-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 8rem 0;
}

/* Text input */

.text-input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 52.5%;
  gap: 1rem;
}

.text-input label {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input input {
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  text-indent: 0.5rem;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input input:focus {
  outline: none;
}

.text-input textarea {
  padding: 0.5rem;
  font-family: "Poppins";
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  resize: none;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input textarea:focus {
  outline: none;
}

.text-title {
  display: flex;
  padding: 0 0.5rem;
}

.option-container {
  display: flex;
  gap: 0.5rem;
}

.option-box {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
}

.option-box:hover {
  background: #e9e9e9;
}

.option-box-active {
  background: #f90011;
  cursor: default;
}

.option-box-active:hover {
  background: #f90011 !important;
}

.option-box-active h1 {
  color: #ffffff !important;
}

.option-box .option-circle {
  width: 10px;
  height: 10px;
  border: #3a3a3a 1px solid;
  border-radius: 5px;
}

.option-box-active .option-circle {
  background: #ffffff;
}

.option-box h1 {
  font-size: 1rem;
  color: #3a3a3a;
}

.image-input {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 1rem;
}

.image-input .image-title {
  display: flex;
  font-size: 1.25rem;
  color: #989898;
}

.image-input .image-container .previewing-image {
  width: 240px;
  height: 240px;
}

.cover-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cover-input .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 240px;
  background: #d9d9d9;
}

.file-selection-label {
  padding: 0.75rem 0;
}

.big-file-select {
  font-size: 1rem;
  color: #ffffff;
  background: #ff2230;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.big-file-select:hover {
  background-color: #f90011;
}

.big-file-select:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.logo-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.logo-title {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.logo-title h1 {
  font-size: 1rem;
  color: #7a7a7a;
}

.logo-title span {
  color: #ff2230;
}

.logo-input .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 240px;
  background: #d9d9d9;
}

.snapshots-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.snapshots-container .image-container {
  background: #d9d9d9;
  width: 240px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.snapshot-upload-button {
  display: flex;
}

.snapshot-upload-button label {
  background: #ff2230;
  color: #ffffff;
  width: 240px;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 5px;
  cursor: pointer;
}

.snapshot-upload-button label:hover {
  background-color: #f90011;
}

.snapshot-upload-button label:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.snapshot-item-container {
  display: flex;
  flex-wrap: wrap;
  width: 496px;
  gap: 1rem;
}

.snapshot-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 240px;
}
.buttons {
  padding: 0 2rem 1rem 2rem;
}
.back-button button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.back-button button:hover {
  background-color: #f90011;
}

.back-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.disabled-button {
  background-color: #707070 !important;
  cursor: default !important;
}
</style>
