<template>
  <div class="container">
    <Hero :isMain="true" />
    <section id="service">
      <Service />
    </section>
    <section id="project">
      <Project />
    </section>
    <section id="testimony">
      <Testimony />
    </section>
    <section id="contact">
      <Contact />
    </section>
    <section id="reach-us">
      <ReachUs />
    </section>
    <section id="article">
      <Article />
    </section>
    <Footer/>
    <RunningText v-if="runningText" :text="'Masukkan text Anda disini.'" />
  </div>
</template>

<script>
import Hero from "@/components/user/Hero.vue";
import Service from "@/components/user/home/Service.vue";
import Project from "@/components/user/home/Project.vue";
import Testimony from "@/components/user/home/Testimony.vue";
import Contact from "@/components/user/home/Contact.vue";
import ReachUs from "@/components/user/home/ReachUs.vue";
import Article from "@/components/user/home/Article.vue";
import Footer from "@/components/user/Footer.vue";
import RunningText from "@/components/user/RunningText.vue";

export default {
  components: {
    Hero,
    Service,
    Project,
    Testimony,
    Contact,
    ReachUs,
    Article,
    Footer,
    RunningText,
  },
  data() {
    return {
      runningText: false,
    };
  },
  mounted() {
    if (this.runningText) {
      document.querySelector(".container").style.paddingBottom = "3rem";
    }
  },
};
</script>

<style scoped>
.container {
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  max-width: 100vw;
}

/* Service section */

#service {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8ff;
  background-image: url("@/assets/user/images/home/art-line/circle-1.svg");
  background-position: right 36%;
  background-repeat: no-repeat;
}

#project {
  display: flex;
  justify-content: center;
  background-color: #ffe4c4;
}

#testimony {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8ff;
  background-image: url("@/assets/user/images/home/art-line/circle-3.svg");
  background-position: left 40%;
  background-repeat: no-repeat;
  overflow: hidden;
}

#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffe4c4;
}

#reach-us {
  display: flex;
  background-color: #f8f8ff;
  justify-content: center;
}

#article {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: #f8f8ff;
  background-image: url("@/assets/user/images/home/art-line/circle-4.svg");
  background-repeat: no-repeat;
  background-position: right 10%;
}

/* Responsives */

@media only screen and (max-width: 1100px) {
  #project {
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 400px) {
  #project {
    flex-direction: column;
    align-items: center;
  }
}
</style>
