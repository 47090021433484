<template>
  <Loading v-if="isLoading" />
  <div class="container-failed prevent-select" v-if="failed">
    <h1>Gagal memuat</h1>
    <div>
      <button @click="fetchProject">Coba lagi</button>
    </div>
  </div>
  <div class="container" v-if="!isLoading">
    <div class="hero">
      <!-- <img
        class="hide"
        :src="`/user/assets/images/product-item/${project.is_finished}.svg`"
        alt=""
        draggable="false"
      /> -->
      <div class="hero-title">
        <h1>{{ project.title }}</h1>
        <h2>{{ project.category }}</h2>
      </div>
    </div>
    <article id="product">
      <div class="product-container">
        <div class="snapshot-container prevent-select" v-if="!isGaleryEmpty">
          <img class="main-img" :src="previewedSnapshot" alt="" />
          <div class="scroll-container" v-if="isGaleryMoreThanOne">
            <img
              v-for="(item, index) in project.galery"
              :key="index"
              :src="item.image"
              @click="changeSnapshot(index)"
              alt=""
              draggable="false"
            />
          </div>
        </div>
        <div class="product-description">
          <img
            v-if="project.logo"
            class="product-logo"
            :src="project.logo"
            alt=""
          />
          <h1 v-html="project.desc"></h1>
        </div>
      </div>
      <div class="map-container" v-if="project.map != null">
        <iframe :src="project.map"> </iframe>
        <div class="map-shadow"></div>
      </div>
      <div class="related-project-container">
        <h1 class="related-project-title">RELATED PROJECTS</h1>
        <div class="related-project-item">
          <router-link
            v-for="(project, index) in project.related"
            :key="index"
            :to="`/product/${project.id}`"
            @click="reloadWindow()"
          >
            <div class="image">
              <img class="thumbnail" :src="project.cover" alt="" />
              <img
                class="status hide"
                :src="`/user/assets/images/product/${project.is_finished}.svg`"
                alt=""
                draggable="false"
              />
            </div>
            <h1>{{ project.title }}</h1>
            <h2>{{ project.category }}</h2>
          </router-link>
        </div>
      </div>
    </article>
    <Footer />
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api";
import { useRouter } from "vue-router";
import Footer from "../Footer.vue";
import Loading from "@/components/admin/Loading.vue";

export default {
  data() {
    return {
      project: [],
      id: "",
      previewedSnapshot: "",
      isLoading: undefined,
      failed: false,
      isFetching: false,
    };
  },
  components: {
    Footer,
    Loading,
  },
  created() {
    this.id = useRouter().currentRoute.value.params.id;
  },
  methods: {
    changeSnapshot(id) {
      this.previewedSnapshot = `${this.project.galery[id].image}`;
    },
    fetchProject() {
      if (this.isFetching) return;
      this.isFetching = true;
      this.failed = false;
      this.isLoading = true;
      Api.get(`project/${this.id}?limit=4`, {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => {
          this.project = res.data.data;
          if (this.project.logo == null) {
            this.project.logo = null;
          } else {
            this.project.logo = `${baseURL}images/${this.project.logo}`;
          }
          if (this.project.cover == null) {
            this.project.cover = "/user/assets/images/project/noimage.png";
          } else {
            this.project.cover = `${baseURL}images/${this.project.cover}`;
            // console.log(this.project.cover);
          }
          if (this.project.title == null) {
            this.project.title = "Tanpa Judul";
          }
          if (this.project.desc == null) {
            this.project.desc = "<p>Tidak ada deskripsi.</p>";
          }
          if (this.project.is_finished == 1) {
            this.project.is_finished = "finished";
          } else if (this.project.is_finished == 0) {
            this.project.is_finished = "ongoing";
          }
          if (this.project.galery.length != 0) {
            for (let i = 0; i < this.project.galery.length; i++) {
              this.project.galery[
                i
              ].image = `${baseURL}images/${this.project.galery[i].image}`;
            }
          } else if (this.project.galery.length == 0) {
            this.project.galery.push({
              project_id: `${this.project.id}`,
              image: `user/assets/images/project/noimage.png`,
            });
          }
          if (this.project.related.length != 0) {
            for (let i = 0; i < this.project.related.length; i++) {
              if (this.project.related[i].cover == null) {
                this.project.related[i].cover =
                  "/user/assets/images/project/noimage.png";
              } else {
                this.project.related[
                  i
                ].cover = `${baseURL}images/${this.project.related[i].cover}`;
              }
              if (this.project.related[i].is_finished == 1) {
                this.project.related[i].is_finished = "finished";
              } else if (this.project.related[i].is_finished == 0) {
                this.project.related[i].is_finished = "ongoing";
              }
            }
          }
          this.isFetching = false;
          this.failed = false;
          this.isLoading = false;
          // console.log(this.project);
          // console.log(this.failed);
          setTimeout(() => {
            this.afterFetch();
          }, 1);
        })
        .catch(() => {
          this.isFetching = false;
          this.failed = true;
          this.isLoading = false;
        });
    },
    afterFetch() {
      if (!this.isGaleryEmpty) {
        this.previewedSnapshot = `${this.project.galery[0].image}`;
      }
      if (
        this.project.galery[0].image == `user/assets/images/project/noimage.png`
      ) {
        this.previewedSnapshot = "/user/assets/images/project/noimage.png";
      }

      if (this.project.cover != "/user/assets/images/project/noimage.png") {
        document.querySelector(
          ".hero"
        ).style.background = `no-repeat linear-gradient(180deg, rgba(255, 255, 255, 0) -38.5%, #000000 112.35%), url("${this.project.cover}") center/cover`;
        // ).style.backgroundImage = `url("${this.project.cover}")`;
      } else {
        document.querySelector(
          ".hero"
        ).style.background = `no-repeat linear-gradient(180deg, rgba(255, 255, 255, 0) -38.5%, #000000 112.35%), url("${this.project.cover}") center/cover`;
      }
      let description = document.querySelector(".product-description");
      if (this.project.galery.length == 0) {
        description.style.width = "80%";
        description.style.paddingRight = "0";
      }
      if (!this.project.map) {
        document.querySelector(".related-project-container").style.paddingTop =
          "0";
      }
    },
    reloadWindow() {
      setTimeout(() => {
        window.location.reload();
      }, 1);
    },
  },
  computed: {
    isGaleryEmpty() {
      return this.project?.galery?.length === 0;
    },
    isGaleryMoreThanOne() {
      return this.project?.galery?.length != 0 && this.project?.galery?.length != 1;
    },
  },
  mounted() {
    this.$root.emitter.emit("killInterval");
    this.fetchProject();
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url("@/assets/user/images/product/noimage.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 100vh;
  width: 100%;
  color: #f8f8ff;
  position: relative;
}

.hero img {
  position: absolute;
  top: 0;
  left: 0;
}

.hero-title {
  padding: 4rem;
}

.hero-title h1 {
  margin: 0;
  font-weight: 700;
  font-size: 2.25rem;
  letter-spacing: 0.05em;
}

.hero-title h2 {
  margin: 0;
  font-weight: 500;
  font-size: 2rem;
  letter-spacing: 0.05em;
  color: rgba(248, 248, 255, 0.8);
}

/* Main content */

#product {
  display: flex;
  flex-direction: column;
  background-color: #f8f8ff;
}

.product-container {
  display: flex;
  justify-content: center;
  gap: 4rem;
  padding: 0 0 6rem 0;
}

.snapshot-container {
  display: flex;
  flex-direction: column;
  padding: 6rem 0 0 4rem;
}

.snapshot-container img.main-img {
  width: 650px;
  height: 400px;
  pointer-events: none;
}

.scroll-container {
  display: flex;
  flex-direction: row;
  padding: 1rem 0 0 0;
  gap: 1rem;
  width: 650px;
  overflow: auto;
  white-space: nowrap;
}

.scroll-container img {
  width: 130px;
  height: 80px;
  cursor: pointer;
}

.product-description {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 6rem 1.75rem 0 0;
  gap: 2rem;
}

.product-logo {
  width: 190.5px;
  height: 190.5px;
  object-fit: contain;
}

.product-description h1 {
  margin: 0;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  color: #000000;
  line-height: 30px;
  text-align: justify;
}

.product-description h1:deep(p) {
  margin: 0;
}

.product-bottom {
  display: flex;
}

.visit-website {
  padding: 3rem 0 5rem 0;
}

.visit-website button {
  background-color: #ff2230;
  border-radius: 20px;
  border-style: none;
  font-weight: 600;
  font-size: 1.5rem;
  color: #ffffff;
  padding: 1.5rem 3.5rem 1.5rem 3.5rem;
  -webkit-transition: background-color 0.15s ease-out;
  -moz-transition: background-color 0.15s ease-out;
  -o-transition: background-color 0.15s ease-out;
  transition: background-color 0.15s ease-out;
  cursor: pointer;
}

.visit-website button:hover {
  background-color: #f90011;
}

.visit-website button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
.map-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.map-container iframe {
  width: 100%;
  height: 37rem;
  border: none;
  z-index: 0;
}

.map-shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  box-shadow: inset 0 0 80px #444444;
  top: 0;
  left: 0;
  pointer-events: none;
}

.image {
  position: relative;
}

.image .thumbnail {
  z-index: 0;
  pointer-events: none;
  width: 400px;
  height: 257px;
  vertical-align: bottom;
}

.image .status {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transform: translate(-0.15rem, -0.15rem);
}

.related-project-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 4rem 0 2rem 0;
}

.related-project-title {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
  letter-spacing: 0.05em !important;
  padding-bottom: 4rem;
  color: #000000;
}

.related-project-item {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.related-project-container h1 {
  width: 400px;
  text-align: center;
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  color: #252525;
  margin-bottom: 0;
}

.related-project-container h2 {
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.05em;
  color: rgba(37, 37, 37, 0.7);
  text-align: center;
}

.related-project-item a,
.related-project-item a:visited {
  justify-content: center;
  text-decoration: none;
}

.container-failed {
  height: 100vh;
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-failed h1 {
  font-size: 4rem;
}

.container-failed button {
  font-size: 2rem;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  color: #ffffff;
  background-color: #ff2230;
}

.container-failed button:hover {
  background-color: #f90011;
}

.container-failed button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
@media only screen and (max-width: 400px) {
  .hero-title {
    padding: 4rem 2rem;
  }
  .hero-title h1 {
    font-size: 1.5rem;
  }
  .hero-title h2 {
    font-size: 1rem;
  }
  .product-container {
    flex-wrap: wrap;
    gap: 2rem;
    padding-bottom: 0;
  }
  .snapshot-container {
    padding: 2rem 0;
    padding-bottom: 0;
  }
  .snapshot-container img.main-img {
    width: 325px;
    height: 200px;
  }
  .scroll-container {
    width: 325px;
  }
  .related-project-item a div img:first-of-type {
    width: 300px;
    height: 200px;
  }
  .related-project-item a h1 {
    font-size: 1rem;
    max-width: 300px;
  }
  .related-project-item a h2 {
    font-size: 1rem;
    max-width: 300px;
  }
  .product-description {
    width: 80%;
    padding: 0;
  }
  h1.related-project-title {
    width: auto;
    font-size: 1rem;
    padding-bottom: 2rem;
  }
  .container-failed h1 {
    font-size: 2rem;
  }
  .container-failed button {
    outline: none;
    font-size: 1rem;
    border-radius: 15px;
    padding: 0.5rem 1rem;
  }
}
</style>
