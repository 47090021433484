<template>
  <div
    class="image-full prevent-select"
    @click.self="enableScroll(), closeImage()"
  >
    <div class="full-container">
      <img :src="imageToShow ?? '@/assets/loading.svg'" alt="" />
      <div class="open-new-tab">
        <h1 @click="fullImage()">Open in New Tab</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageToShow: "",
    };
  },
  mounted() {
    this.$root.emitter.on("openImage", (e) => {
      const imageFull = document.querySelector(".image-full");
      // this.disableScroll();
      this.imageToShow = e;
      imageFull.classList.add("image-full-show");
      setTimeout(() => {
        imageFull.style.opacity = "100%";
      }, 1);
    });
  },
  methods: {
    disableScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft;

      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
    },
    enableScroll() {
      window.onscroll = function () {};
    },
    closeImage() {
      const imageFull = document.querySelector(".image-full");
      // this.enableScroll();
      imageFull.style.opacity = "0%";
      setTimeout(() => {
        imageFull.classList.remove("image-full-show");
      }, 1);
    },
    showImage() {},
    fullImage() {
      window.open(`${this.imageToShow}`, "_blank");
    },
  },
};
</script>

<style scoped>
/* Preview */
.image-full {
  font-family: "Poppins";
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #000000dc;
  z-index: 10;
  -webkit-transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
  opacity: 0%;
}

.full-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.image-full img {
  pointer-events: none;
  vertical-align: middle;
  display: block;
  max-width: 920px;
  max-height: 380px;
  width: auto;
  height: auto;
}

.open-new-tab {
  display: flex;
}

.image-full h1 {
  margin: 0;
  font-size: 0.9rem;
  color: #b3b3b3;
  cursor: pointer;
  font-weight: 300;
  -webkit-transition: color 0.1s ease-out;
  -moz-transition: color 0.1s ease-out;
  -o-transition: color 0.1s ease-out;
  transition: color 0.1s ease-out;
}

.image-full h1:hover {
  color: #ffffff;
  text-decoration: underline;
}

.image-full-show {
  display: flex;
}

@media only screen and (max-width: 400px) {
  .image-full img {
    max-width: 460px;
    max-height: 190px;
  }
}
</style>
