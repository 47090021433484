<template>
  <div class="container">
    <Hero :simpleMode="'ARTIKEL'" />
    <div class="article-container">
      <div class="article-wrapper" v-if="articles.length">
        <div
          class="article-card-item"
          v-for="(article, index) in articles"
          :key="index"
        >
          <img :src="article.cover" alt="" draggable="false" />
          <div class="article-card-details">
            <h1>
              {{ article.title }}
            </h1>
            <div class="article-card-user-timestamp">
              <div>
                <img :src="article.author.photo" alt="" draggable="false" />
                <h2>{{ article.author.name }}</h2>
              </div>
              <div>
                <img
                  src="@/assets/user/icons/home/article/clock.svg"
                  alt=""
                  draggable="false"
                />
                <h2>{{ article.time }}</h2>
              </div>
            </div>
            <h3>
              {{ article.content }}
            </h3>
            <div class="article-more" @click="toArticle(article.id)">
              <h4>Selengkapnya</h4>
              <img
                src="@/assets/user/icons/home/article/arrow.svg"
                alt=""
                draggable="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="!articles.length" class="empty-container">
        <h1>Tidak ada artikel</h1>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Hero from "@/components/user/Hero.vue";
import Footer from "@/components/user/Footer.vue";

export default {
  components: { Hero, Footer },
  data() {
    return {
      articles: [
        // {
        //   cover: "user/assets/images/article/cover/article-1.png",
        //   author: {
        //     photo: "user/assets/images/article/author/author-1.png",
        //     name: "Basuki",
        //   },
        //   time: "7 Maret 2020",
        //   title:
        //     "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
        //   content:
        //     "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
        //   id: 1,
        // },
        // {
        //   cover: "user/assets/images/article/cover/article-1.png",
        //   author: {
        //     photo: "user/assets/images/article/author/author-1.png",
        //     name: "Basuki",
        //   },
        //   time: "7 Maret 2020",
        //   title:
        //     "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
        //   content:
        //     "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
        //   id: 1,
        // },
        // {
        //   cover: "user/assets/images/article/cover/article-1.png",
        //   author: {
        //     photo: "user/assets/images/article/author/author-1.png",
        //     name: "Basuki",
        //   },
        //   time: "7 Maret 2020",
        //   title:
        //     "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
        //   content:
        //     "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
        //   id: 1,
        // },
        // {
        //   cover: "user/assets/images/article/cover/article-1.png",
        //   author: {
        //     photo: "user/assets/images/article/author/author-1.png",
        //     name: "Basuki",
        //   },
        //   time: "7 Maret 2020",
        //   title:
        //     "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
        //   content:
        //     "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
        //   id: 1,
        // },
        // {
        //   cover: "user/assets/images/article/cover/article-1.png",
        //   author: {
        //     photo: "user/assets/images/article/author/author-1.png",
        //     name: "Basuki",
        //   },
        //   time: "7 Maret 2020",
        //   title:
        //     "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
        //   content:
        //     "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
        //   id: 1,
        // },
        // {
        //   cover: "user/assets/images/article/cover/article-1.png",
        //   author: {
        //     photo: "user/assets/images/article/author/author-1.png",
        //     name: "Basuki",
        //   },
        //   time: "7 Maret 2020",
        //   title:
        //     "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
        //   content:
        //     "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
        //   id: 1,
        // },
        // {
        //   cover: "user/assets/images/article/cover/article-1.png",
        //   author: {
        //     photo: "user/assets/images/article/author/author-1.png",
        //     name: "Basuki",
        //   },
        //   time: "7 Maret 2020",
        //   title:
        //     "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
        //   content:
        //     "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
        //   id: 1,
        // },
        // {
        //   cover: "user/assets/images/article/cover/article-1.png",
        //   author: {
        //     photo: "user/assets/images/article/author/author-1.png",
        //     name: "Basuki",
        //   },
        //   time: "7 Maret 2020",
        //   title:
        //     "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
        //   content:
        //     "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
        //   id: 1,
        // },
        // {
        //   cover: "user/assets/images/article/cover/article-1.png",
        //   author: {
        //     photo: "user/assets/images/article/author/author-1.png",
        //     name: "Basuki",
        //   },
        //   time: "7 Maret 2020",
        //   title:
        //     "Mengatasi Tantangan Konstruksi di Daerah dengan Tanah Lempung",
        //   content:
        //     "Tantangan dalam konstruksi selalu berbeda-beda tergantung pada lingkungan tempat konstruksi berada. Salah...",
        //   id: 1,
        // },
      ],
    };
  },
  methods: {
    toArticle(a) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.$router.push(`/article/${a}`);
    },
  },
};
</script>

<style scoped>
img {
  pointer-events: none;
}

.container {
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
}

.article-container {
  display: flex;
  justify-content: center;
  background: #f8f8ff;
}

.article-wrapper {
  padding: 10rem 0;
  margin: 0 auto;
  max-width: 93.283%;
  gap: 1.15rem;
  display: inline-flex;
  flex-wrap: wrap;
}

.article-card-item {
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.article-card-details {
  padding: 0.25rem 1rem 1rem 1rem;
}

.article-card-user-timestamp {
  display: flex;
  gap: 1rem;
}

.article-card-user-timestamp div {
  display: flex;
  gap: 0.5rem;
}

.article-card-item div div img {
  object-fit: none;
}

.article-card-item h1 {
  font-weight: 500;
  font-size: 1.25rem;
  max-width: 22rem;
  margin: 0;
}

.article-card-item h2 {
  font-weight: 400;
  font-size: 1rem;
}

.article-card-item h3 {
  font-weight: 400;
  max-width: 23rem;
  font-size: 1rem;
}

.article-card-item h4 {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

.empty-container {
  padding: 5.5rem 0;
}

.empty-container h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

.article-more {
  display: flex;
  gap: 0.25rem;
  cursor: pointer;
  width: 8.5rem;
}
@media only screen and (max-width: 400px) {
  .article-wrapper {
    padding: 2rem 0;
    justify-content: center;
  }
  .article-card-item div div img {
    width: 20px;
    object-fit: contain;
  }
  .article-paragraph h1 {
    font-size: 1.4rem;
  }
  .article-paragraph h2 {
    font-size: 1.1rem;
    /* letter-spacing: 0.1em; */
  }
  .article-card-container {
    width: 85%;
  }
  .article-card-details {
    padding-bottom: 1rem;
  }
  .article-card-item img {
    width: 280px;
  }
  .article-card-item h1 {
    font-size: 0.75rem;
    max-width: 240px;
  }
  .article-card-item h2 {
    font-size: 0.6rem;
  }
  .article-card-item h3 {
    font-size: 0.6rem;
    max-width: 240px;
  }
  .article-card-item h4 {
    font-size: 0.6rem;
  }
  .article-more {
    align-items: center;
  }
}
</style>
