<template>
  <div>
    <h1>Sejarah</h1>
    <h2>
      PT. Adhi Konsultan Utama berdiri di Kendal tahun 2019, berdasarkan Akte
      Notaris: Andy Fahrully Burhan, S.H., M.Kn., Nomor 01, tanggal 13 Maret
      2019. Perusahaan ini berdomisili di Witjitraland Blok E Nomor 5, Kelurahan
      Langeharjo, Kecamatan Kendal, Kabupaten Kendal, Provinsi Jawa Tengah.
    </h2>
  </div>
</template>

<style scoped>
h1 {
  font-weight: 600;
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  color: #ff2230;
  padding-left: 2rem;
}

h2 {
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  color: #252525;
  padding-top: 1rem;
  text-align: justify;
}

@media only screen and (max-width: 1440px) {
  h1 {
    font-size: 2rem;
    margin: 0;
    padding: 2rem 0 2rem 2rem;
  }
  h2 {
    font-size: 1.25rem;
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 1050px) {
  h1 {
    font-size: 1.75rem;
  }
  h2 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 0;
    padding: 0;
    text-align: center;
  }
  h2 {
    font-size: 1rem;
  }
}
</style>
