<template>
  <div class="project-paragraph">
    <h1>Proyek yang Telah Kami Selesaikan</h1>
    <hr />
    <h2>
      Ini adalah proyek dari PT Adhi Konsultan Utama yang sedang berjalan dan
      juga yang telah selesai.
    </h2>
    <div class="more-project">
      <router-link to="/product#product"
        >Proyek kami lainnya<img
          src="@/assets/user/icons/home/project/arrow.svg"
          alt=""
      /></router-link>
    </div>
  </div>
  <div class="project-container" v-if="projects.length != 0">
    <div
      v-for="(project, index) in projects"
      :key="project.id"
      :class="`project-item item${index}`"
      @mouseover="fadeInBg(index)"
      @mouseleave="fadeOutBg(index)"
    >
      <div class="card-shadow"></div>
      <h1 class="disappear">{{ project.title }}</h1>
      <h2 class="disappear" v-html="project.desc"></h2>
      <router-link
        class="disappear"
        :to="`/product/${project.id}`"
        @click="toTop()"
        >Selengkapnya<img
          src="@/assets/user/icons/home/project/arrow-small.svg"
          alt=""
      /></router-link>
    </div>
    <div class="more-projects">
      <router-link to="/product#product"
        >Proyek Kami Lainnya<img
          src="@/assets/user/icons/home/project/arrow-small.svg"
          alt=""
      /></router-link>
    </div>
  </div>
  <div class="project-container skeleton-project" v-else>
    <div class="project-item skeleton"></div>
    <div class="project-item skeleton"></div>
    <div class="project-item skeleton"></div>
    <div class="project-item skeleton"></div>
    <div class="project-item skeleton"></div>
    <div class="project-item skeleton"></div>
    <div class="more-projects">
      <router-link to="/product#product"
        >Proyek Kami Lainnya<img
          src="@/assets/user/icons/home/project/arrow-small.svg"
          alt=""
      /></router-link>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api.js";

export default {
  data() {
    return {
      projects: [],
    };
  },
  mounted() {
    Api.get("home", {
      headers: {
        Accept: "application/json",
      },
    }).then((res) => {
      this.projects = res.data.data.project;
      for (let i = 0; i < this.projects.length; i++) {
        if (this.projects[i].cover == null) {
          this.projects[i].cover = "/user/assets/images/project/noimage.png";
        } else {
          this.projects[i].cover = `${baseURL}images/${this.projects[i].cover}`;
        }
        if (this.projects[i].title == null) {
          this.projects[i].title = "No title";
        }
        if (this.projects[i].desc == null) {
          this.projects[i].desc =
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.";
        }
        setTimeout(() => {
          document.querySelector(
            `.item${i}`
          ).style.background = `linear-gradient(360deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%), url(${this.projects[i].cover}) no-repeat center / cover`;
        }, 1);
      }
    });
  },
  methods: {
    fadeInBg(e) {
      const projectElement = document.querySelector(`.item${e}`);
      const cardShadow = projectElement.querySelector(".card-shadow");

      cardShadow.classList.add("shadowHover");
      this.textPop(e);
    },
    fadeOutBg(e) {
      const projectElement = document.querySelector(`.item${e}`);
      const cardShadow = projectElement.querySelector(".card-shadow");

      cardShadow.classList.remove("shadowHover");
      this.textDisappear(e);
    },
    textPop(e) {
      const projectElement = document.querySelector(`.item${e}`);
      const cardH1 = projectElement.querySelector("h1");
      const cardH2 = projectElement.querySelector("h2");
      const cardA = projectElement.querySelector("a");

      cardH1.classList.remove("disappear");
      cardH2.classList.remove("disappear");
      cardA.classList.remove("disappear");
    },
    textDisappear(e) {
      const projectElement = document.querySelector(`.item${e}`);
      const cardH1 = projectElement.querySelector("h1");
      const cardH2 = projectElement.querySelector("h2");
      const cardA = projectElement.querySelector("a");

      cardH1.classList.add("disappear");
      cardH2.classList.add("disappear");
      cardA.classList.add("disappear");
    },
    toTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.project-paragraph {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  /* padding-top: 15rem;
  padding-bottom: 15rem;
  padding-right: 5.6rem; */
  padding: 15% 5.6% 15% 0;
  width: 30%;
  height: 30rem;
}

.project-paragraph h1 {
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.5rem;
  color: #001858;
  margin: 0;
  padding-bottom: 0.75rem;
}

.project-paragraph hr {
  border-style: solid;
  border-width: 0.15rem;
  border-color: #e4000f;
  width: 63%;
  margin: 0;
}

.project-paragraph h2 {
  font-weight: 400;
  font-size: 2.25rem;
  color: #001858;
  margin: 0;
  padding-top: 1.5rem;
  letter-spacing: 0.05em;
}

.project-paragraph div {
  width: 81%;
}

.project-paragraph a {
  display: flex;
  gap: 1rem;
  font-weight: 400;
  font-size: 2.25rem;
  margin: 0;
  letter-spacing: 0.05em;
}

.project-container {
  display: flex;
  flex-direction: column;
  padding-top: 7rem;
  gap: 7rem;
}

.project-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 770px;
  width: 852px;
  border-radius: 10px;
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 50%
    ),
    url("@/assets/user/images/home/project/company-1.png"), #f8f8ff;
  overflow: hidden;
}

.card-shadow {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  -webkit-transition: opacity 0.75s ease-in-out;
  -moz-transition: opacity 0.75s ease-in-out;
  -o-transition: opacity 0.75s ease-in-out;
  transition: opacity 0.75s ease-in-out;
  opacity: 0%;
  border-radius: 10px;
  z-index: 1;
}

.shadowHover {
  opacity: 50%;
}

.project-item h1 {
  height: 4em;
  box-sizing: border-box;
  padding: 2.5rem 2.5rem 0 2.5rem;
  font-family: "Poppins Bold";
  font-weight: 700;
  font-size: 2rem;
  letter-spacing: 0.05em;
  line-height: 3rem;
  color: #f8f8ff;
  margin: 0;
  text-overflow: hidden;
  z-index: 2;
  -webkit-transition: transform 0.75s ease-in-out;
  -moz-transition: transform 0.75s ease-in-out;
  -o-transition: transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out;
}

.project-item h2 {
  font-weight: 400;
  font-size: 2rem;
  line-height: 54.4px;
  color: #f8f8ff;
  margin: 0;
  padding: 0 2.5rem 0 2.5rem;
  text-overflow: hidden;
  overflow-y: scroll;
  z-index: 2;
  -webkit-transition: transform 0.75s ease-in-out;
  -moz-transition: transform 0.75s ease-in-out;
  -o-transition: transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out;
}

.project-item h2::-webkit-scrollbar {
  display: none;
}

.project-item a {
  display: flex;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 0.05em;
  color: #f8f8ff;
  text-decoration: none;
  gap: 0.75rem;
  padding: 0 2.5rem 2.5rem 2.5rem;
  text-overflow: hidden;
  width: 30%;
  z-index: 2;
  -webkit-transition: transform 0.75s ease-in-out;
  -moz-transition: transform 0.75s ease-in-out;
  -o-transition: transform 0.75s ease-in-out;
  transition: transform 0.75s ease-in-out;
}

.disappear {
  -webkit-transform: translateY(650px);
  -moz-transform: translateY(650px);
  -o-transform: translateY(650px);
  -ms-transform: translateY(650px);
  transform: translateY(650px);
}

.more-projects {
  display: flex;
  justify-content: center;
  margin-top: -3.5rem;
  padding-bottom: 3.5rem;
}

.more-projects a {
  color: #252525;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.5rem;
  display: flex;
  gap: 0.75rem;
}

.more-projects a img {
  filter: invert(1);
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-project .project-item {
  background: none;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

/* @media only screen and (max-height: 768px) {
  .project-paragraph {
    padding: 9rem 2rem 9rem 0;
  }
} */

@media only screen and (max-width: 1473px) {
  /* .project-paragraph {
    padding: 18rem 5.6rem 18rem 0;
  } */
  .project-paragraph h1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .project-paragraph h2 {
    font-size: 1.5rem;
  }
  .project-paragraph a {
    font-size: 1.5rem;
  }
  .project-item {
    height: 577.5px;
    width: 639px;
  }
  .project-item h1 {
    height: 6em;
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .project-item h2 {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .project-item a {
    font-size: 1.25rem;
  }
  .disappear {
    -webkit-transform: translateY(470px);
    -moz-transform: translateY(470px);
    -o-transform: translateY(470px);
    -ms-transform: translateY(470px);
    transform: translateY(470px);
  }
}

@media only screen and (max-width: 1415px) {
  /* .project-paragraph {
    padding: 18rem 2rem 18rem 0;
  } */
}
@media only screen and (max-width: 1320px) {
  /* .project-paragraph {
    padding: 18rem 1rem 18rem 0;
  } */
}
@media only screen and (max-width: 1100px) {
  .project-paragraph {
    /* display: none; */
    position: static;
    padding: 2rem 0;
    width: 80%;
    height: auto;
  }
  .project-paragraph h1 {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .project-paragraph h2 {
    font-size: 1.2rem;
  }
  .project-paragraph a {
    font-size: 1rem;
    padding-top: 1rem;
  }
  .project-container {
    gap: 2rem;
    padding: 0;
  }
  .project-item {
    height: 577.5px;
    width: 639px;
  }
  .project-item h1 {
    height: 6em;
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .project-item h2 {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .project-item a {
    font-size: 1.25rem;
  }
  .disappear {
    -webkit-transform: translateY(470px);
    -moz-transform: translateY(470px);
    -o-transform: translateY(470px);
    -ms-transform: translateY(470px);
    transform: translateY(470px);
  }
  .more-projects {
    margin: 0;
    padding-bottom: 2rem;
  }
  .more-projects a {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 690px) {
  .project-item {
    height: 288.75px;
    width: 319.5px;
  }
  .project-item h1 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .project-item h2 {
    font-size: 1rem;
    line-height: 2rem;
  }
  .project-item a {
    font-size: 1rem;
  }
  .disappear {
    -webkit-transform: translateY(190px);
    -moz-transform: translateY(190px);
    -o-transform: translateY(190px);
    -ms-transform: translateY(190px);
    transform: translateY(190px);
  }
}
@media only screen and (max-width: 400px) {
  .project-paragraph {
    /* display: none; */
    position: static;
    padding: 2rem 0;
    width: 80%;
    height: auto;
  }
  .project-paragraph h1 {
    font-size: 1.4rem;
    line-height: 2rem;
  }
  .project-paragraph h2 {
    font-size: 1.2rem;
  }
  .project-paragraph a {
    font-size: 1rem;
    padding-top: 1rem;
  }
  .project-container {
    gap: 2rem;
    padding: 0;
  }
  .project-item {
    height: 288.75px;
    width: 319.5px;
  }
  .project-item h1 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .project-item h2 {
    font-size: 1rem;
    line-height: 2rem;
  }
  .project-item a {
    font-size: 1rem;
  }
  .disappear {
    -webkit-transform: translateY(190px);
    -moz-transform: translateY(190px);
    -o-transform: translateY(190px);
    -ms-transform: translateY(190px);
    transform: translateY(190px);
  }
  .more-projects {
    margin: 0;
    padding-bottom: 2rem;
  }
  .more-projects a {
    font-size: 1rem;
  }
}
</style>
