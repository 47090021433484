<template>
  <div class="container">
    <Hero
      :title="'PRODUK'"
      :text="'PT Adhi Konsultan Utama telah dipercaya dan berpengalaman pada banyak pekerjaan.'"
      :buttonText="'Hubungi Kami'"
      :buttonDirection="'/home#reach-us'"
    />
    <section id="product">
      <div>
        <Project/>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Hero from "@/components/user/Hero.vue";
import Project from "@/components/user/product/category/Project.vue"
import Footer from '@/components/user/Footer.vue';
// import Licensing from "@/components/user/product/category/Licensing.vue"

export default {
  data() {
    return {
    }
  },
  components: {
    Hero,
    Project,
    Footer,
  },
};
</script>

<style scoped>
.container {
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

/* Product content */
#product {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8ff;
  overflow-x: hidden;
}

/* Category */
.category {
  padding-top: 4rem;
  display: flex;
  gap: 4rem;
}

.category h1 {
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  color: #000000;
  cursor: pointer;
  -webkit-transition: border-bottom 0.1s ease-in-out;
  -moz-transition: border-bottom 0.1s ease-in-out;
  -o-transition: border-bottom 0.1s ease-in-out;
  transition: border-bottom 0.1s ease-in-out;
  border-bottom: 2px solid rgba(255, 34, 48, 0);
}

.selected-category {
  border-bottom: 2px solid #ff2230 !important;
  cursor: default !important;
}


</style>
