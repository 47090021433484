<template>
  <footer>
    <div class="footer-div">
      <h1>{{ name }}</h1>
      <hr />
      <div class="footer-bottom">
        <div class="footer-credit-socials">
          <h2>SITEMAP</h2>
          <hr />
          <div class="socials">
            <a
              v-if="facebookLink"
              :href="facebookLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/user/icons/footer/socials/facebook.svg"
                alt=""
              />
            </a>
            <a
              v-if="instagramLink"
              :href="instagramLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/user/icons/footer/socials/instagram.svg"
                alt=""
              />
            </a>
            <a
              v-if="twitterLink"
              :href="twitterLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/user/icons/footer/socials/twitter.svg"
                alt=""
              />
            </a>
            <a
              v-if="youtubeLink"
              :href="youtubeLink"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="@/assets/user/icons/footer/socials/youtube.svg"
                alt=""
              />
            </a>
          </div>
        </div>
        <div class="credit">
          <h3>
            Copyright {{ copyrightYear }} © <span>{{ copyright }}</span>
          </h3>
          <h3 @mouseleave="leaveCreditHover()">
            Developed by<span
              v-if="!creditHovered"
              @mouseover="onCreditHover()"
              >{{ developedBy }}</span
            >
            <span v-if="creditHovered"
              ><a
                :href="credit1Link"
                target="_blank"
                rel="noopener noreferrer"
                >{{ credit1 }}</a
              ></span
            >
            <span v-if="creditHovered"
              ><a
                :href="credit2Link"
                target="_blank"
                rel="noopener noreferrer"
                >{{ credit2 }}</a
              ></span
            >
            <span v-if="creditHovered"
              ><a
                :href="credit3Link"
                target="_blank"
                rel="noopener noreferrer"
                >{{ credit3 }}</a
              ></span
            >
          </h3>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      name: "PT Adhi Konsultan Utama",
      footerColor: "#bb000c",
      copyright: "PT Adhi Konsultan Utama",
      copyrightYear: "2023",
      developedBy: "Developer PAKU",
      facebookLink: "",
      instagramLink: "",
      twitterLink: "",
      youtubeLink: "",
      credit1: "rRasyad",
      credit1Link: "https://github.com/rRasyad",
      credit2: "StickyPistonn",
      credit2Link: "https://github.com/StickyPistonn",
      credit3: "Xasham",
      credit3Link: "https://github.com/xasham",

      creditHovered: false,
    };
  },
  mounted() {
    document.querySelector(
      "footer"
    ).style.backgroundColor = `${this.footerColor}`;
  },
  methods: {
    onCreditHover() {
      this.creditHovered = true;
    },
    leaveCreditHover() {
      this.creditHovered = false;
    },
  },
};
</script>

<style scoped>
footer {
  display: flex;
  justify-content: center;
}

.footer-div {
  width: 80%;
  padding: 0.25rem 0;
}

.footer-div h1 {
  font-weight: 700;
  font-size: 1.5rem;
  color: #f8f8ff;
  margin: 0;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.footer-div h2 {
  font-weight: 700;
  font-size: 1.25rem;
  color: #f8f8ff;
  margin: 0;
}

.footer-div h3 {
  font-weight: 700;
  font-size: 1.25rem;
  color: #f8f8ff;
  margin: 0;
}

.footer-div hr {
  border-style: solid;
  border-color: #f8f8ff;
  margin: 0;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
}

.footer-bottom div {
  display: flex;
}

.footer-credit-socials {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
}

.footer-credit-socials hr {
  border-color: #f8f8ff;
  height: 3rem;
}

.socials {
  display: flex;
  gap: 1rem;
}

.socials a {
  display: flex;
  height: 100%;
}

.socials img {
  cursor: pointer;
}

.credit {
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-top: 1rem;
}

.credit h3 {
  font-weight: 700;
  font-size: 15px;
  color: rgba(248, 248, 255, 0.8);
}

.credit h3 span {
  color: #f8f8ff;
}

.credit h3 a,
.credit h3 a:visited,
.credit h3 a:link {
  color: #f8f8ff;
  text-decoration: none;
}

.credit h3 span::before {
  content: " ";
}

@media only screen and (max-width: 580px) {
  .footer-div {
    width: 95%;
    padding: 1rem;
  }
  .footer-div h1 {
    font-size: 1rem;
    padding-top: 0;
  }
  .footer-bottom {
    flex-direction: row;
  }
  .footer-credit-socials {
    justify-content: center;
  }
  .credit h3 {
    font-size: 0.75rem;
  }
}
</style>
