<template>
  <div class="container">
    <h1>Visi dan Misi</h1>
    <div class="image-container">
      <div v-if="!imgs" class="empty-image">
        <div class="loading-div">
          <h6>Memuat</h6>
        </div>
      </div>
      <div class="vmv-container">
        <img
          v-for="img in imgs"
          :key="img.id"
          :src="img.value ?? noImg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api";
export default {
  data() {
    return {
      imgs: [],
      noImg: "/user/assets/images/project/noimage.png",
    };
  },
  mounted() {
    Api.get("vmv")
      .then((res) => {
        this.imgs = res.data.data;
        for (let i = 0; i < this.imgs.length; i++) {
          this.imgs[i].value = `${baseURL}images/${this.imgs[i].value}`;
        }
        // console.log(this.imgs);
      })
      .catch(() => {
        this.data.visi = this.noImg;
        this.data.misi = this.noImg;
        this.data.nilai_nilai = this.noImg;
      });
  },
};
</script>

<style scoped>
img {
  pointer-events: none;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0 0 6rem 0;
}

.container h1 {
  font-weight: 600;
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  color: #ff2230;
  padding-left: 2rem;
}

.container h2 {
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 0.05em;
  margin-top: 0;
}

.image-container {
  padding-top: 1rem;
}

.vmv-container img {
  width: 80rem;
}

.emppty-image {
}

.loading-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70rem;
  height: 20rem;
}

.loading-div h6 {
  font-size: 2.5rem;
  margin: 0;
  font-weight: 300;
}
@media only screen and (max-width: 1790px) {
  .vmv-container img {
    width: 60rem;
  }
}
@media only screen and (max-width: 1700px) {
  .vmv-container img {
    width: 40rem;
  }
}
@media only screen and (max-width: 1440px) {
  .container h1 {
    font-size: 2rem;
    margin: 0;
    padding: 2rem 0 2rem 2rem ;
  }
}
@media only screen and (max-width: 1400px) {
  .vmv-container {
    width: 30rem;
  }
}

@media only screen and (max-width: 400px) {
  .container {
    padding-bottom: 0;
  }
  .container h1 {
    padding: 0;
    margin-bottom: 0;
    font-size: 2rem;
  }
  .image-container {
    gap: 2rem;
  }
  .vmv-container img {
    width: 18rem;
    transform: translateX(-12%);
  }
}
</style>
