<template>
  <div class="inner-content">
    <div class="inner-header">
      <div class="add-button prevent-select" v-if="!isLoading && !isError">
        <input class="hide" type="file" id="upload-vmv" @change="addVmv" />
        <label for="upload-vmv">Tambah data</label>
      </div>
    </div>
    <div class="inner-container">
      <hr v-if="!isLoading && !isError" />
      <div class="system-messages">
        <Empty v-if="showEmpty" />
        <Loading v-if="isLoading && !isError" />
        <Error v-if="!isLoading && isError" />
      </div>

      <div v-if="!isLoading && !isError" class="card-wrapper">
        <div
          v-for="(vmvItem, index) in vmv"
          :key="index"
          class="card-item prevent-select"
        >
          <div class="image-height">
            <img :src="vmvItem.value" alt="" />
          </div>
          <div class="edit-button">
            <input
              type="file"
              class="hide"
              :id="`edit-${vmvItem.id}`"
              @change="editVmv"
            />
            <label
              :for="`edit-${vmvItem.id}`"
              @click="this.selectedId = vmvItem.id"
              >Edit</label
            >
          </div>
          <div class="delete-button">
            <button
              @click="
                openModal('delete'),
                  this.$root.emitter.emit('setDeleteType', 'vmv'),
                  this.$root.emitter.emit('setDeleteId', vmvItem.id)
              "
            >
              Hapus
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "../Empty.vue";
import { Api, baseURL } from "@/api/Api";
import Loading from "../Loading.vue";
import Error from "../Error.vue";
export default {
  data() {
    return {
      modalOpened: false,
      allowSave: false,
      showEmpty: null,
      isLoading: null,
      isError: null,
      selectedModal: null,
      selectedId: null,
      vmv: [],
    };
  },
  components: {
    Empty,
    Loading,
    Error,
  },
  mounted() {
    this.$root.emitter.on("allowModalOpen", () => {
      this.modalOpened = false;
    });
    this.$root.emitter.on("fetchVmv", () => {
      this.fetchVmv();
    });
    this.fetchVmv();
  },
  methods: {
    openModal(a) {
      if (this.modalOpened) return;
      this.modalOpened = true;
      this.$root.emitter.emit("openModal", a);
    },
    fetchVmv() {
      const Token = localStorage.getItem("token");
      this.isLoading = true;
      Api.get(`admin/vmv`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          this.vmv = res.data.data;
          if (this.vmv.length) {
            for (let i = 0; i < this.vmv.length; i++) {
              this.vmv[i].value = `${baseURL}images/${this.vmv[i].value}`;
            }
          } else this.showEmpty = true;
        })
        .catch((e) => {
          console.log(e);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    editVmv(e) {
      const Token = localStorage.getItem("token");
      let file = e.target.files[0];
      if (!file) return;
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert(
          "Please select a valid image file.\nValid image file type: jpeg, png."
        );
        event.target.value = "";
        return;
      }
      Api.post(
        `admin/vmv/${this.selectedId}`,
        {
          image: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Token}`,
          },
        }
      )
        .then(() => {
          this.fetchVmv();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.selectedId = null;
        });
    },
    addVmv(e) {
      const Token = localStorage.getItem("token");
      let file = e.target.files[0];
      if (!file) return;
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert(
          "Please select a valid image file.\nValid image file type: jpeg, png."
        );
        event.target.value = "";
        return;
      }
      Api.post(
        `admin/vmv`,
        {
          image1: file,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Token}`,
          },
        }
      )
        .then(() => {
          this.fetchVmv();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

img {
  pointer-events: none;
  vertical-align: middle;
}

.inner-content {
  display: flex;
  flex-direction: column;
}

.inner-content hr {
  width: 100%;
  border: #e1e1e1 solid 1px;
  box-sizing: border-box;
  margin: 0;
}

.inner-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.inner-header h1 {
  font-size: 1.25rem;
  font-weight: 500;
  height: 41px;
}

.inner-header button {
  font-size: 0.9375rem;
  font-weight: 500;
  background-color: #ff2230;
  border: none;
  border-radius: 5px;
  padding: 0.75rem 2.5rem;
  color: #ffffff;
  cursor: pointer;
}

.less-wide-button {
  padding: 0.75rem 1.5rem !important;
}

.inner-header button:hover {
  background-color: #f90011;
}

.inner-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.inner-header .disabled-button {
  background-color: #707070 !important;
  cursor: default !important;
}

.inner-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0 3rem 3rem 3rem;
}

.system-messages {
  width: 100%;
}

.card-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.image-height {
  display: flex;
  align-items: center;
  height: 400px;
}

.card-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  max-width: 400px;
}

.card-item img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  vertical-align: bottom;
  object-fit: contain;
}

.card-item div {
  width: 100%;
}
.card-item button {
  width: 100%;
  padding: 1rem 0;
  font-size: 1rem;
}
.add-button {
}

.add-button label {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #ff2230;
  color: #fff;
  border: none;
  padding: 0.5rem 0.25rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 500;
}

.edit-button label {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #ff2230;
  color: #fff;
  border: none;
  padding: 1rem 0;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.edit-button label:hover {
  background-color: #f90011;
}

.edit-button label:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
.delete-button button {
  background-color: #ffffff;
  color: #ff2230;
  border-radius: 5px;
  border: #ff2230 2px solid;
  font-size: 1rem;
  cursor: pointer;
}

.delete-button button:hover {
  background: #f0f0f0;
}

.delete-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}

.image-container {
  display: flex;
}
</style>
