<template>
  <div class="testimony-container">
    <div class="testimony-container-header">
      <!-- <form class="searchBar" @submit.prevent="searchTestimony">
        <img
          src="@/assets/admin/icons/SearchIcon.svg"
          alt=""
          draggable="false"
        />
        <input
          type="text"
          name=""
          id=""
          placeholder="Cari akun"
          v-model="searchQuery"
        />
        <input class="hidden-enter" type="submit" value="" />
      </form> -->
      <h1>Testimony</h1>
      <div class="prevent-select" v-if="!isLoading && !isError">
        <button @click="openAdd" :class="{ 'disabled-button': isNine }">
          +&nbsp;&nbsp;&nbsp;&nbsp;Tambah data
        </button>
      </div>
    </div>
    <hr />
    <Loading v-if="isLoading && !isError" />
    <Empty v-if="isEmpty" :type="'akun'" />
    <Error v-if="isError" />
    <div v-if="!isLoading && !isError" class="testimony-list">
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                class="custom-checkbox check-all hide"
                v-model="selectAll"
                @click="toggleAll"
                @change="checkCheck"
              />
            </th>
            <th>No</th>
            <th>Testimoni</th>
            <th>Status</th>
            <th>Komentar</th>
            <th><!--empty table header for fun --></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(testimony, index) in testimonies" :key="testimony.id">
            <td>
              <input
                type="checkbox"
                class="custom-checkbox hide"
                v-model="testimony.checked"
                @change="checkCheck"
              />
            </td>
            <td>{{ index + 1 }}</td>
            <td>{{ testimony.name }}</td>
            <td>{{ testimony.as }}</td>
            <td>{{ testimony.comment }}</td>
            <td class="edit-button prevent-select">
              <img
                src="@/assets/admin/icons/table/hapus.svg"
                alt=""
                draggable="false"
                @click="
                  openModal('delete'),
                    this.$root.emitter.emit('deleteTestimony', testimony.id)
                "
              />
              <img
                src="@/assets/admin/icons/table/edit.svg"
                alt=""
                draggable="false"
                @click="editTestimony(testimony.id)"
              />
              <div class="move-button">
                
              </div>
              <button @click="openDetail(testimony.id)">Lihat detail</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="isMoreThanTen">
        <div class="page">
          <img
            src="@/assets/admin/icons/table/previous-page.svg"
            alt=""
            draggable="false"
          />
          <h1>
            Halaman
            <input type="number" v-model="currentPage" />
            dari
            <span>
              {{ page }}
            </span>
          </h1>
          <img
            src="@/assets/admin/icons/table/next-page.svg"
            alt=""
            draggable="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/admin/Empty.vue";
import Loading from "../Loading.vue";
import { Api } from "@/api/Api";
import Error from '../Error.vue';

export default {
  data() {
    return {
      searchQuery: "",
      isEmpty: null,
      isError: null,
      isLoading: null,
      showOptions: null,
      modalOpened: false,
      ModalType: null,
      page: 200,
      currentPage: 1,
      testimonies: [],
    };
  },
  components: {
    Empty,
    Loading,
    Error
  },
  computed: {
    selectAll: {
      get() {
        return this.testimonies.every((item) => item.checked);
      },
      set(value) {
        this.testimonies.forEach((item) => {
          item.checked = value;
        });
      },
    },
    isMoreThanTen() {
      return this.testimonies?.length > 10;
    },
    isNine() {
      return this.testimonies?.length > 8;
    },
  },
  mounted() {
    this.$root.emitter.on("fetchTestimonies", () => {
      this.getTestimonies();
    });
    this.getTestimonies();
  },
  methods: {
    getTestimonies() {
      const Token = localStorage.getItem("token");
      this.isLoading = true;
      Api.get(`admin/testimoni?limit=10&page=${this.currentPage}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          function truncateText(text, maxLength) {
            if (text.length <= maxLength) {
              return text;
            } else {
              return text.slice(0, maxLength) + "...";
            }
          }
          this.testimonies = res.data.data;
          this.testimonies.map((i) => {
            i["checked"] = false;
          });
          // console.log(this.testimonies);
          if (!this.testimonies.length) this.isEmpty = true;
          if (this.testimonies.length) {
            for (let i = 0; i < this.testimonies.length; i++) {
              this.testimonies[i].comment = truncateText(
                this.testimonies[i].comment,
                30
              );
            }
          }
          // console.log(this.users);
        })
        .catch((e) => {
          console.log(e);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
          this.checkCheck();
        });
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
    },
    checkCheck() {
      const checkChecked = this.testimonies.some(
        (check) => check.checked === true
      );
      this.checkedAmount = this.testimonies.filter(
        (check) => check.checked === true
      ).length;
      if (checkChecked) {
        this.showSelectionContainer = true;
      } else this.showSelectionContainer = false;
    },
    openDetail(e) {
      this.$root.emitter.emit("openDetailTestimony", e);
    },
    openAdd() {
      if (this.isNine) return;
      this.$root.emitter.emit("openAddTestimony");
    },
    editTestimony(e) {
      this.$root.emitter.emit("openEditTestimony", e);
      setTimeout(() => {
        this.$root.emitter.emit("setDirectTo", "list");
      }, 1);
    },
    openModal(e) {
      this.$root.emitter.emit("openModal", e);
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.testimony-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.testimony-container-header h1 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 550;
  margin: 0;
}

.testimony-container hr {
  border: solid #e1e1e1;
  margin: 0;
  border-width: 1px;
}

.testimony-container-header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.testimony-container-header button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.testimony-container-header button:hover {
  background-color: #f90011;
}

.testimony-container-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.hidden-enter {
  display: none;
}

.searchBar {
  display: flex;
  align-items: stretch;
  padding: 0.5rem 1.25rem;
  width: 15%;
  border-radius: 5px;
  align-self: center;
  background: #f0f0f0;
  box-shadow: 0px 28px 53px rgba(122, 125, 126, 0.07);
  gap: 0.3rem;
}

.searchBar > img {
  aspect-ratio: 1/1;
}

.searchBar > input {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  background: #f0f0f0;
}

.searchBar input::placeholder {
  color: #3a3a3a;
  font-size: 0.875rem;
  font-weight: 500;
}

.testimony-list table {
  width: 100%;
  border-collapse: collapse;
}

.testimony-list table thead th,
.testimony-list table tbody th {
  color: #393d43;
  font-size: 1rem;
}

.testimony-list table thead th:nth-of-type(1) {
  width: 2%;
}

.testimony-list table thead th:nth-of-type(2) {
  width: 4%;
  text-align: left;
}
.testimony-list table thead th:nth-of-type(3),
.testimony-list table thead th:nth-of-type(4),
.testimony-list table thead th:nth-of-type(5) {
  width: 25%;
  text-align: left;
}
.testimony-list table thead * {
  padding: 1rem 0;
}

.testimony-list table tbody tr td {
  padding: 0.75rem 0;
}

.edit-button {
  display: flex;
  flex-direction: row-reverse;
  gap: 0.25rem;
}

.move-button {
  display: flex;
  flex-direction: column;
  padding: 0 0.75rem;
  gap: 0;
}

.move-up {
  height: 16px;
  overflow: hidden;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.move-down {
  height: 16px;
  overflow: hidden;
  transform: rotate(180deg);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.move-button img {
  vertical-align: middle;
  padding: 0;
}

.edit-button button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  background-color: #ff2230;
  border-radius: 5px;
  cursor: pointer;
}

.edit-button button:hover {
  background-color: #f90011;
}

.edit-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.testimony-list table tbody tr td img {
  cursor: pointer;
}

.testimony-list table tbody td:nth-of-type(1) {
  text-align: center;
}

.testimony-list table tbody tr {
  border-bottom: 0.5px solid rgba(225, 225, 225, 0.8);
}

.testimony-list table thead tr {
  border-bottom: 1px solid rgba(225, 225, 225, 0.8);
}

.testimony-list table thead tr input,
.testimony-list table tbody tr input {
  margin: 0;
  vertical-align: middle;
}

.testimony-list table tbody td:nth-of-type(6) {
  text-align: right;
  padding-right: 2rem;
}

.pagination {
  display: flex;
  flex-direction: row-reverse;
}

.page {
  display: flex;
  gap: 0.5rem;
  padding: 1rem 2rem 1rem 0;
  align-items: center;
}

.page img {
  cursor: pointer;
  width: 31px;
  height: 31px;
  border-radius: 50%;
}

.page h1 {
  color: #3a3a3a;
  font-size: 1rem;
  font-weight: 500;
  word-spacing: 0.25rem;
}

.page h1 span {
  color: #1f1f1f;
  font-weight: 400;
}

.page input {
  width: 1.6rem;
  height: 1.8rem;
  text-align: center;
  color: #ff2230;
  border: #ff2230 solid 2px;
  font-size: 1rem;
}

.page input[type="number"]:focus {
  outline: none;
}

.custom-checkbox {
  position: relative;
  cursor: pointer;
}

.custom-checkbox:before,
.custom-checkbox:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -6px;
  left: 0;
  transition: background-color 0.1s ease-out, border-color 0.1s ease-out;
  background-color: #ffffff;
  border: #3a3a3a 1px solid;
}

.custom-checkbox:checked:before {
  background-color: #ff2230;
  border-color: #ff2230;
}

.custom-checkbox:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: -3px;
  left: 7px;
}

.disabled-button {
  background-color: #707070 !important;
  cursor: default !important;
}
</style>
