<template>
  <div class="container">
    <div class="prevent-select">
      <h1>404</h1>
      <h1>Laman tidak ditemukan.</h1>
    </div>
  </div>
</template>

<style scoped>
.container {
  font-family: "Poppins";
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

h1 {
  text-align: center;
  margin: 0;
}

.container div {
  display: flex;
  flex-direction: column;
}

.container div h1:first-of-type {
  font-size: 15rem;
  color: #e1000f;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.container div h1:last-of-type {
  transform: translateY(-4rem);
  font-size: 3rem;
  color: #333333;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 1400px) {
  .container div h1:first-of-type {
    font-size: 10rem;
  }
  .container div h1:last-of-type {
    font-size: 2rem;
    transform: translateY(-3rem);
  }
}
@media only screen and (max-width: 600px) {
  .container div h1:first-of-type {
    font-size: 5rem;
  }
  .container div h1:last-of-type {
    font-size: 1rem;
    transform: translateY(-1rem);
  }
}
</style>
