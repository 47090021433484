<template>
  <div class="service-paragraph">
    <h1>Layanan Kami</h1>
    <h2>
      PT Adhi Konsultan Utama adalah Konsultan dan partner terbaik untuk segala
      bidang Kontruksi dan Perdagangan Umum seperti Konsultan Perencanaan &#38;
      Pengawasan, Pengurusan Izin, Pengadaan Alat Tulis Kantor dan layanan
      lainnya.
    </h2>
    <hr />
  </div>
  <div class="service-wrapper prevent-select">
    <div class="service-item service-1">
      <div>
        <h1>Konsultan Perencanaan dan Pengawasan</h1>
        <h2>
          Melayani Konsultansi Perencanaan dan Pengawasan Gedung &amp; Jalan
          Raya
        </h2>
      </div>
      <div>
        <button @click="selectService('kpp')">Cek Penawaran!</button>
      </div>
    </div>
    <div class="service-item service-2">
      <div>
        <h1>Pengurusan Perizinan</h1>
        <h2>
          Melayani Pengurusan PBG &#40;Persetujuan Bangunan Gedung&#41;,
          Pengurusan SLF &#40;Sertifikat Laik Fungsi&#41;, Pengurusan Perizinan
          Lingkungan &#40;SPPL, UKL UPL dan AMDAL&#41;, Pengurusan Analisa
          Dampak Lalu Lintas &#40;Andalalin&#41;, dan Pengurusan SLO
          &#40;Sertifikat Laik Operasi&#41; dan lain-lain
        </h2>
      </div>
      <div>
        <button @click="selectService('pi')">Cek Penawaran!</button>
      </div>
    </div>
    <div class="service-item service-3">
      <div>
        <h1>Perdagangan Umum</h1>
        <h2>
          Pengadaan berbagai jenis barang &amp; jasa seperti Pengadaan Alat
          Tulis Kantor &#40;ATK&#41;, Genset, Seragam Kantor dan Sekolah,
          Komputer dan Aksesoris, Meubeler, Window Blind, dan lain – lain.
        </h2>
      </div>
      <div>
        <button @click="selectService('pu')">Cek Penawaran!</button>
      </div>
    </div>
    <div class="service-item service-4">
      <div>
        <h1>Jasa Pengembangan Aplikasi dan Website</h1>
        <h2>
          Melayani Pembuatan dan Maintenance website dan aplikasi software.
        </h2>
      </div>
      <div>
        <button @click="selectService('jasa')">Cek Penawaran!</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    selectService(a) {
      this.$root.emitter.emit("selectService", a);
    },
  },
};
</script>

<style scoped>
/* Global style */
h1,
h2 {
  margin: 0;
}

/* Service section */

.service-paragraph {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.service-paragraph h1 {
  font-weight: 600;
  font-size: 2rem;
  margin: 0;
  padding-bottom: 1.5rem;
}

.service-paragraph h2 {
  text-align: center;
  line-height: 2.25rem;
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  margin: 0;
}

.service-paragraph hr {
  border-width: 0.15rem;
  border-style: solid;
  border-color: #e4000f;
  width: 15%;
}

.service-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 3.5rem 8.1rem 3.5rem;
  gap: 0.75rem;
  justify-content: center;
}

.service-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 884px;
  height: 404px;
  background: url("@/assets/user/images/home/service/background.png");
  z-index: 1;
  border-radius: 10px;
}

.service-item div:first-of-type {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  padding: 2.5rem 1.5rem 0 0;
  gap: 0.75rem;
}

.service-item div:last-of-type {
  text-align: center;
  padding: 0 0 1.5rem 0;
}

.service-item div:last-of-type button {
  color: #252525;
  font-weight: 600;
  font-size: 1.5rem;
  padding: 1rem 1.75rem;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  -webkit-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  -moz-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  -o-transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
}

.service-item div:last-of-type button:hover {
  background: #ff2230;
  color: #ffffff;
}

.service-item div:last-of-type button:active {
  -webkit-transition: background-color 0.1s ease-in-out;
  -moz-transition: background-color 0.1s ease-in-out;
  -o-transition: background-color 0.1s ease-in-out;
  transition: background-color 0.1s ease-in-out;
  background: #d0000e;
}

.service-item div:first-of-type h1:first-of-type {
  font-size: 1.5rem;
  font-weight: 500;
  color: #f8f8ff;
  max-width: 300px;
}

.service-item div:first-of-type h2:first-of-type {
  font-size: 1.25rem;
  font-weight: 400;
  color: #f8f8ff;
  max-width: 500px;
}

.service-1:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: url("@/assets/user/images/home/service/konsultan.png");
  background-repeat: no-repeat;
  background-position: top left;
  z-index: 2;
  pointer-events: none;
  border-radius: 10px;
}

.service-2:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: url("@/assets/user/images/home/service/perizinan.png");
  background-repeat: no-repeat;
  background-position: top left;
  z-index: 2;
  pointer-events: none;
  border-radius: 10px;
}

.service-3:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: url("@/assets/user/images/home/service/perdagangan.png");
  background-repeat: no-repeat;
  background-position: top left;
  z-index: 2;
  pointer-events: none;
  border-radius: 10px;
}

.service-4:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: url("@/assets/user/images/home/service/jasa.png");
  background-repeat: no-repeat;
  background-position: top left;
  z-index: 2;
  pointer-events: none;
  border-radius: 10px;
}
@media only screen and (max-width: 1908px) {
  .service-item {
    width: 784px;
  }
}
@media only screen and (max-width: 1708px) {
}
@media only screen and (max-width: 1473px) {
  .service-paragraph {
    padding-top: 2rem;
  }
  .service-paragraph h1 {
    font-size: 1.75rem;
    padding-bottom: 0.75rem;
  }
  .service-paragraph h2 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .service-wrapper {
    padding: 4rem 3.5rem 4.1rem 3.5rem;
  }
}

@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1366px) {
  .service-item {
    width: 584px;
    height: 354px;
  }
  .service-1:after {
    background-size: 8.25rem;
  }
  .service-2:after {
    background-size: 8.25rem;
  }
  .service-3:after {
    background-size: 8.25rem;
  }
  .service-4:after {
    background-size: 8.25rem;
  }
  .service-item div:first-of-type {
    padding-top: 1.5rem;
  }
  .service-item div:first-of-type h1:first-of-type {
    font-size: 1.25rem;
  }
  .service-item div:first-of-type h2:first-of-type {
    font-size: 1rem;
    max-width: 400px;
  }
  .service-item div:last-of-type button {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 655px) {
  .service-item {
    width: 325px;
    height: 300px;
  }

  .service-1:after {
    background-size: 3.25rem;
  }
  .service-2:after {
    background-size: 3.25rem;
  }
  .service-3:after {
    background-size: 3.25rem;
  }
  .service-4:after {
    background-size: 3.25rem;
  }
  .service-item div:first-of-type {
    padding: 1.5rem;
  }
  .service-item div:first-of-type h1:first-of-type {
    font-size: 1rem;
    font-weight: 500;
    color: #f8f8ff;
    width: 200px;
    max-width: 300px;
  }

  .service-item div:first-of-type h2:first-of-type {
    font-size: 0.75rem;
    font-weight: 400;
    color: #f8f8ff;
    max-width: 300px;
  }
  .service-item div:last-of-type button {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .service-paragraph {
    width: 80%;
    padding-top: 2rem;
  }
  .service-paragraph h1 {
    font-size: 1.4rem;
  }

  .service-paragraph h2 {
    font-size: 1.1rem;
    text-align: justify;
  }
  .service-wrapper {
    padding: 2rem 0;
  }
  .service-item {
    width: 325px;
    height: 300px;
  }

  .service-1:after {
    background-size: 3.25rem;
  }
  .service-2:after {
    background-size: 3.25rem;
  }
  .service-3:after {
    background-size: 3.25rem;
  }
  .service-4:after {
    background-size: 3.25rem;
  }
  .service-item div:first-of-type h1:first-of-type {
    font-size: 1rem;
    font-weight: 500;
    color: #f8f8ff;
    max-width: 300px;
  }

  .service-item div:first-of-type h2:first-of-type {
    font-size: 0.75rem;
    font-weight: 400;
    color: #f8f8ff;
    max-width: 300px;
  }
  .service-item div:last-of-type button {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 300px) {
  .service-item {
    width: 250px;
    height: 400px;
  }
  .service-1:after {
    background: none;
  }
  .service-2:after {
    background: none;
  }
  .service-3:after {
    background: none;
  }
  .service-4:after {
    background: none;
  }
  .service-item div:first-of-type h2:first-of-type {
    max-width: 200px;
  }
}
</style>
