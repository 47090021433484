<template>
  <div class="sidebar">
    <div class="sidebar-title prevent-select">
      <img
        src="@/assets/admin/images/sidebar/line-art/red-2.svg"
        alt=""
        draggable="false"
      />
      <div></div>
      <img src="@/assets/admin/images/logo-2.svg" alt="" draggable="false" />
      <h1>PANEL ADMIN</h1>
    </div>
    <div class="navigations prevent-select">
      <router-link to="/account-admin"
        ><img
          src="@/assets/admin/icons/sidebar/account.svg"
          alt=""
          draggable="false"
        />Akun</router-link
      >
      <router-link to="/project-admin"
        ><img
          src="@/assets/admin/icons/sidebar/project.svg"
          alt=""
          draggable="false"
        />Project</router-link
      >
      <a @click="checkifAuthenticated"
        ><img
          src="@/assets/admin/icons/sidebar/article.svg"
          alt=""
          draggable="false"
        />Artikel</a
      >
      <router-link to="/testimony-admin"
        ><img
          src="@/assets/admin/icons/sidebar/testimony.svg"
          alt=""
          draggable="false"
        />Testimoni</router-link
      >
      <!-- <router-link to="/admin/service"
        ><img src="@/assets/admin/icons/sidebar/service.svg" alt="" draggable="false" />Layanan
        Kami</router-link
      > -->
      <!-- <router-link to="/admin/banner"
        ><img
          src="@/assets/admin/icons/sidebar/banner.svg"
          alt="" draggable="false"
        />Banner</router-link
      > -->
      <router-link to="/about-us-admin"
        ><img
          src="@/assets/admin/icons/sidebar/info.svg"
          alt=""
          draggable="false"
        />Tentang Kami</router-link
      >
    </div>
    <div class="logout-button prevent-select">
      <img
        src="@/assets/admin/icons/sidebar/logout.svg"
        alt=""
        draggable="false"
        @click="logout"
      />
    </div>
  </div>
</template>

<script>
import { Api, Token } from "@/api/Api";

export default {
  methods: {
    logout() {
      Api.delete("logout", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      }).finally(() => {
        localStorage.removeItem("token");
        this.$router.push("/login");
      });
    },
    checkifAuthenticated() {
      const Token = localStorage.getItem("token");
      Api.get("is-authenticated", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then(() => {
          window.open("https://blog.adhikonsultanutama.com/wp-admin", "_self");
        })
        .catch((e) => {
          if (e.response.data.message == "Unauthenticated.") {
            localStorage.clear();
          }
        });
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  background-color: #ffffff;
  width: 380px;
  height: 100%;
  overflow: hidden;
}

.sidebar-title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.sidebar-title img:first-of-type {
  position: absolute;
  z-index: 1;
}

.sidebar-title img:last-of-type {
  padding-top: 0.5rem;
  width: 70px;
  z-index: 2;
}

.sidebar-title div {
  position: absolute;
  z-index: 0;
  height: 98px;
  width: 380px;
  box-shadow: 0px 0.31701597571372986px 1.086911916732788px 0px
      rgba(0, 0, 0, 0.1),
    0px 0.8765086531639099px 13.0051724910736084px 0px rgba(0, 0, 0, 0.14),
    0px 2.110295057296753px 17.235297679901123px 0px rgba(0, 0, 0, 0.19),
    0px 7px 24px 0px rgba(0, 0, 0, 0.29);
}

.sidebar-title h1 {
  margin: 0;
  font-size: 1.125rem;
  z-index: 1;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 0.05em;
  z-index: 2;
}

.navigations {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.navigations a {
  padding-left: 2.25rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: flex;
  gap: 0.5rem;
  text-decoration: none;
  color: #252525;
  font-size: 1.25rem;
  font-weight: 500;
}

.navigations a:hover {
  background-color: #ffb4b8;
}

.navigations .router-link-active {
  border-left: #ff2230 solid 6px;
  padding-left: 1.85rem;
  cursor: default;
  background-color: #ffffff !important;
  pointer-events: none;
}

.logout-button {
  margin: auto auto 0 auto;
  padding-bottom: 2rem;
}

.logout-button img {
  aspect-ratio: 1/1;
  object-fit: none;
  cursor: pointer;
  vertical-align: middle;
}
</style>
