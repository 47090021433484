<template>
  <div class="career-container">
    <div class="career-text">
      <h1>Mohon Maaf</h1>
      <h2>Saat ini kami sedang tidak membuka lowongan.</h2>
    </div>
  </div>
</template>

<style scoped>
.career-container {
  display: flex;
  justify-content: center;
}
.career-text {
  display: flex;
  flex-direction: column;
}
.career-text h1 {
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  padding: 1rem 0;
  text-align: center;
}
.career-text h2 {
  font-weight: 400;
  text-align: center;
  margin: 0;
}
@media only screen and (max-width: 400px) {
  .career-text h1 {
    font-size: 1.75rem;
    padding: 0.5rem 0;
  }
  .career-text h2 {
    font-size: 1rem;
    text-align: center;
  }
}
</style>
