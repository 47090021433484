<template>
  <div class="inner-content">
    <div class="inner-header">
      <div class="button-header-container prevent-select">
        <button
          :class="{ 'disabled-button': !valueChanged }"
          @click="uploadDirector"
        >
          Simpan data
        </button>
      </div>
      <h1>Tambah Direksi</h1>
    </div>
    <div class="directors-container">
      <div class="directors-content">
        <div v-if="isLoading" class="loading-con">
          <Loading />
        </div>
        <div v-if="!isLoading" class="directors-image">
          <div class="image-title">
            <h1>Foto profil<span class="required">*</span></h1>
            <h1 class="hide">
              Ukuran file maks.<span class="required">&nbsp;100kb</span>
            </h1>
          </div>
          <div class="profile-container prevent-select">
            <div v-if="!base64" class="image-container prevent-select">
              <img
                src="@/assets/admin/icons/edit/image-big.svg"
                alt=""
                draggable="false"
              />
            </div>
            <img v-if="base64" :src="base64" class="image-container" alt="" />
          </div>
          <div class="image-buttons">
            <input
              type="file"
              id="change-image"
              class="hide"
              @change="previewImage"
            />
            <label class="change-button" for="change-image">Ubah gambar</label>
          </div>
          <h2>
            Untuk mengubah Aspect Ratio Anda dapat menggunakan
            <a
              href="https://imresizer.com/resize-image-to-3x4cm"
              target="_blank"
              rel="noopener noreferrer"
              >website ini</a
            >.
          </h2>
        </div>
        <div v-if="!isLoading" class="directors-input">
          <label>
            <div>Nama<span class="required">*</span></div>
            <input type="text" v-model="director.name" />
          </label>
          <label>
            <div>Jabatan<span class="required">*</span></div>
            <input type="text" v-model="director.as" />
          </label>
        </div>
      </div>
    </div>
    <div class="back-button">
      <button @click="this.$root.emitter.emit('openManagementMain')">
        Kembali
      </button>
    </div>
  </div>
</template>

<script>
import { fileToBase64 } from "@/helpers";
import { Api } from "@/api/Api";
import Loading from "../../Loading.vue";

export default {
  data() {
    return {
      base64: null,
      allowSave: null,
      isUploading: null,
      isLoading: null,
      director: {
        category: "dd",
        name: "",
        as: "",
        pp: null,
      },
    };
  },
  components: {
    Loading,
  },
  mounted() {},
  methods: {
    async previewImage(e) {
      let file = e.target.files[0];
      if (!file) return;
      const img = new Image();
      const reader = new FileReader();
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert(
          "Please select a valid image file.\nValid image file type: jpeg, png."
        );
        event.target.value = "";
        return;
      }
      reader.onload = (e) => {
        img.onload = () => {
          const width = img.width;
          const height = img.height;
          const aspectRatio = width / height;

          // Define the expected aspect ratio
          const expectedAspectRatio = 3 / 4;

          // Define the allowed tolerance for aspect ratio comparison
          const tolerance = 0.01;

          // Check if the aspect ratio is within the allowed tolerance
          if (Math.abs(aspectRatio - expectedAspectRatio) > tolerance) {
            alert("Aspect ratio should be 3:4.");
            e.target.value = "";
            // Optionally, you can reset the file input to allow the user to select a new file.
            // event.target.value = '';
          } else {
            let promise = fileToBase64(file);
            promise.then((result) => {
              this.base64 = result?.src;
              this.director.pp = file;
            });
          }
        };

        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    uploadDirector() {
      const Token = localStorage.getItem("token");
      if (this.isUploading && !this.valueChanged) return;
      this.isUploading = true;
      this.isLoading = true;
      Api.post(`admin/mp`, this.director, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then(() => {
          this.$root.emitter.emit("openManagementMain");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.isUploading = false;
          this.isLoading = false;
        });
    },
  },
  computed: {
    valueChanged() {
      return (
        this.director.name != "" &&
        this.director.as != "" &&
        this.director.pp != null
      );
    },
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6,
hr {
  margin: 0;
}

.required {
  color: red;
}

.inner-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inner-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 2rem;
}

.inner-header h1 {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  height: 41px;
}

.inner-header button {
  font-size: 0.9375rem;
  font-weight: 500;
  background-color: #ff2230;
  border: none;
  border-radius: 5px;
  padding: 0.75rem 2.5rem;
  color: #ffffff;
  cursor: pointer;
}

.inner-header button:hover {
  background-color: #f90011;
}

.inner-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.directors-container {
  display: flex;
  padding: 0 3.5rem 1.5rem 3.5rem;
}

.directors-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 0.625rem;
  background: #efefef;
  box-shadow: 0px 1.1345751285552979px 2.2138051986694336px 0px
      rgba(0, 0, 0, 0.02),
    0px 2.726541757583618px 5.32008171081543px 0px rgba(0, 0, 0, 0.03),
    0px 5.133836269378662px 10.017241477966309px 0px rgba(0, 0, 0, 0.04),
    0px 9.157886505126953px 17.869047164916992px 0px rgba(0, 0, 0, 0.04),
    0px 17.128820419311523px 33.422088623046875px 0px rgba(0, 0, 0, 0.05);
}

.directors-image {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 2rem 3rem;
}

.directors-image h2 {
  color: #989898;
  font-size: 1rem;
  font-weight: 500;
  max-width: 350px;
}

.image-title {
  display: flex;
  align-items: center;
  gap: 1.125rem;
}

.image-title h1:first-of-type {
  color: #989898;
  font-size: 1.25rem;
  font-weight: 500;
}

.image-title h1:last-of-type {
  color: #7a7a7a;
  font-size: 1rem;
  font-weight: 500;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 533.33px;
  background: #d9d9d9;
}

.image-buttons {
  display: flex;
  justify-content: center;
}

.change-button {
  font-size: 1rem;
  color: #ffffff;
  background: #ff2230;
  padding: 0.75rem 1rem;
  height: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.change-button:hover {
  background-color: #f90011;
}

.change-button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
.back-button button {
  font-size: 1rem;
  color: #ffffff;
  background: #ff2230;
  padding: 0.938rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.back-button button:hover {
  background-color: #f90011;
}

.back-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}
.directors-input {
  width: 40%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.directors-input label {
  color: #989898;
  font-size: 1.25rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}
.directors-input input {
  height: 2.5rem;
  border-radius: 5px;
  text-indent: 0.5rem;
  font-size: 1.25rem;
  color: #3a3a3a;
  border: none;
  outline: none;
}

.loading-con {
  width: 100%;
}

.disabled-button {
  background-color: #707070 !important;
  cursor: default !important;
}
</style>
