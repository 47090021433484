<template>
  <AboutUsModal v-show="modalOpened" :modalType="selectedModal" />
  <div class="container">
    <Sidebar />
    <div class="content-container">
      <div class="about-us-container">
        <div class="about-us-container-header prevent-select">
          <h1 class="active-section" @click="switchSection('mv')">
            Visi dan misi
          </h1>
          <h1 @click="switchSection('mp')">Profil manajemen</h1>
        </div>
        <hr />
        <VisionAndMission v-if="ActiveComponent == 'mv'" />
        <ManagementProfile v-if="ActiveComponent == 'mp'" />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar.vue";
import VisionAndMission from "@/components/admin/about-us/VisionAndMission.vue";
import ManagementProfile from "@/components/admin/about-us/ManagementProfile.vue";
import AboutUsModal from "@/components/admin/about-us/AboutUsModal.vue";
import { Api } from "@/api/Api";

export default {
  data() {
    return {
      ActiveComponent: "mv",
      modalOpened: null,
      selectedModal: null,
    };
  },
  components: {
    Sidebar,
    VisionAndMission,
    ManagementProfile,
    AboutUsModal,
  },
  mounted() {
    const Token = localStorage.getItem("token");
    this.$root.emitter.on("openModal", (a) => {
      if (this.modalOpened) return;
      this.modalOpened = true;
      const addFormAnimationIn = document.querySelectorAll(".form");
      const AddModal = document.querySelector(".addModal");
      this.selectedModal = a;
      var index = 0,
        length = addFormAnimationIn.length;
      setTimeout(() => {
        for (; index < length; index++) {
          addFormAnimationIn[index].classList.add("form-active");
        }
        AddModal.classList.add("addModal-active");
      }, 100);
    });
    this.$root.emitter.on("closeModal", () => {
      if (!this.modalOpened) return;
      const addFormAnimationOut = document.querySelectorAll(".form");
      const AddModal = document.querySelector(".addModal");
      var index = 0,
        length = addFormAnimationOut.length;
      for (; index < length; index++) {
        addFormAnimationOut[index].classList.remove("form-active");
      }
      AddModal.classList.remove("addModal-active");
      setTimeout(() => {
        this.selectedModal = null;
        this.modalOpened = false;
      }, 100);
    });
    Api.get("is-authenticated", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    }).catch((e) => {
      if (e.response.data.message == "Unauthenticated.") {
        localStorage.clear();
      }
    });
  },
  methods: {
    switchSection(a) {
      const sections = document.querySelector(".about-us-container-header");
      const section1 = sections.childNodes[0];
      const section2 = sections.childNodes[1];
      if (a == "mv") {
        if (section1.classList.contains("active-section")) return;
        section1.classList.add("active-section"),
          section2.classList.remove("active-section");
        this.ActiveComponent = "mv";
      }
      if (a == "mp") {
        if (section2.classList.contains("active-section")) return;
        section1.classList.remove("active-section"),
          section2.classList.add("active-section");
        this.ActiveComponent = "mp";
      }
    },
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.container {
  position: absolute;
  width: 100%;
  display: flex;
  background-color: #f8f8ff;
  font-family: "Poppins";
}

.content-container {
  padding: 4rem 2rem 4rem;
  flex: 1;
}

.sidebar {
  height: 100vh !important;
  position: sticky;
  top: 0;
}

.about-us-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.about-us-container hr {
  border: solid #e1e1e1;
  margin: 0;
  border-width: 1px;
}

.about-us-container-header {
  padding: 0 2.5rem;
  display: flex;
  justify-content: space-around;
}

.about-us-container-header h1 {
  font-size: 1.25rem;
  color: #3a3a3a;
  font-weight: 500;
  padding: 2rem 0 0.5rem 0;
  cursor: pointer;
}

.about-us-container-header h1.active-section {
  border-bottom: 2px #ff2230 solid;
  cursor: default !important;
}
</style>
