import { createRouter, createWebHistory } from "vue-router";
import checkAdminAuth from "@/router/middleware/checkAdminAuth.js";
import multiguard from "vue-router-multiguard";
import mitt from "mitt";

let emitter = mitt();

// Main
import Home from "@/pages/user/Home.vue";
import AboutUs from "@/pages/user/AboutUs.vue";
import Product from "@/pages/user/Product.vue";
import Career from "@/pages/user/Career.vue";
import Article from "@/pages/user/Article.vue";

// import TestPage from "@/pages/TestPage.vue";

// About Us Components
import Background from "@/components/user/about-us/pages/Background.vue";
import VisionAndMission from "@/components/user/about-us/pages/VisionAndMission.vue";
import History from "@/components/user/about-us/pages/History.vue";
import BoardOfCommissioners from "@/components/user/about-us/pages/ManagementProfile/BoardOfCommissioners.vue";
import BoardOfDirectors from "@/components/user/about-us/pages/ManagementProfile/BoardOfDirectors.vue";
import OurTeam from "@/components/user/about-us/pages/ManagementProfile/OurTeam.vue";
import Galery from "@/components/user/about-us/pages/ManagementProfile/Galery.vue";

// Product Component
import ProductItem from "@/components/user/product/ProductItem.vue";

// Article Component
import ArticleContent from "@/components/user/article/ArticleContent.vue";

//Admin Main
import AdminLogin from "@/pages/admin/Login.vue";

import AdminAccount from "@/pages/admin/Account.vue";
import AdminProject from "@/pages/admin/Project.vue";
// import AdminArticle from "@/pages/admin/Article.vue";
import AdminTestimony from "@/pages/admin/Testimony.vue";
import AdminAboutUs from "@/pages/admin/AboutUs.vue";

import NotFound from "@/pages/NotFound.vue";

const routes = [
  //Main

  //Home
  {
    path: "/home",
    component: Home,
    name: "Home",
    meta: { title: `PT Adhi Konsultan Utama` },
  },
  { path: "/", redirect: "/home" },

  //About Us
  {
    path: "/about-us",
    name: "AboutUs",
    redirect: "/about-us/background",
    meta: { title: `PT Adhi Konsultan Utama | About Us` },
    component: AboutUs,
    children: [
      { path: "/about-us/background", component: Background },
      { path: "/about-us/vision-and-mission", component: VisionAndMission },
      { path: "/about-us/history", component: History },
      {
        path: "/about-us/management-profile",
        redirect: "/about-us/management-profile/board-of-commissioners",
      },
      {
        path: "/about-us/management-profile/board-of-commissioners",
        component: BoardOfCommissioners,
      },
      {
        path: "/about-us/management-profile/board-of-directors",
        component: BoardOfDirectors,
      },
      {
        path: "/about-us/management-profile/our-team",
        component: OurTeam,
      },
      {
        path: "/about-us/management-profile/galery",
        component: Galery,
      },
    ],
  },

  //Product
  {
    path: "/product",
    name: "Product",
    meta: { title: `PT Adhi Konsultan Utama | Product` },
    component: Product,
  },

  //Product item
  {
    path: "/product/:id",
    name: "ProductItem",
    meta: { title: `PT Adhi Konsultan Utama | Product` },
    component: ProductItem,
  },

  // Career
  {
    path: "/career",
    meta: { title: `PT Adhi Konsultan Utama | Career` },
    component: Career,
  },

  // Article
  {
    path: "/article",
    meta: { title: `PT Adhi Konsultan Utama | Article` },
    component: Article,
  },

  //Article content
  {
    path: "/article/:id",
    component: ArticleContent,
  },

  //Admin Main

  //Login
  {
    name: "Login",
    path: "/login",
    // beforeEnter: multiguard([checkAdminAuth]),
    meta: { title: `Login | Admin Panel` },
    component: AdminLogin,
  },

  {
    path: "/admin",
    beforeEnter: multiguard([checkAdminAuth]),
    redirect: "/account-admin",
  },

  {
    name: "AdminAccount",
    path: "/account-admin",
    beforeEnter: multiguard([checkAdminAuth]),
    meta: { title: `Account | Admin Panel` },
    component: AdminAccount,
  },

  {
    path: "/project-admin",
    beforeEnter: multiguard([checkAdminAuth]),
    meta: { title: `Project | Admin Panel` },
    component: AdminProject,
  },

  // {
  //   path: "/article-admin",
  //   component: AdminArticle,
  // },

  {
    path: "/testimony-admin",
    beforeEnter: multiguard([checkAdminAuth]),
    meta: { title: `Testimony | Admin Panel` },
    component: AdminTestimony,
  },

  {
    path: "/about-us-admin",
    beforeEnter: multiguard([checkAdminAuth]),
    meta: { title: `About Us | Admin Panel` },
    component: AdminAboutUs,
  },

  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound },
  // { path: "/test", component: TestPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  document.title = to.meta.title;
  if (from.name == "Home" || from.name == "AboutUs" || from.name == "Product") {
    emitter.emit("killInterval");
  }
  if (from.name == "ProductItem" && to.name == "ProductItem") {
    setTimeout(() => {
      window.location.reload();
    }, 1);
  }
});

export default router;
