<template>
  <div class="empty prevent-select">
    <div>
      <img src="@/assets/admin/images/emptyData.svg" alt="" draggable="false" />
      <h1>Gagal memuat data!</h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
  },
};
</script>

<style scoped>
.empty {
  display: flex;
  justify-content: center;
  padding: 5rem 0 5rem 0;
}

.empty div {
  text-align: center;
}

.empty img {
  pointer-events: none;
}

.empty h1 {
  color: #3a3a3a;
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
