<template>
  <div class="career-container">
    <!-- <div class="career-title">
      <h1></h1>
      <hr />
    </div> -->
    <Loading v-if="isLoading" />
    <Error v-if="!isLoading && isError" />
    <CareerClosed v-if="!isLoading && !isError && isHiring == false" />
    <div class="card-container" v-if="!isError && !isLoading && isHiring != false">
      <div class="card-item">
        <div>
          <h1>Daftar Tim Engineer Kami</h1>
          <h2>
            Membangun hubungan kepercayaan yang berkelanjutan dengan Pelanggan
            melalui komunikasi yang terbuka dan interaktif.
          </h2>
        </div>
        <div>
          <button @click="checkHiring">Daftar sekarang</button>
        </div>
      </div>
      <!-- <div class="card-item">
        <div>
          <h1>Staff keuangan</h1>
          <h2>
            Membangun hubungan kepercayaan yang berkelanjutan dengan Pelanggan
            melalui komunikasi yang terbuka dan interaktif.
          </h2>
        </div>
        <div>
          <button @click="selectJob('careerOne', 'staffKeuangan')">
            Daftar sekarang
          </button>
        </div>
      </div>
      <div class="card-item">
        <div>
          <h1>Staff keuangan</h1>
          <h2>
            Membangun hubungan kepercayaan yang berkelanjutan dengan Pelanggan
            melalui komunikasi yang terbuka dan interaktif.
          </h2>
        </div>
        <div>
          <button @click="selectJob('careerOne', 'staffKeuangan')">
            Daftar sekarang
          </button>
        </div>
      </div>
      <div class="card-item">
        <div>
          <h1>Staff keuangan</h1>
          <h2>
            Membangun hubungan kepercayaan yang berkelanjutan dengan Pelanggan
            melalui komunikasi yang terbuka dan interaktif.
          </h2>
        </div>
        <div>
          <button @click="selectJob('careerOne', 'staffKeuangan')">
            Daftar sekarang
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Loading from "@/components/admin/Loading.vue";
import Error from "@/components/admin/Error.vue";
import CareerClosed from "./CareerClosed.vue";
import { Api } from "@/api/Api";

export default {
  data() {
    return {
      isLoading: null,
      isError: null,
      isHiring: null,
    };
  },
  components: {
    Loading,
    Error,
    CareerClosed,
  },
  methods: {
    selectJob(selectedCareer, a) {
      this.$root.emitter.emit("selectJob", selectedCareer);
      this.$root.emitter.emit("applyJob", a);
    },
    checkHiring() {
      this.isLoading = true;
      Api.get("check-career")
        .then((res) => {
          this.isError = false;
          if (res.data.data.is_hiring == true) {
            this.isHiring = res.data.data.is_hiring;
            // this.selectJob("careerOne", "staffKeuangan");
            this.selectJob("careerTemp", "staffKeuangan");
          }
          if (res.data.data.is_hiring == false) {
            this.isHiring = res.data.data.is_hiring;
          }
        })
        .catch((e) => {
          console.log(e);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
h1,
h2,
hr {
  margin: 0;
}

.career-container {
  display: flex;
  flex-direction: column;
  gap: 8rem;
}

.career-title {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.career-title h1 {
  font-size: 2rem;
  font-weight: 600;
}

.career-title hr {
  width: 11rem;
  border: solid #e4000f 2px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.card-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 884px;
  height: 369px;
  background: url("@/assets/user/images/home/career/red.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 100px 480px rgba(0, 0, 0, 0.08),
    0px 41.7776px 200.533px rgba(0, 0, 0, 0.0575083),
    0px 22.3363px 107.214px rgba(0, 0, 0, 0.0476886),
    0px 12.5216px 60.1035px rgba(0, 0, 0, 0.04),
    0px 6.6501px 31.9205px rgba(0, 0, 0, 0.0323114),
    0px 2.76726px 13.2828px rgba(0, 0, 0, 0.0224916);
}

.card-item div:first-of-type {
  padding: 4rem 0 0 3rem;
}

.card-item h1 {
  color: #ff2230;
  font-weight: 800;
  font-size: 1.625rem;
}

.card-item h2 {
  color: #252525;
  font-weight: 400;
  font-size: 1.25rem;
  max-width: 600px;
}

.card-item div:last-of-type {
  text-align: center;
  padding: 0 0 2.25rem 0;
}

.card-item button {
  font-weight: 600;
  font-size: 1.5rem;
  color: #252525;
  padding: 1rem 1.75rem;
  border-radius: 40px;
  background-color: #ffffff;
  border: #000000 solid 1px;
  cursor: pointer;
}

.card-item button:hover {
  background: #f0f0f0;
}

.card-item button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}
@media only screen and (max-width: 1912px) {
  .card-item {
    width: 684px;
  }
  .card-item h2 {
    max-width: 400px;
  }
}
@media only screen and (max-width: 1512px) {
  .career-title h1 {
    font-size: 1.5rem;
  }
  .career-container {
    gap: 3rem;
  }
  .card-item {
    width: 484px;
    height: 269px;
    background-size: 10rem;
  }
  .card-item h1 {
    font-size: 1.25rem;
  }
  .card-item h2 {
    font-size: 1rem;
    max-width: 340px;
  }
  .card-item h3 {
  }
  .card-item button {
    font-size: 1rem;
  }
  .card-item div:first-of-type {
    padding: 1rem 0 0 1rem;
  }
  .card-item div:last-of-type {
    padding-bottom: 1rem;
  }
}
@media only screen and (max-width: 400px) {
  .career-container {
    gap: 3rem;
  }
  .career-title h1 {
    font-size: 1.5rem;
  }
  .card-item {
    background-image: none;
    /* background-size: 10rem; */
    height: 280px;
  }
  .card-item h1 {
    /* text- */
    font-size: 1.5rem;
  }
  .card-item h2 {
    font-size: 1rem;
  }
  .card-item div:first-of-type {
    padding: 1rem 1rem;
  }
  .card-item div:last-of-type {
    padding-bottom: 1.5rem;
  }
  .card-item button {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }
}
</style>
