<template>
  <TestimonyModal v-show="modalOpened" :modalType="selectedModal" />
  <div class="container">
    <Sidebar />
    <div class="content-container">
      <TestimonyTable v-if="ActiveComponent == 'testimonyTable'" />
      <AddTestimony v-if="ActiveComponent == 'addTestimony'" />
      <EditTestimony v-if="ActiveComponent == 'editTestimony'" />
      <DetailTestimony v-if="ActiveComponent == 'detailTestimony'" />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar.vue";
import TestimonyTable from "@/components/admin/testimony/TestimonyTable.vue";
import AddTestimony from "@/components/admin/testimony/AddTestimony.vue";
import EditTestimony from "@/components/admin/testimony/EditTestimony.vue";
import DetailTestimony from "@/components/admin/testimony/DetailTestimony.vue";
import TestimonyModal from '@/components/admin/testimony/TestimonyModal.vue';
import { Api } from '@/api/Api';

export default {
  data() {
    return {
      ActiveComponent: "testimonyTable",
      modalOpened: null,
      selectedModal: null,
    };
  },
  components: {
    Sidebar,
    TestimonyTable,
    AddTestimony,
    EditTestimony,
    DetailTestimony,
    TestimonyModal
  },
  mounted() {
    const Token = localStorage.getItem("token");
    this.$root.emitter.on("openModal", (a) => {
      if (this.modalOpened) return;
      this.modalOpened = true;
      const addFormAnimationIn = document.querySelectorAll(".form");
      const AddModal = document.querySelector(".addModal");
      this.selectedModal = a;
      var index = 0,
        length = addFormAnimationIn.length;
      setTimeout(() => {
        for (; index < length; index++) {
          addFormAnimationIn[index].classList.add("form-active");
        }
        AddModal.classList.add("addModal-active");
      }, 100);
    });
    this.$root.emitter.on("closeModal", () => {
      if (!this.modalOpened) return;
      const addFormAnimationOut = document.querySelectorAll(".form");
      const AddModal = document.querySelector(".addModal");
      var index = 0,
        length = addFormAnimationOut.length;
      for (; index < length; index++) {
        addFormAnimationOut[index].classList.remove("form-active");
      }
      AddModal.classList.remove("addModal-active");
      setTimeout(() => {
        this.selectedModal = null;
        this.modalOpened = false;
      }, 100);
    });
    this.$root.emitter.on("openDetailTestimony", (id) => {
      this.ActiveComponent = "detailTestimony";
      setTimeout(() => {
        this.$root.emitter.emit("fetchDetailTestimony", id);
      }, 1);
    });
    this.$root.emitter.on("openEditTestimony", (id) => {
      this.ActiveComponent = "editTestimony";
      setTimeout(() => {
        this.$root.emitter.emit("fetchDetailTestimony", id);
      }, 1);
    });
    this.$root.emitter.on("backToTestimony", () => {
      this.ActiveComponent = "testimonyTable";
    })
    this.$root.emitter.on("openAddTestimony", () => {
      this.ActiveComponent = "addTestimony";
    });
    Api.get("is-authenticated", {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${Token}`,
      },
    }).catch((e) => {
      if (e.response.data.message == "Unauthenticated.") {
        localStorage.clear();
      }
    });
  },
};
</script>

<style scoped>
.container {
  position: absolute;
  width: 100%;
  display: flex;
  background-color: #f8f8ff;
  font-family: "Poppins";
}

.content-container {
  padding: 4rem 2rem 4rem;
  flex: 1;
}

.sidebar {
  height: 100vh!important;
  position: sticky;
  top: 0;
}
</style>
