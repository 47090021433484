<template>
  <div>
    <h1>Dewan Komisaris</h1>
    <div class="board-container">
      <div class="empty-image" v-if="!commissioners.length">
        <img src="@/assets/loading.svg" alt="" />
      </div>
      <div
        v-for="(item, index) in commissioners"
        :key="index"
        class="board-item"
        v-else
      >
        <img :src="item.pp" alt="" />
        <h2>{{ item.name }}</h2>
        <h3>{{ item.as }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api";

export default {
  data() {
    return {
      commissioners: [],
    };
  },
  mounted() {
    Api.get("commissioners").then((res) => {
      this.commissioners = res.data.data;
      for (let i = 0; i < this.commissioners.length; i++) {
        this.commissioners[
          i
        ].pp = `${baseURL}images/${this.commissioners[i].pp}`;
      }
    });
  },
};
</script>

<style scoped>
h1 {
  font-weight: 600;
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  color: #ff2230;
  padding-left: 2rem;
}

img {
  pointer-events: none;
}

.board-container {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 4rem;
}

.board-container img {
  width: 400px;
  /* height: 460px; */
  vertical-align: bottom;
  object-fit: contain;
  aspect-ratio: 3/4;
}

.board-item {
  text-align: center;
}

.board-item h2 {
  font-weight: 400;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  color: #252525;
  padding-top: 0.45rem;
  margin: 0;
}

.board-item h3 {
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: 0.05em;
  color: rgba(37, 37, 37, 0.7);
  margin: 0;
}

.empty-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70rem;
  height: 20rem;
}

.empty-image img {
  mix-blend-mode: multiply;
}

.empty-image h6 {
  font-size: 2.5rem;
  margin: 0;
  font-weight: 300;
}
@media only screen and (max-width: 1440px) {
  h1 {
    font-size: 2rem;
    padding: 2rem 0 2rem 2rem;
    margin: 0;
  }
  .board-container {
    padding-top: 0;
  }
  .board-container img {
    width: 300px;
  }
  .board-item h2 {
    font-size: 1.25rem;
  }
  .board-item h3 {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 400px) {
  h1 {
    font-size: 1.5rem;
    padding: 0;
    text-align: center;
  }

  .board-container img {
    width: 200px;
  }
  .board-item h2 {
    font-size: 1rem;
  }
  .board-item h3 {
    font-size: 0.9rem;
  }
}
</style>
