<template>
  <div class="testimony-container">
    <div class="testimony-container-header">
      <div class="header-title">
        <h1>Edit Testimoni</h1>
      </div>
      <div class="prevent-select" v-if="!isLoading && !isError">
        <button
          :class="[{ 'disabled-button': !valueChanged }, 'save-button']"
          @click="editTestimony"
        >
          Simpan perubahan
        </button>
      </div>
    </div>
    <hr />
    <Loading v-if="isLoading" />
    <Error v-if="!isLoading && isError" />
    <div v-if="!isLoading && !isError" class="edit-container">
      <div class="text-input">
        <label>
          <div class="text-title">
            Nama Testimoni
            <span class="requiredTag">*</span>
          </div>
          <input
            type=""
            name=""
            v-model="testimony.name"
            placeholder="Masukkan nama testimoni"
          />
        </label>
        <label>
          <div class="text-title">
            Status
            <span class="requiredTag">*</span>
          </div>
          <input
            type=""
            name=""
            v-model="testimony.as"
            placeholder="Masukkan status testimoni"
          />
        </label>
        <label>
          <div class="text-title">
            Komentar
            <span class="requiredTag">*</span>
          </div>
          <textarea
            v-model="testimony.comment"
            cols="30"
            rows="6"
            placeholder="Masukkan komentar testimoni"
          ></textarea>
        </label>
      </div>
      <div class="image-input">
        <div class="logo-input">
          <div class="image-title">
            Foto Testimoni
          </div>
          <img
            v-if="!base64 && testimony.pp"
            class="logo-image"
            :src="testimony.pp"
            alt=""
            draggable="false"
          />
          <img
            v-if="base64"
            class="logo-image"
            :src="base64"
            alt=""
            draggable="false"
          />
          <div
            v-if="!testimony.pp && !base64"
            class="image-container prevent-select"
          >
            <img
              src="@/assets/admin/icons/edit/image-big.svg"
              alt=""
              draggable="false"
            />
          </div>
          <input
            type="file"
            id="cover"
            name="cover"
            @change="previewImage"
            class="hide"
          />
          <div class="file-selection-label prevent-select">
            <label for="cover" class="big-file-select">Ubah gambar</label>
            <div class="hide">
              <button @click="(base64 = null), (testimony.pp = null)">
                Hapus gambar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons" v-if="!isLoading">
      <div class="back-button">
        <button @click="backToList">Kembali</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Api, baseURL } from "@/api/Api";
import Loading from "../Loading.vue";
import Error from "../Error.vue";
import { fileToBase64 } from "@/helpers";

export default {
  data() {
    return {
      directTo: "detail",
      base64: null,
      isError: null,
      isLoading: null,
      modalOpened: false,
      ModalType: null,
      isSending: null,
      newPP: null,
      testimony: [],
      oldTestimony: [],
    };
  },
  components: {
    Loading,
    Error,
  },
  mounted() {
    this.$root.emitter.on("setDirectTo", (e) => {
      this.directTo = e;
    });
    this.$root.emitter.on("fetchDetailTestimony", (id) => {
      this.getTestimony(id);
    });
  },
  methods: {
    getTestimony(id) {
      const Token = localStorage.getItem("token");
      this.isLoading = true;
      Api.get(`admin/testimoni/${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          this.testimony = res.data.data;
          if (this.testimony.pp) {
            this.testimony.pp = `${baseURL}images/${this.testimony.pp}`;
          }
          this.oldTestimony = { ...this.testimony };
          // console.log(this.users);
        })
        .catch((e) => {
          console.log(e);
          this.isError = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async previewImage(e) {
      let file = e.target.files[0];
      if (!file) return;
      const allowedTypes = ["image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        alert(
          "Please select a valid image file.\nValid image file type: jpeg, png."
        );
        event.target.value = "";
        return;
      }
      let promise = fileToBase64(file);
      promise.then((result) => {
        this.base64 = result?.src;
        this.newPP = file;
      });
    },
    editTestimony() {
      const Token = localStorage.getItem("token");
      if (!this.valueChanged || this.isSending) return;
      this.isSending = true;
      this.isLoading = true;
      Api.post(
        `admin/testimoni/${this.testimony.id}`,
        {
          name: this.testimony.name,
          as: this.testimony.as,
          comment: this.testimony.comment,
          pp: this.newPP,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${Token}`,
          },
        }
      )
        .then(() => {
          this.backToList();
        })
        .catch((e) => {
          console.log(e);
          alert("Gagal menyunting testimoni!");
        })
        .finally(() => {
          this.isSending = false;
          this.isLoading = false;
        });
    },
    backToDetail(id) {
      this.$root.emitter.emit("openDetailTestimony", id);
    },
    backToList() {
      if (this.directTo == "detail") {
        this.backToDetail(this.testimony.id);
      }
      if (this.directTo == "list") {
        this.$root.emitter.emit("backToTestimony");
      }
    },
  },
  computed: {
    valueChanged() {
      return (
        this.testimony?.name != this.oldTestimony?.name ||
        this.testimony?.as != this.oldTestimony?.as ||
        this.testimony?.comment != this.oldTestimony?.comment ||
        this.newPP != null
      );
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
}

.requiredTag {
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.testimony-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.testimony-container hr {
  border: solid #e1e1e1;
  margin: 0;
  border-width: 1px;
}

.testimony-container-header {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
}

.header-title {
  display: flex;
  align-items: center;
}

.testimony-container h1 {
  font-size: 1.375rem;
  font-weight: 400;
  margin: 0;
  color: #3a3a3a;
}

.testimony-container-header button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.testimony-container-header button:hover {
  background-color: #f90011;
}

.testimony-container-header button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.edit-container {
  display: flex;
  flex-wrap: wrap;
}

/* Text input */

.text-input {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 6rem 2rem;
  width: 52.5%;
  gap: 1rem;
}

.text-input label {
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input input {
  height: 3.5rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  text-indent: 0.5rem;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input input:focus {
  outline: none;
}

.text-input textarea {
  padding: 0.5rem;
  font-family: "Poppins";
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  resize: none;
  font-size: 1.25rem;
  color: #3a3a3a;
}

.text-input textarea:focus {
  outline: none;
}

.text-title {
  display: flex;
  padding: 0 0.5rem;
}

.option-container {
  display: flex;
  gap: 0.5rem;
}

.option-box {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem 1rem;
  background: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
}

.option-box:hover {
  background: #e9e9e9;
}

.option-box-active {
  background: #f90011;
  cursor: default;
}

.option-box-active:hover {
  background: #f90011 !important;
}

.option-box-active h1 {
  color: #ffffff !important;
}

.option-box .option-circle {
  width: 10px;
  height: 10px;
  border: #3a3a3a 1px solid;
  border-radius: 5px;
}

.option-box-active .option-circle {
  background: #ffffff;
}

.option-box h1 {
  font-size: 1rem;
  color: #3a3a3a;
}

.image-input {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 1rem;
}

.image-input .image-title {
  display: flex;
  font-size: 1.25rem;
  color: #989898;
}

.cover-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cover-input .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: 240px;
  background: #d9d9d9;
}

.cover-image {
  width: 480px;
  height: 240px;
  pointer-events: none;
}

.file-selection-label {
  padding: 0.75rem 0;
  display: flex;
  gap: 0.5rem;
}

.file-selection-label button {
  padding: 0.9rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #ff2230;
  border: #ff2230 solid 1px;
  background: none;
  border-radius: 5px;
  cursor: pointer;
}

.file-selection-label button:hover {
  background: #f0f0f0;
}

.file-selection-label button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}

.big-file-select {
  font-size: 1rem;
  color: #ffffff;
  background: #ff2230;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.big-file-select:hover {
  background-color: #f90011;
}

.big-file-select:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.logo-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.logo-title {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}

.logo-title h1 {
  font-size: 1rem;
  color: #7a7a7a;
}

.logo-title span {
  color: #ff2230;
}

.logo-input .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 240px;
  background: #d9d9d9;
}

.logo-image {
  width: 240px;
  height: 240px;
  pointer-events: none;
}

.snapshots-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.snapshots-container .image-container {
  background: #d9d9d9;
  width: 240px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.snapshot-upload-button {
  display: flex;
  justify-content: space-between;
}

.snapshot-upload-button label {
  background: #ff2230;
  color: #ffffff;
  text-align: center;
  padding: 0.75rem 0.25rem;
  border-radius: 5px;
  cursor: pointer;
}

.snapshot-upload-button label:hover {
  background-color: #f90011;
}

.snapshot-upload-button label:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.snapshot-upload-button button {
  padding: 0.9rem 0.25rem;
  font-size: 1rem;
  font-weight: 500;
  color: #ff2230;
  border: #ff2230 solid 1px;
  background: none;
  border-radius: 5px;
  cursor: pointer;
}

.snapshot-upload-button button:hover {
  background: #f0f0f0;
}

.snapshot-upload-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}

.snapshot-image {
  width: 240px;
  height: 120px;
  pointer-events: none;
}

.snapshot-item-container {
  display: flex;
  flex-wrap: wrap;
  width: 496px;
  gap: 1rem;
}

.snapshot-input {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 240px;
}
.buttons {
  padding: 0 2rem 1rem 2rem;
}
.back-button button {
  border: none;
  background: #ff2230;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  padding: 0.75rem 1rem 0.75rem 1rem;
  font-size: 1rem;
  background-color: #f90011;
  cursor: pointer;
}

.back-button button:hover {
  background-color: #f90011;
}

.back-button button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.disabled-button {
  background-color: #707070 !important;
  cursor: default !important;
}
</style>
