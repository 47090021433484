<template>
  <div class="running-text-container">
    <h1 class="running-text">{{ text }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>

<style scoped>
.running-text-container {
  height: 3rem;
  width: 100%;
  background-color: #ff2230;
  position: fixed;
  bottom: 0;
  z-index: 10;
}

.running-text {
  font-family: "Roboto Bold";
  font-size: 2rem;
  padding-top: 0.25rem;
  color: #f8f8ff;
  margin: 0;
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: running-animation 15s linear infinite;
  -webkit-animation: running-animation 15s linear infinite;
  animation: running-animation 15s linear infinite;
}

/* for Firefox */
@-moz-keyframes running-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes running-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes running-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
</style>
