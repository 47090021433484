import axios from 'axios';
const baseURL = `https://test.adhikonsultanutama.com/`;
const blogBaseURL = `https://blog.adhikonsultanutama.com/wp-json/wp/v2/`;
const Token = localStorage.getItem("token")

const Api = axios.create({
    baseURL: baseURL,
});
const blogApi = axios.create({
    baseURL: blogBaseURL,
});
const daerahApi = axios.create({
    baseURL: 'https://dev.farizdotid.com/api/daerahindonesia/',
});

export { baseURL, daerahApi, Api, blogApi, Token }