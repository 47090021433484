<template>
  <div class="addModal" v-show="modalType == 'delete'">
    <div class="modal-shadow" @click="closeModal"></div>
    <div class="form form-delete-bulk" v-show="modalType == 'delete'">
      <div>
        <img src="@/assets/admin/images/delete.png" alt="" />
        <h1>Apokah Anda ingin menghapus item terpilih?</h1>
      </div>
      <div>
        <button @click="deleteFunction" class="delete-button">
          {{ deleteAccount.button }}
        </button>
        <button @click="closeModal">Batal</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "@/api/Api";

export default {
  data() {
    return {
      deleteType: null,
      selectedId: null,
      deleteAccount: {
        button: "Iya",
      },
      isClicking: false,
      showLoading: false,
    };
  },
  props: {
    modalType: null,
  },
  components: {},
  methods: {
    closeModal() {
      this.$root.emitter.emit("allowModalOpen");
      this.$root.emitter.emit("closeModal");
    },
    getUsers() {
      const Token = localStorage.getItem("token");
      Api.get("admin/account", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          this.users = res.data.data;
          // console.log(this.users);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getUser(a) {
      const Token = localStorage.getItem("token");
      this.showLoading = true;
      Api.get(`admin/account/${a}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          this.selectedUser = res.data.data;
          this.selectedUserToEdit = {
            username: `${res.data.data.username}`,
            email: `${res.data.data.email}`,
          };
          // console.log(this.selectedUserToEdit);
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    deleteFunction() {
      if (this.isClicking) return;
      this.isClicking = true;
      if (this.deleteType == "vmv") {
        this.deleteVmv();
      }
      if (this.deleteType == "mp") {
        this.deleteMp();
      }
      if (this.deleteType == "gallery") {
        this.deleteGallery();
      }
    },
    deleteVmv() {
      document.querySelector(".delete-button").classList.add("saving");
      this.deleteAccount.button = "Menghapus data";
      const Token = localStorage.getItem("token");
      Api.delete(`admin/vmv/${this.selectedId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then(() => {
          this.$root.emitter.emit("fetchVmv");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          document.querySelector(".delete-button").classList.remove("saving");
          this.deleteAccount.button = "Iya";
          this.$root.emitter.emit("allowModalOpen");
          this.$root.emitter.emit("closeModal");
          this.isClicking = false;
        });
    },
    deleteMp() {
      document.querySelector(".delete-button").classList.add("saving");
      this.deleteAccount.button = "Menghapus data";
      const Token = localStorage.getItem("token");
      Api.delete(`admin/mp/${this.selectedId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then(() => {
          this.$root.emitter.emit("fetchMp");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          document.querySelector(".delete-button").classList.remove("saving");
          this.deleteAccount.button = "Iya";
          this.$root.emitter.emit("allowModalOpen");
          this.$root.emitter.emit("closeModal");
          this.isClicking = false;
        });
    },
    deleteGallery() {
      document.querySelector(".delete-button").classList.add("saving");
      this.deleteAccount.button = "Menghapus data";
      const Token = localStorage.getItem("token");
      Api.delete(`admin/galeries/${this.selectedId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
      })
        .then(() => {
          this.$root.emitter.emit("fetchGallery");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          document.querySelector(".delete-button").classList.remove("saving");
          this.deleteAccount.button = "Iya";
          this.$root.emitter.emit("allowModalOpen");
          this.$root.emitter.emit("closeModal");
          this.isClicking = false;
        });
    },
    deleteBulk() {
      document
        .querySelector(".form-delete-bulk .delete-button")
        .classList.add("saving");
      this.deleteAccount.button = "Menghapus akun";
      this.$root.emitter.emit("bulkDeleteUser");
    },
    resetDeleteButton() {
      document
        .querySelector(".form-delete-bulk .delete-button")
        .classList.remove("saving");
      this.deleteAccount.button = "Iya";
      this.$root.emitter.emit("closeModal");
      this.selectedId = null;
    },
    removeInvalid(e) {
      document
        .querySelector(`.${e}-title`)
        .classList.remove("invalid-input-input");
      document.querySelector(`.${e}-input`).classList.remove("invalid-input");
      document
        .querySelector(`.form-edit .${e}-title`)
        .classList.remove("invalid-input-input");
      document
        .querySelector(`.form-edit .${e}-input`)
        .classList.remove("invalid-input");
      if (e == "username") this.inputTitle.username = "Username";
      if (e == "password") this.inputTitle.password = "Password";
      if (e == "passwordcon")
        this.inputTitle.passwordConfirmation = "Password Confirmation";
      if (e == "email") this.inputTitle.email = "Email";
    },
  },
  created() {
    // this.getUsers();
  },
  mounted() {
    this.$root.emitter.on("setDeleteId", (a) => {
      this.selectedId = a;
    });
    this.$root.emitter.on("setDeleteType", (a) => {
      this.deleteType = a;
    });
    this.$root.emitter.on("resetDeleteButtonUser", () => {
      this.resetDeleteButton();
    });
  },
};
</script>

<style scoped>
.addModal {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: opacity 0.2s;
  opacity: 0;
}

.modal-shadow {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.addModal-active {
  opacity: 1;
}

.form {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 5rem;
  width: 5rem;
  background: white;
  border-radius: 50%;
  transition: all 500ms cubic-bezier(0.93, 0.1, 0.42, 0.91);
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  font-family: "Poppins";
  overflow: hidden;
  justify-content: space-between;
  box-shadow: 0px 7px 29px rgba(0, 0, 0, 0.06),
    0px 0.876509px 3.63125px rgba(0, 0, 0, 0.03);
}

.form h1 {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
  color: #000000;
}

.formInputs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.form label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1rem;
  color: #3a3a3a;
  /* color: red; */
}

.form input {
  height: 2rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-style: none;
  outline: none;
  text-indent: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
}

.form input::placeholder {
  color: #3a3a3a;
  font-weight: 250;
}

.form button {
  height: 3rem;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  background: #ff2230;
  border-radius: 5px;
  border-style: none;
  padding: 0 1rem;
  cursor: pointer;
}

.form button:hover {
  background-color: #f90011;
}

.form button:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d0000e;
}

.form div:last-of-type {
  text-align: right;
}

.form-active {
  height: 27rem;
  /* height: auto; */
  width: 40%;
  border-radius: 10px;
}

.form-delete div:first-of-type {
  padding: 4rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.form-delete div:last-of-type {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
}

.form-delete div:last-of-type button:last-of-type {
  background: #ffffff;
  border: #ff2230 solid 1px;
  color: #ff2230;
}

.form-delete div:last-of-type button:last-of-type:hover {
  background: #f0f0f0;
}

.form-delete div:last-of-type button:last-of-type:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}

.form-delete img {
  width: 244.36px;
  height: 180px;
}

.form-delete h1 {
  font-family: "Poppins Bold";
  font-size: 1.5625rem;
  max-width: 549px;
  text-align: center;
}

.form-delete-bulk div:first-of-type {
  padding: 4rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.form-delete-bulk div:last-of-type {
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
}

.form-delete-bulk div:last-of-type button:last-of-type {
  background: #ffffff;
  border: #ff2230 solid 1px;
  color: #ff2230;
}

.form-delete-bulk div:last-of-type button:last-of-type:hover {
  background: #f0f0f0;
}

.form-delete-bulk div:last-of-type button:last-of-type:active {
  -webkit-transition: background-color 0.1s ease-out;
  -moz-transition: background-color 0.1s ease-out;
  -o-transition: background-color 0.1s ease-out;
  transition: background-color 0.1s ease-out;
  background-color: #d9d9d9;
}

.form-delete-bulk img {
  width: 244.36px;
  height: 180px;
}

.form-delete-bulk h1 {
  font-family: "Poppins Bold";
  font-size: 1.5625rem;
  max-width: 549px;
  text-align: center;
}

.invalid-input {
  border: red solid 2px !important;
}

.invalid-input-input {
  color: red !important;
}

.saving {
  background-color: #ff737d !important;
  cursor: default !important;
}
</style>
