<template>
  <div class="navigations prevent-select">
    <router-link to="/about-us/background">LATAR BELAKANG</router-link>
    <router-link to="/about-us/vision-and-mission"
      >VISI, MISI, DAN NILAI-NILAI</router-link
    >
    <router-link to="/about-us/history">SEJARAH</router-link>
    <h1 @click="clickSwitch()" class="prevent-select">
      PROFIL MANAJEMEN<img
        src="@/assets/user/icons/about-us/arrow.svg"
        alt=""
      />
    </h1>
    <div class="management-profile">
      <router-link to="/about-us/management-profile/board-of-commissioners"
        >DEWAN KOMISARIS</router-link
      >
      <router-link to="/about-us/management-profile/board-of-directors"
        >DEWAN DIREKSI</router-link
      >
      <router-link to="/about-us/management-profile/our-team"
        >TIM KAMI</router-link
      >
      <router-link to="/about-us/management-profile/galery">GALERI</router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpened: false,
    };
  },
  methods: {
    clickSwitch() {
      if (!this.isOpened) {
        const mph1 = document.querySelector(".navigations").querySelector("h1");
        const arrow = mph1.querySelector("img");
        const divs = document.querySelector(".management-profile");
        mph1.classList.add("div-opened");
        arrow.classList.add("div-opened-arrow");
        divs.style.display = "flex";
        this.isOpened = true;
      } else if (this.isOpened) {
        const mph1 = document.querySelector(".navigations").querySelector("h1");
        const arrow = mph1.querySelector("img");
        const divs = document.querySelector(".management-profile");
        mph1.classList.remove("div-opened");
        arrow.classList.remove("div-opened-arrow");
        divs.style.display = "none";
        this.isOpened = false;
      }
    },
    isManagement() {
      if (
        this.$route.path ==
          "/about-us/management-profile/board-of-commissioners" ||
        this.$route.path == "/about-us/management-profile/board-of-directors" ||
        this.$route.path == "/about-us/management-profile/our-team" ||
        this.$route.path == "/about-us/management-profile/galery"
      ) {
        this.clickSwitch();
      }
    },
  },
  mounted() {
    this.isManagement();
  },
};
</script>

<style scoped>
.navigations {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 0 7rem 0;
}

.navigations a {
  font-size: 1.25rem;
  font-weight: 400;
  text-decoration: none;
  letter-spacing: 0.05em;
  color: #252525;
}

.navigations a:visited {
  text-decoration: none;
  color: #252525;
}

.navigations h1 {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #252525;
  margin: 0;
  display: flex;
  gap: 1rem;
  cursor: pointer;
}

.navigations .router-link-active {
  text-decoration: none;
  font-weight: 600;
  color: #ff2230;
}

.navigations img {
  -webkit-transition: transform 0.1s ease-in-out;
  -moz-transition: transform 0.1s ease-in-out;
  -o-transition: transform 0.1s ease-in-out;
  transition: transform 0.1s ease-in-out;
}

.div-opened {
  color: #ff2230 !important;
}

.div-opened-arrow {
  filter: brightness(0) saturate(100%) invert(38%) sepia(95%) saturate(4994%)
    hue-rotate(341deg) brightness(105%) contrast(118%);
  transform: rotate(180deg);
}

.management-profile {
  padding-left: 1.5rem;
  display: none;
  flex-direction: column;
  gap: 1.5rem;
}

@media only screen and (max-width: 1050px) {
  .navigations {
    padding: 0;
  }
}

@media only screen and (max-width: 400px) {
  .navigations {
    padding: 0;
  }
  .navigations a {
    font-size: 1rem;
  }
  .navigations h1 {
    font-size: 1rem;
  }
}
</style>
